import React, { useState, useEffect, useCallback } from "react";
import {
    getActiveScreenShot,
    getArticlesAll,
    getArticlesData,
    getArticlesDataAll,
    getGalleryArticleId,
    getGalleryFullScreen,
    getGalleryIndex,
    getGalleryTiliIndex,
    getIsArticlesAllLoading,
    getIsArticlesLoading,
    getIsGalleryMode,
    getIsReadMoreState,
    getScreenshotLanguage,
    getScreenShotLoadingST,
    getSelectedArticleDataAll, getShowArticlePopup,
} from "../../store/selectors";
import { connect } from "react-redux";
import ArticleItem from "../UI/ArticleItem";
import Gallery from "../gallery";
import ArticleReadMore from "../articleReadMore";
import Popup from "../UI/Popup";
import i18n from "i18next";
import { getArticleAllFromAPI } from "../../store/actions/articlesAll";
import { setAudioPlayingFunc } from "../../store/actions/audio";
import {
    setActiveScreenShot, setArticleId,
    setGalleryFullScreen,
    setGalleryIndex, setIsFromReadMore,
    setIsGalleryState,
    setIsReadMoreState,
    setScreenShotCurrentTiliIndex,
    setScreenShotFullScreen,
    setScreenShotGalleryIndex,
    setShowArticlePopup,
} from "../../store/actions/mapStateAction";
import {useSearchParams} from "react-router-dom";
import GalleryNew from "../gallery-new/UI";
import {setCloseAndDeactivateAllMicroArticlesActivator} from "../../store/actions/microArticle";

let url = new URL(window.location.href);
const queryParams = new URLSearchParams(window.location.search);

const ArticlePopup = (props) => {
  const {
    articleData,
    articleLoading,
    getArticleAllFromAPI,
    articleAllLoading,
    articleAllData,
    stopPinAnimation,
    map,
    showReadMore,
    isGallery,
    galleryIndex,
    galleryTiliIndex,
    setIsReadMoreState,
    setGalleryIndex,
    galleryFullScreen,
    setGalleryFullScreen,
    setIsGalleryState,
    showArticlePopup,
    setShowArticlePopup,
    galleryArticleId,
    getScreenShotLoadingST,
    selectedArticleDataAll,
    setCloseAndDeactivateAllMicroArticlesActivator,
    screenshotLanguage,
    setActiveScreenShot,
    activeScreenShot,
    setIsFromReadMore,
    setArticleId,
    setScreenShotGalleryIndex,
    setScreenShotFullScreen,
    setScreenShotCurrentTiliIndex
  } = props;
  const [showGallery, setShowGallery] = useState(false);
  const [isFromReadMore, setFromReadMore] = useState(false);
  const [clickedMediaImage, setClickedMediaImage] = useState(null);
  const lng = screenshotLanguage || (i18n.language === 'hy' ? 1 : 2)

  const content = articleData?.[0]?.articleData?.content?.find( el => el.languageId === lng)
  const hasLanguageContent = !!content
  const toggleGallery = useCallback(
    (id, readMore = false) => {
      props.setAudioPlayingFunc(false);
      if (typeof id === "number") {
        setShowGallery(id);
      } else {
        setShowGallery(!showGallery);
      }
      setClickedMediaImage(id);
      setFromReadMore(!!readMore);
     setGalleryIndex(null)
     setGalleryFullScreen(false)
     setIsGalleryState(false)
     setIsFromReadMore(!!readMore)
     setArticleId(articleData?.[0]?.id)
    },
    [showGallery]
  );

  const toggleShowArticlePopup = useCallback(
    (id, isRefetch = true ) => {
      props.setAudioPlayingFunc(false);
      setShowArticlePopup(true);

      // setIsReadMoreState(!showReadMore)
      // setGalleryIndex(null)
      if(!!isRefetch) getArticleAllFromAPI(id || galleryArticleId, lng);

      // setClickedMediaImage(id)
    },
    [showGallery,screenshotLanguage]
  );

  const toggleArticleMode = useCallback(
      (id) => {
          props.setAudioPlayingFunc(false);
          setShowArticlePopup(true);
          setShowGallery(false);
          getArticleAllFromAPI(id || galleryArticleId);
          setIsReadMoreState(!showReadMore)
          // setGalleryIndex(null)
      },
      []
  );

  useEffect(() => {
      if(showReadMore && articleData?.[0]?.id) {
          toggleArticleMode(articleData?.[0]?.id)
      } else {
          setShowArticlePopup(false)
      }
  },[showReadMore])


    useEffect(() => {
        if(showReadMore !== undefined && (galleryIndex !== null || isGallery)) {
            if(showReadMore && (galleryIndex >= 0 || isGallery)) {
                setTimeout(() => {
                    setShowGallery(true)
                }, 1000)
            }
            else if (!showReadMore && (galleryIndex >= 0 || isGallery)) {
                setShowGallery(true)
            }
            return
        }
        if(getScreenShotLoadingST) setShowGallery(false)
    },[galleryIndex, isGallery])

    const getGalleryProps = (id) =>{
        let findItem,findItemSort,galleryItems,title,feauturedImage,feauturedImageIdx;
        findItem = articleData?.find((x) => x.show === true)
        findItemSort = findItem?.articleData?.gallery?.sort((a, b) => a.order - b.order)
        galleryItems = findItemSort?.filter((x) => x.type !== 4)
        title = findItem?.articleData?.content?.[0].shortTitle
        if(typeof id === 'number'){
            feauturedImage = findItem?.articleData?.content?.[0].featuredImage
            feauturedImageIdx = galleryItems?.findIndex(el=>el.id===id)
        }else{
            feauturedImage = findItem?.articleData?.content?.[0].featuredImage
            feauturedImageIdx = galleryItems?.findIndex(el=>el.type===0)
        }
        return {
            findItem,
            findItemSort,
            galleryItems,
            title,
            feauturedImage,
            feauturedImageIdx
        }
    }

    const rendGallery = (id) =>{
        const galleryProps = getGalleryProps(showGallery)
        // return (<Gallery
        //             isGrid={isGallery}
        //             mapState={map}
        //             title={galleryProps.title}
        //             gallery={showGallery}
        //             toggleGallery={toggleGallery}
        //             clickedMediaImage={clickedMediaImage}
        //             feauturedImage={galleryProps.feauturedImage}
        //             feauturedImageIdx={galleryIndex ?? galleryProps.feauturedImageIdx}
        //             galleryItems={galleryProps.galleryItems}
        //             feauturedTiliPageIdx={galleryTiliIndex ?? 0}
        //             fromArticle={isFromReadMore}
        //             isFullScreen={galleryFullScreen}
        //             setGalleryFullScreen={setGalleryFullScreen}
        //             articleId={articleData?.[0]?.id}
        //             screenShotFirstLoad={getScreenShotLoadingST}
        //         />)
        return (<GalleryNew
                    isGrid={isGallery}
                    mapState={map}
                    title={galleryProps.title}
                    gallery={showGallery}
                    toggleGallery={toggleGallery}
                    clickedMediaImage={clickedMediaImage}
                    feauturedImage={galleryProps.feauturedImage}
                    feauturedImageIdx={galleryIndex  ?? galleryProps.feauturedImageIdx}
                    galleryItems={galleryProps.galleryItems}
                    feauturedTiliPageIdx={galleryTiliIndex ?? 0}
                    fromArticle={isFromReadMore}
                    isFullScreen={galleryFullScreen}
                    setGalleryFullScreen={setGalleryFullScreen}
                    articleId={articleData?.[0]?.id}
                    screenShotFirstLoad={getScreenShotLoadingST}
                    setActiveScreenShot={setActiveScreenShot}
                    activeScreenShot={activeScreenShot}
                    setScreenShotGalleryIndex={setScreenShotGalleryIndex}
                    setScreenShotFullScreen={setScreenShotFullScreen}
                    setScreenShotCurrentTiliIndex={setScreenShotCurrentTiliIndex}
                />)
    }
    // const changeURIParams = () => {
    //     let url = new URL(window.location.href);
    //     let params = new window.URLSearchParams(window.location.search);
    //     params.set("lessonID", id);
    //     url.search = params;
    //     url = url.toString();
    //     window.history.replaceState({ url: url }, null, url);
    //     window.location.reload();
    // };
    const closeArticlePopup = () =>{
        setShowArticlePopup (false)
        setIsReadMoreState(false)
        setGalleryIndex(null)
        if(queryParams.has("bookmarkID")) {
            queryParams.delete('bookmarkID');
            url.search = queryParams;
            window.history.replaceState('', '', url);
        }
    }
  useEffect(() => {
    let flag = false;
    if (
      !flag &&
      articleData.length > 0 &&
      queryParams.get("bookmarkID") &&
      !showArticlePopup
    ) {
      flag = true;
      toggleShowArticlePopup(queryParams.get("bookmarkID"));
    }
  }, [articleLoading]);

    const renderArticlesList = () => {
        if(articleData.length > 0) {
            setCloseAndDeactivateAllMicroArticlesActivator(true);
        }
        return (
            <>
                {articleData.length > 0 &&
                    Array(Math.ceil(articleData.length / 5))
                        .fill()
                        .map((_, columnIndex) => {
                            // Calculate how many rows this column should have
                            const startIdx = columnIndex * 5;
                            const endIdx = Math.min(startIdx + 5, articleData.length);
                            const itemsInColumn = articleData.slice(startIdx, endIdx);
                            const rowsInColumn = itemsInColumn.length;

                            return (
                                <div
                                    className="articles-grid"
                                    key={columnIndex}
                                    style={{
                                        gridTemplateRows: `repeat(${rowsInColumn}, 80px)`, // Rows will adjust based on the number of items
                                    }}
                                >
                                    {itemsInColumn.length && itemsInColumn.map((item) => (
                                        <ArticleItem
                                            key={item?.id}
                                            showReadMore={showReadMore}
                                            map={map}
                                            article={item}
                                            toggleGallery={(id) => toggleGallery(id)}
                                            toggleShowArticlePopup={toggleShowArticlePopup}
                                            setShowArticlePopup={setShowArticlePopup}
                                            stopPinAnimation={stopPinAnimation}
                                        />
                                    ))}
                                </div>
                            );
                        })}
            </>
        )
    }

    return (
    <>
    {showArticlePopup && hasLanguageContent && (
          <Popup
              onClose={() => setShowArticlePopup(false)}
              isOpened={showArticlePopup}
              loading={!articleAllLoading && !!articleAllData?.data?.length}
              cssText={'z-index: 2;'}
            >
              <div className="article-read-more-content">
                <ArticleReadMore
                  mapState={map}
                  toggleShowArticlePopup={() => closeArticlePopup()}
                  showArticlePopup={showArticlePopup}
                  toggleGallery={(id) => toggleGallery(id, true)}
                  galleryItems={getGalleryProps()?.galleryItems}
                />
                {!!articleData.length
                    && showGallery
                    && rendGallery(showGallery)}
              </div>
            </Popup>
    )}
        <div style={{display: 'flex', height: '80px'}}>
            {renderArticlesList()}
        </div>
      {!!articleData.length && hasLanguageContent &&
        // !articleLoading &&
        showGallery &&
        rendGallery(showGallery)}
    </>
  );
};
const mapStateTopProps = (state) => ({
    articleData: getArticlesData(state),
    articleLoading: getIsArticlesLoading(state),
    articleAllLoading: getIsArticlesAllLoading(state),
    articleAllData:getArticlesAll(state),
    // showReadMore: getIsReadMoreState(state),
    isGallery: getIsGalleryMode(state),
    galleryIndex: getGalleryIndex(state),
    galleryTiliIndex: getGalleryTiliIndex(state),
    galleryFullScreen: getGalleryFullScreen(state),
    galleryArticleId: getGalleryArticleId(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
    selectedArticleDataAll: getSelectedArticleDataAll(state),
    screenshotLanguage: getScreenshotLanguage(state),
    showArticlePopup: getShowArticlePopup(state),
    activeScreenShot: getActiveScreenShot(state)

})

const mapDispatchToProps = {
    getArticleAllFromAPI,
    setAudioPlayingFunc,
    setIsReadMoreState,
    setGalleryIndex,
    setGalleryFullScreen,
    setShowArticlePopup,
    setCloseAndDeactivateAllMicroArticlesActivator,
    setIsGalleryState,
    setActiveScreenShot,
    setIsFromReadMore,
    setArticleId,
    setScreenShotGalleryIndex,
    setScreenShotFullScreen,
    setScreenShotCurrentTiliIndex
}

export default connect(mapStateTopProps, mapDispatchToProps)(ArticlePopup)
