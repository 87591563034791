import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Eye from '../../../UI/Eye'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'
import Sort from '../../../UI/Sort'
import { useTranslation } from "react-i18next"
import withRedux from "../../mapper";
import MicroArticlesIcon from "../../../UI/MicroArticlesIcon";
import { useDispatch } from 'react-redux';
import { setActiveMicroArticleId, setAllMicroArticles } from "../../../../store/actions/microArticle";
import {getContrastColor, INITIAL_ICON_OBJECT, isIconKeyValid, MICRO_ARTICLE_SOURCE} from "../../../microArticle/utils";

const color = '#036147'

const MicroArticleTab = ({
                             getAllMicroArticles,
                             map,
                             getAllFeaturesOfSuperCluster,
                             globalLoading,
                             sortBoard,
                             toggleSorting,
                             stopPinAnimation,
                             browserInfo,
                             microArticleTabOpen,
                             setMicroArticleTabOpen,
                             setSelectedCategoryId,
                             getActiveMicroArticleId,
                             filterAction,
                         }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const tabRef = useRef(null)
    const title = t('microArticle.userDashboard.sectionName')

    const sortArticles = (articles) => {
        console.log(articles);
        if (filterAction === 'alphabetically') {
            return [...articles].sort((a, b) => a.title.localeCompare(b.title))
        } else if (filterAction === 'chronologically') {
            return [...articles].sort((a,b)=>a?.startYear - b?.startYear)
        }
        return articles
    }

    const [sortedMicroArticles, setSortedMicroArticles] = useState(null);

    useEffect(() => {
        const newSortedMicroArticles = sortArticles(getAllMicroArticles);
        setSortedMicroArticles(newSortedMicroArticles);
    }, [getAllMicroArticles]);

    const calculateMaxHeight = () => {
        const lessonNames = document.querySelector('.lesson__names');
        const targetElement = document.querySelector('.target-element');

        if (lessonNames && targetElement) {
            const maxHeight = lessonNames.getBoundingClientRect().height; // Получаем высоту lesson__names
            targetElement.style.maxHeight = `${maxHeight}px`; // Устанавливаем max-height
        }
    }

    const calculateHeight = (element) => {
        if (!element) return 0
        const windowHeight = (window.innerHeight * 100) / 70
        const { top, height } = element.getBoundingClientRect()
        return Math.max(0, windowHeight - top - height - 135.5)
    }

    const handleTabClick = useCallback(() => {
        setSelectedCategoryId(null)
        setMicroArticleTabOpen(prev => !prev)
    }, [setSelectedCategoryId, setMicroArticleTabOpen])

    const mainSubItemStyles = useMemo(() => {
        if (!tabRef.current) return
        return {
            height: 'fit-content',
            minHeight: '100px',
            maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
                ? `${tabRef.current.height || calculateHeight(tabRef.current)}px`
                : `calc(100vh - 293px)`
        }
    }, [tabRef.current])

    const mainSubItemsWrapperStyles = useMemo(() => {
        if (!tabRef.current) return
        return {
            flexDirection: 'column',
            height: 'fit-content',
            maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
                ? `${(tabRef.current.height || calculateHeight(tabRef.current)) - 70 > 0
                    ? (tabRef.current.height || calculateHeight(tabRef.current)) - 70
                    : 0}px`
                : browserInfo.isSafari && window.innerWidth > 1800
                    ? `calc(100dvh - 657px)`
                    : `calc(100dvh - 557px)`
        }
    }, [tabRef.current, browserInfo.isSafari])

    const getMicroArticlesSource = () => map.getSource(MICRO_ARTICLE_SOURCE)

    // Handle toggling all micro-articles
    const handleToggleAll = () => {
        const microArticleIndexes = [];
        getAllFeaturesOfSuperCluster().current.forEach((ma, idx) => {
            ma.properties.isMicroArticle && microArticleIndexes.push(idx);
        })
        // FOR AVAILABLE IN SUPER CLUSTER
        const allVisible = microArticleIndexes.every((idx) => getAllFeaturesOfSuperCluster().current[idx].properties.show);
        microArticleIndexes.forEach(idx => {
            getAllFeaturesOfSuperCluster().current[idx].properties.show = !allVisible;
            getAllFeaturesOfSuperCluster().current[idx].properties.visible = !allVisible ? 'visible' : 'none';
        });

        if(getMicroArticlesSource()) {
            getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster().current);
        }

        const updatedArticles = sortedMicroArticles.map(ma => ({ ...ma, show: !allVisible, visible: !allVisible ? 'visible' : 'none' }));
        dispatch(setAllMicroArticles(updatedArticles));
    };

    // Handle toggling a single micro-article
    const handleToggleSingle = (id) => {
        getAllFeaturesOfSuperCluster().current.forEach((ma, idx) => {
            if (ma.properties.microArticleID === id) {
                getAllFeaturesOfSuperCluster().current[idx].properties.show = !(getAllFeaturesOfSuperCluster().current[idx].properties.show);
                getAllFeaturesOfSuperCluster().current[idx].properties.visible = getAllFeaturesOfSuperCluster().current[idx].properties.show ? 'visible' : 'none';
            }
        })

        if(getMicroArticlesSource()) {
            getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster().current);
        }

        const updatedArticles = sortedMicroArticles.map(ma => {
            if (ma.id === id) {
                return {
                    ...ma,
                    show: !ma.show,
                    visible: !ma.show ? 'visible' : 'none',
                };
            }
            return ma;
        });

        dispatch(setActiveMicroArticleId(null))
        dispatch(setAllMicroArticles(updatedArticles));
    };

    return (
      <li
        className={`main__tab persons ${microArticleTabOpen ? 'active' : ''}`}
        data-is-loading={globalLoading}>
        <button
          className="main__tab-btn"
          type="button"
          ref={tabRef}
          style={{
              color: `${getContrastColor(color)}`,
              backgroundColor: microArticleTabOpen ? color : 'transparent',
          }}
          onClick={handleTabClick}>
          <span className="main__tab-icon icon-wrapper">
            {MicroArticlesIcon({ isActive: microArticleTabOpen, isMap: true })}
          </span>
        </button>

        {microArticleTabOpen && (
          <>
            <div className="main__subitem" style={mainSubItemStyles}>
              <div className="main__subitem-heading" data-color={color}>
                <span
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    '--data-color': color,
                  }}
                />
                <h2 className="main__subitem-title">{title}</h2>
                <label className="main__subitem-label">
                  <input
                    className="main__subitem-checkbox show-checkbox"
                    type="checkbox"
                    onChange={handleToggleAll}
                    checked={sortedMicroArticles.every((ma) => ma.show)}
                  />
                  <Eye disableColor={'#f1f1f1'} color={'#fff'} show={sortedMicroArticles.every((ma) => ma.show)} />
                </label>
                <button
                  className="main__subitem-sort"
                  type="button"
                  onClick={() => toggleSorting(!sortBoard)}>
                  <span>
                    <Sort color={'#fff'}/>
                  </span>
                </button>
              </div>

              <div
                className="main__subitem-itemswrapper"
                style={mainSubItemsWrapperStyles}>
                <ul className="main__subitem-list person">
                  {sortedMicroArticles.map((ma) =>
                      (<li
                        key={`microart_${ma.id}`} className="main__subitem-li">
                      <div
                        style={{
                          '--data-color': ma.iconBackgroundColor,
                          '--data-font-color': `${getContrastColor(ma.iconBackgroundColor, '#fff', '#2C476C')}`,
                          opacity: !ma.show ? 0.5 : 1,
                        }}>
                        <p className="with-svg">
                          <ConnectedSpriteSvgComponent name={isIconKeyValid(ma.iconKey) ? ma.iconKey : INITIAL_ICON_OBJECT.darkKey} />
                        </p>
                        <div
                          className="main__subitem-libtn"
                          style={{
                              fontSize: '12px !important',
                              fontFamily: "Noto Sans Armenian, sans-serif !important",
                          }}
                          onClick={() => {
                            stopPinAnimation()
                            setTimeout(() => {
                              dispatch(setActiveMicroArticleId(ma.id))
                            }, 100)
                          }}>
                          {ma.title}
                        </div>
                      </div>
                      <label
                        className="main__subitem-label category-filters category_filters_icon"
                        data-show={!ma.show}>
                        <input
                          className="main__subitem-checkbox show-checkbox"
                          type="checkbox"
                          onChange={() => handleToggleSingle(ma.id)}
                          checked={ma.show}
                        />
                        <Eye show={ma.show} />
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              style={{ left: '800%' }}
              className="sort-board category_sorted_tooltip"
              data-active={sortBoard}>
              <span
                className="sort-title"
                style={{ textTransform: 'uppercase' }}>
                {t('sortBy.title')}
              </span>
              <div onClick={() => toggleSorting(false, 'chronologically')}>
                <input
                  name="sort-microarticles"
                  type="radio"
                  id="chronologically-microarticles"
                />
                <label htmlFor="chronologically-microarticles">
                  {t('sortBy.timeRange')}
                </label>
              </div>
              <div onClick={() => toggleSorting(false, 'alphabetically')}>
                <input
                  checked={filterAction === 'alphabetically'}
                  name="sort-microarticles"
                  type="radio"
                  id="alphabetically-microarticles"
                />
                <label htmlFor="alphabetically-microarticles">
                  {t('sortBy.alphabetically')}
                </label>
              </div>
            </div>
          </>
        )}
      </li>
    )
}

export default withRedux(MicroArticleTab);
