import React, {useState} from 'react'
import {Button, Image, Upload} from 'antd'
import AddImageIcon from '../icons/AddImageIcon'
import TrashIcon from '../icons/TrashIcon'
import {useUploadMediaMutation} from '../../store/presentationApi'

const ImageUploader = ({
  value,
  fileName,
  alt,
  access,
  onChange,
  localUpload,
  ...rest
}) => {
  const [file, setFile] = useState(value)

  const imageUrl =
    typeof file === 'string' || !file ? file : URL.createObjectURL(file)

  const [uploadMedia, { isLoading }] = useUploadMediaMutation()

  console.log('FILE', file, imageUrl)

  // Handle file change
  const handleUpload = ({ file }) => {
    console.log('file', file)
    if (!file.type.includes('image')) {
      return
    }

    if (file.name) {
      const uploadedFile = file
      setFile(uploadedFile)

      if (localUpload) {
        onChange?.(uploadedFile)
        return
      }
      const formData = new FormData()
      formData.append('content', file, 'capturedImage.jpeg')
      formData.append('title', fileName || 'capturedImage.jpeg')
      formData.append('alt', alt || fileName || 'capturedImage.jpeg')
      formData.append('access', access || 'PublicRead')

      uploadMedia(formData)
        .unwrap()
        .then((res) => {
          console.log('res', res)
          if (onChange) onChange(res?.data?.[0]?.mediaInfo?.optimized)
        })
    }
  }

  const onRemove = () => {
    setFile(null)
    onChange?.(null)
  }

  return (
    <div>
      {!file ? (
        <Upload
          className="w-full"
          rootClassName="w-full grid"
          name="image"
          showUploadList={false}
          beforeUpload={() => false} // Prevent default upload
          onChange={handleUpload}
          {...rest}>
          <div className="aspect-[397/280] cursor-pointer rounded-xl w-full flex items-center justify-center bg-[#EAEDF0]">
            <AddImageIcon />
          </div>
        </Upload>
      ) : (
        <div>
          <Image
            preview={false}
            onError={e => e.target.src = 'https://community.softr.io/uploads/db9110/original/2X/7/74e6e7e382d0ff5d7773ca9a87e6f6f8817a68a6.jpeg'}
            className="aspect-[397/280] object-contain rounded-xl bg-[#f4f4f4]"
            onClick={(e) => e.stopPropagation()}
            src={imageUrl}
          />
          <div className="flex gap-2 justify-center mt-2">
            <Upload
              rootClassName="w-auto"
              name="image"
              showUploadList={false}
              beforeUpload={() => false} // Prevent default upload
              onChange={handleUpload}
              {...rest}
              accept={'.jpeg, .jpg, .png, .webp'}>
              <Button icon={<AddImageIcon color="#2C476C"/>} type="text" />
            </Upload>

            <Button
              onClick={onRemove}
              icon={<TrashIcon width={18} height={18} color="#2C476C" />}
              type="text"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageUploader
