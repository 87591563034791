import React from 'react'
import {cn} from '../../../../utils/utils'
import {Checkbox, Image} from 'antd'

const ScreenShotCard = ({ name, onChange, checked, id, image }) => {
  return (
    <div className="text-[#2C476C]">
      <div id={`presentation-${id}`} className={cn('flex flex-col gap-3 mb-2')}>
        <Image
          className="rounded-2xl w-full aspect-[5/3] object-cover"
          src={
            image ||
            'https://community.softr.io/uploads/db9110/original/2X/7/74e6e7e382d0ff5d7773ca9a87e6f6f8817a68a6.jpeg'
          }
          preview={false}
        />
        <div className="flex items-center justify-between">
          <span>{name}</span>
          <Checkbox checked={checked} onChange={onChange} />
        </div>
      </div>
    </div>
  )
}

export default ScreenShotCard
