import React, {useEffect, useMemo, useState} from 'react'
import PresentationInfoForm from './PresentationInfoForm'
import {
  useCloneSlideMutation,
  useCreatePresentationMutation,
  useCreateSlideMutation,
  useGetPresentationSlidesQuery,
  useLazyGetPresentationQuery, useLazyGetPresentationsQuery, useRemoveMediaByPathMutation, useRemoveMediaMutation,
  useRemoveSlideMutation,
  useUpdatePresentationMutation,
  useUpdateSlideMutation, useUploadMediaMutation,
} from '../../store/presentationApi'
import {Spin} from 'antd'
import {getChangesFormData, getStringFromHTML} from '../../lib/utils'

const PresentationFormWrapper = ({ createMode, onSuccess, id, slideId }) => {
  const [presentationState, setPresentationState] = useState(null)

  const [getPresentation, { isFetching }] = useLazyGetPresentationQuery()
  const [uploadMedia, { isLoading: isUploading }] = useUploadMediaMutation()

  useEffect(() => {
    if (id) {
      getPresentation({ id })
          .unwrap()
          .then((res) => {
            setPresentationState(res.data?.[0])
          })
    }
  }, [id])

  // const {data} = useGetPresentationQuery({id}, {
  //     skip: !id,
  //     refetchOnMountOrArgChange: 3
  // });

  const [onCloneSlide, { isLoading: isCloning }] = useCloneSlideMutation()
  const [onGetPresentations] = useLazyGetPresentationsQuery()

  // const presentation = data?.data?.[0];

  const { data: slidesData } = useGetPresentationSlidesQuery(
    {
      page: 1,
      perPage: 1000,
      columnFiltersMany: {
        presentationId: [String(id)],
      },
      sort: [
        {
          field: 'order',
          type: 'asc',
        },
      ],
    },
    { skip: !id },
  )

  const slides = slidesData?.data?.[0]?.items

  const initialData = useMemo(() => {
    if (!presentationState || !slides) return null
    return {
      title: presentationState?.title,
      description: presentationState?.description || presentationState?.shortDescription || '',
      imageUrl: presentationState?.imageUrl,
      audioGender: presentationState?.audioGender || presentationState?.isVoiceEnabled ? 'Male' : 'off',
      isVoiceEnabled: presentationState?.isVoiceEnabled,
      showDescriptionPopup: presentationState?.showDescriptionPopup,
      slideTransitionDuration: String(presentationState?.slideTransitionDuration),
      mediaId: presentationState?.mediaId,
      slides: slides.map((slide) => {
        return {
          ...slide,
          description: slide?.description || slide?.shortDescription || '',
        }
      }),
    }
  }, [presentationState, slides])

  console.log("initialData", initialData);

  const [onCreate, { isLoading }] = useCreatePresentationMutation()
  const [onCreateSlide, { isLoading: isCreateSlideLoading }] =
    useCreateSlideMutation()
  const [onUpdate, { isLoading: isUpdateLoading }] =
    useUpdatePresentationMutation()
  const [onUpdateSlide, { isLoading: isUpdateSlideLoading }] =
    useUpdateSlideMutation()
  const [onRemoveSlide, { isLoading: isRemoveSlideLoading }] =
    useRemoveSlideMutation()

  const handleUpload = (file, callback) => {
    if (!file.type.includes('image')) {
      return
    }

    if (file.name) {
      const fileName = file.name
      const formData = new FormData()
      formData.append('content', file, 'capturedImage.jpeg')
      formData.append('title', fileName || 'capturedImage.jpeg')
      formData.append('alt',  fileName || 'capturedImage.jpeg')
      formData.append('access', 'PublicRead')

      uploadMedia(formData)
          .unwrap()
          .then((res) => {
            console.log('res', res)
            // if (initialData?.mediaId) {
            //   onGetPresentations({columnFilters: {
            //     mediaId: initialData?.mediaId,
            //   }}).unwrap().then((res) => {
            //       if (!res?.data?.[0]?.items?.length > 1) {
            //         deleteMedia(initialData?.mediaId)
            //       }
            //   })
            //
            // }
            if (callback) callback({
              imageUrl:res?.data?.[0]?.mediaInfo?.optimized,
              mediaId:res?.data?.[0]?.id
            })
          })
    }
  }

  const submitHandler = async ({ slides, ...values }) => {
    // Helper function to introduce a delay
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    try {
      console.log('SUBMIT XXX', values, initialData)

      // Introduce a 400-second delay before proceeding
      await delay(1000) // 400,000 milliseconds = 400 seconds

      if (!createMode) {
        const changedValues = getChangesFormData(initialData, values)

        console.log("changedValues", changedValues, initialData, values);

        if (changedValues.imageUrl instanceof File) {
          handleUpload(changedValues.imageUrl, async (data) => {
            await onUpdate({
              id,
              ...changedValues,
              ...data,
            }).unwrap()
            onSuccess?.()
          })
        } else {
          const mediaData = {};

          if (changedValues.imageUrl === null) {
            mediaData.mediaId = null
          }
          await onUpdate({
              id,
              ...mediaData,
              ...changedValues
          }).unwrap()
          onSuccess?.()
        }

        if (initialData.slides?.length) {
          // Create an array of promises for slide updates and removals
          const slidePromises = initialData.slides.map((initialSlide) => {
            initialSlide = {
              ...initialSlide,
              rawDescription: getStringFromHTML(initialSlide.description || ''),
            }
            const correspondingSlide = slides?.find(
              (slide) => slide.id === initialSlide.id,
            )

            if (correspondingSlide) {
              const slideWithRawDescription = {
                ...correspondingSlide,
                rawDescription: getStringFromHTML(
                  correspondingSlide.description || '',
                ),
              }

              console.log('initialSlide', initialSlide, correspondingSlide)

              const sentData = getChangesFormData(
                initialSlide,
                slideWithRawDescription,
              )
              console.log('sentData', initialSlide)
              if (!sentData.isNew && Object.keys(sentData || {}).length > 0) {
                return onUpdateSlide({
                  id: correspondingSlide.id,
                  ...sentData,
                  // state: initialSlide.state
                }).unwrap()
              }
            } else {
              return onRemoveSlide(initialSlide.id)
                .unwrap()
                .then((res) => {
                  // Handle response if needed
                })
            }
          })

          try {
            const newSlides = slides.filter((item) => item.isNew)
            if (newSlides.length) {
              await onCloneSlide({
                slideIds: newSlides.map((slide) => slide.id),
                presentationId: id,
              }).unwrap()
            }
          } catch (e) {
            console.log('onCloneSlide error', e)
          }

          // Await all slide-related operations
          await Promise.all(slidePromises)
        }

        console.log('onSuccessonSuccess', onSuccess)
        // Call onSuccess after all operations are complete
        onSuccess?.()
      } else {
        // Handle create mode
        if (values.imageUrl instanceof File) {
          handleUpload(values.imageUrl, async (data) => {
            await onCreate({
              ...values,
              ...data,
            }).unwrap()
            onSuccess?.()
          })
        } else {
          await onCreate(values).unwrap()
          onSuccess?.()
        }
      }
    } catch (error) {
      console.error('Submission failed:', error)
      // Optionally, handle errors here (e.g., show a notification)
    }
  }

  if (id && !initialData) {
    return (
      <div className="p-8 flex justify-center items-center min-h-[40vh]">
        <Spin />
      </div>
    )
  }

  console.log("isLoading---isLoading", isLoading);

  return (
    <div>
      <PresentationInfoForm
        slideId={slideId}
        initialData={initialData}
        loading={
          isLoading ||
          isUploading ||
          isUpdateLoading ||
          isUpdateSlideLoading ||
          isRemoveSlideLoading
        }
        createMode={createMode}
        onSubmit={submitHandler}
      />
    </div>
  )
}

export default PresentationFormWrapper
