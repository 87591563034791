import { connect } from 'react-redux'
import {
  getActiveMicroArticleId,
  getAllMicroArticles,
  getCategoriesData,
  getFilteredCategoriesData,
  getFilteredCategoriesDataST,
  getFilteredLegendsData,
  getHintsData, getIsFormPopupVisible,
  getLampData,
  getLampDataFilteredScreenShot,
  getLampModalData,
  getLampModalState,
  getLampModalStateIndex,
  getLampSelectedId,
  getLampSortedTooltipState,
  getLegends,
  getLegendsLoading,
  getMapRequirementData,
  getMapRequirementsData,
  getMenuExpendData,
  getOpenCategoryID,
  getQuizData,
  getQuizModalData,
  getQuizModalState,
  getQuizPopupData,
  getScreenShotLoadingST,
  getSelectedQuizData,
  getSortedTooltipSelectItem,
  getTimeLineExpendData,
} from '../../store/selectors'
import { setHints } from '../../store/actions/hints'
import { setQuizPopup } from '../../store/actions/quizPopup'
import { setMenuExpend } from '../../store/actions/menuExpend'
import { setMapRequirement } from '../../store/actions/mapRequirement'
import { getArticleFromAPI } from '../../store/actions/articles'
import { changeMapRequirements } from '../../store/actions/mapRequirements'
import {
  setLamp,
  setLampModalData,
  setLampModalState,
  setLampModalStateIndex,
  setSortedTooltipSelectItem,
} from '../../store/actions/lamp'
import { setTimeLineExpend } from '../../store/actions/timeLineExpend'
import {
  getSelectedQuizDataFromApi,
  setQuizModalData,
  setQuizModalState,
  setSelectedQuizData,
  setSortedTooltipSelectItemQuiz,
} from '../../store/actions/quiz'
import {
  setCategoriesClickedArticleId,
  setLampSelectedId,
  setLampSortedTooltipState,
  setMapRequiredScreenShot,
  setOpenCategoryID,
} from '../../store/actions/mapStateAction'
import {setActiveMicroArticleId} from "../../store/actions/microArticle";

const mapStateToProps = (state) => ({
  getCategories: getCategoriesData(state),
  timelineExtend: getTimeLineExpendData(state),
  filteredCategories: getFilteredCategoriesData(state),
  filteredCategoriesST: getFilteredCategoriesDataST(state),
  legends: getLegends(state),
  loadingLegends: getLegendsLoading(state),
  filteredLegends: getFilteredLegendsData(state),
  hints: getHintsData(state),
  selectedQuiz: getSelectedQuizData(state),
  quizPopup: getQuizPopupData(state),
  mapRequirement: getMapRequirementData(state),
  menuExpend: getMenuExpendData(state),
  lampData: getLampData(state),
  quizData: getQuizData(state),
  mapRequirements: getMapRequirementsData(state),
  sortedTooltipSelectItem: getSortedTooltipSelectItem(state),
  lampModalState: getLampModalState(state),
  lampModalData: getLampModalData(state),
  quizModalState: getQuizModalState(state),
  quizModalData: getQuizModalData(state),
  getOpenCategoryID: getOpenCategoryID(state),
  getLampDataFilteredScreenShot: getLampDataFilteredScreenShot(state),
  getLampSortedTooltipState: getLampSortedTooltipState(state),
  getLampSelectedId: getLampSelectedId(state),
  getLampModalStateIndex: getLampModalStateIndex(state),
  getScreenShotLoadingST: getScreenShotLoadingST(state),
  getAllMicroArticles: getAllMicroArticles(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
  getActiveMicroArticleId: getActiveMicroArticleId(state),
})

const mapDispatchToProps = {
  setHints,
  setQuizPopup,
  setMenuExpend,
  setMapRequirement,
  getArticleFromAPI,
  setMapRequirements: changeMapRequirements,
  setSortedTooltipSelectItem,
  setTimeLineExpend,
  setLampModalState,
  setLampModalData,
  setQuizModalState,
  setQuizModalData,
  setSortedTooltipSelectItemQuiz,
  getSelectedQuizDataFromApi,
  setSelectedQuizData,
  setOpenCategoryID,
  setMapRequiredScreenShot,
  setCategoriesClickedArticleId,
  setLampSortedTooltipState,
  setLampSelectedId,
  setLampModalStateIndex,
  setLamp,
  setActiveMicroArticleId,
}

const withRedux = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component)
export default withRedux
