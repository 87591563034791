import {Link, useLocation} from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import {LogOut} from "../../helpers";
import {logoutUser} from "../../store/actions/user";
import AccountBoards from "../UI/AccountBoards";
import Bookmark from "../UI/Bookmark";
import PresentationIcon from "../UI/Presentation";
import DynamicScreenshot from "../UI/DynamicScreenshot";
import MicroArticlesIcon from "../UI/MicroArticlesIcon";
import AccountPage from "../UI/AccountPage";
import Logout from "../UI/LogOut";
import {LanguageChanger} from "../languageChanger";
import './style.css'
const TopBar = (props) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
      <div className="top-btns">
        <div className="top-btns-left-bar">
          <div
            className={`main__tab ${isActive('/account-boards') ? 'active' : ''}`}>
            <Link to="/account-boards">
              <div className="main__tab-btn">
                <AccountBoards active={isActive('/account-boards')} />
              </div>
              <div className="main__tab-btn__title">{t('topicsAndLesson')}</div>
            </Link>
          </div>

          <div
            className={`main__tab ${isActive('/bookmarks') ? 'active' : ''}`}>
            <Link to="/bookmarks">
              <div className="main__tab-btn">
                <Bookmark isActive={isActive('/bookmarks')} />
              </div>
              <div className="main__tab-btn__title">
                {t('favoriteArticles')}
              </div>
            </Link>
          </div>
          {window.innerWidth > 1024 && (
            <div
              className={`main__tab ${isActive('/presentations') ? 'active' : ''}`}>
              <Link to="/presentations">
                <div className="main__tab-btn">
                  <PresentationIcon activeColor={"#FF6539"} disableColor={"#2C476C"} isActive={isActive('/presentations')} />
                </div>
                <div className="main__tab-btn__title">
                  {t('presentations')}
                </div>
              </Link>
            </div>
          )}
          {window.innerWidth > 1024 && (
            <div
              className={`main__tab ${isActive('/dynamic-screenshots') ? 'active' : ''}`}>
              <Link to="/dynamic-screenshots">
                <div className="main__tab-btn">
                  <DynamicScreenshot
                    isActive={isActive('/dynamic-screenshots')}
                  />
                </div>
                <div className="main__tab-btn__title">{t('savedMaps')}</div>
              </Link>
            </div>
          )}
          <div
            className={`main__tab ${isActive('/micro-article-board') ? 'active' : ''}`}>
            <Link to="/micro-article-board">
              <div className="main__tab-btn">
                <MicroArticlesIcon
                  isActive={isActive('/micro-article-board')}
                />
              </div>
              <div className="main__tab-btn__title">{t('microArticle.userDashboard.sectionName')}</div>
            </Link>
          </div>
        </div>
        <div className="top-btns-right-bar">
          <div
              className={`main__tab ${isActive('/account-page') ? 'active' : ''}`}>
            <Link to="/account-page">
              <div className="main__tab-btn">
                <AccountPage active={isActive('/account-page')}/>
              </div>
              <div className="main__tab-btn__title">{t('userPage')}</div>
            </Link>
          </div>
          <div className={`main__tab`}>
            <div>
              <div className="main__tab-btn" onClick={LogOut}>
                <Logout/>
              </div>
              <div className="main__tab-btn__title">{t('signOut')}</div>
            </div>
          </div>
          <LanguageChanger/>
        </div>
      </div>
    )
};

const mapDispatchToProps = {
  logoutUser,
};

export default connect(null, mapDispatchToProps)(TopBar);
