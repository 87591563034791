import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ChevronLeft, ChevronRight, CirclePause, Maximize2, MenuIcon,} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'
import PlayIcon from '../icons/PlayIcon'
import {Button, Dropdown, Modal} from 'antd'
import DotsIcon from '../icons/DotsIcon'
import TextIcon from '../icons/TextIcon'
import PenIcon from '../icons/PenIcon'

import MinimizeIcon from '../icons/MinimizeIcon'
import VoiceIcon from '../icons/VoiceIcon'
import PlayCircleIcon from '../icons/PlayCircleIcon'
import RestartIcon from '../icons/RestartIcon'
import CloseIcon from '../icons/CloseIcon'
import {cn, getLocalStorage, setLocalStorage} from '../../../../utils/utils'
import PlaybackSlideItem from './PlaybackSlideItem'
import {
  selectIsMinimized,
  selectPatumSize,
  selectPlaybackPresentation,
  setIsMinimized,
  setPatumSize,
  setPlaybackPresentation,
} from '../../store/presentationSlice'
import PresentationFormWrapper from '../form/PresentationFormWrapper'
import {useGetPresentationSlidesQuery, useUpdatePresentationMutation,} from '../../store/presentationApi'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {getSubjectsSettingsData as getSubjectsSettingsDataST} from '../../../../store/selectors'
import {dispatchScreenShotLoader, getScreenShotSingleDataAPI,} from '../../../../store/actions/mapStateAction'
import {getExternalDuration} from '../../../UI/Slides/utils'
import {useTranslation} from 'react-i18next'
import {setViewPopupVisibility} from "../../../../store/actions/microArticle";

const debounce = (fn, wait) => {
  let timeoutID
  return (...arg) => {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...arg), wait)
  }
}
const debeounced300 = debounce((fn) => fn(), 300)

const PlaybackWrapper = ({
  firstLoad,
  map,
  firstRunScreenShotStates,
  stopCountdown,
  handleEditDurationTime,
  slideNavigation,
  onExit,
  restartCountdown,
  handleSlideInfoPopup,
  pauseCountdown,
  playCountdown,
  handleShowSlides,
  showSlide,
  slideTransitionDuration,
  setIsPlaying,
  isPlaying,
  globalLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMinimized = useSelector(selectIsMinimized)
  const presentation = useSelector(selectPlaybackPresentation)
  const getSubjectsSettingsData = useSelector(getSubjectsSettingsDataST)
  const [slidesIsShow, setSlidesIsShow] = useState(true)
  const [footerHeight, setFooterHeight] = useState(0)
  const patumSize = useSelector(selectPatumSize)
  const firstLoadRef = React.useRef(true)

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [editIsOpen, setIsEditMode] = useState(false)
  const [settings, setSettings] = useState({
    text: patumSize,
    audioGender: 'off',
    isVoiceEnabled: false,
  })

  const durFromStorage =
    typeof getExternalDuration() === 'number'
      ? getExternalDuration()
      : slideTransitionDuration
  const [duration, setDuration] = useState(durFromStorage)
  const [isMenuHided, setIsMenuHided] = useState(false)
  const [isMuted, setIsMuted] = useState(false)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [searchParams] = useSearchParams()
  const [runScreenShotStates, setRunScreenShotStates] = useState(null)
  const [search] = useSearchParams();
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userInfo'))?.data)


  const [onUpdatePresentation, { isLoading: isUpdateLoading }] =
    useUpdatePresentationMutation()

  // const {onNextSlide,onExit, currentSlide,restartCountdown, onPlayToggle, isPaused, onPrevSlide} = usePlayback()

  const url = new URL(window.location.href)

  const { data: slidesData, refetch: refetchSlides } = useGetPresentationSlidesQuery(
    {
      page: 1,
      perPage: 1000,
      columnFiltersMany: {
        presentationId: [String(presentation?.id)],
      },
      sort: [
        {
          field: 'order',
          type: 'asc',
        },
      ],
    },
    { skip: !presentation?.id },
  )

  const slides = useMemo(() => slidesData?.data?.[0]?.items || [], [slidesData])
  const currentSlideNumber =
    slides?.findIndex(
      (slide) => slide.id === Number(query.get('screenSlide')),
    ) + 1

  const playbackPresentation = useSelector(
    (state) => state.presentations.playbackPresentation,
  )

  const items = [
    {
      icon: <MinimizeIcon />,
      label: t('presentation_mode.minimise'),
      key: 'minimize',
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'edit',
    },
    {
      icon: <VoiceIcon />,
      label: t('presentation_mode.voiceover'),
      key: 'audioGender',
      popupOffset: [8, 0],
      children: [
        {
          key: 'Male',
          label: t('presentation_mode.voiceover_on_option'),
        },
        {
          key: 'off',
          label: t('presentation_mode.voiceover_off_option'),
        },
      ],
    },
    {
      icon: <TextIcon />,
      label: (
        <span className="patum-dropdown--item">
          {t('presentation_mode.narrative')}{' '}
          <span className="patum-pt--overview" style={{fontSize:`${settings.text}px`}}>
            {t('presentation_mode.font_size_tooltip')}
          </span>
        </span>
      ),
      key: 'text',
      className: 'patum-dropdown--item-menu',
      popupClassName: 'patum-dropdown--item-menu--popup',
      popupOffset: [8, 0],
      children: [
        {
          key: '10',
          label: t('presentation_mode.font_size_x', { size: 10 }),
        },
        {
          key: '14',
          label: t('presentation_mode.font_size_x', { size: 14 }),
        },
        {
          key: '18',
          label: t('presentation_mode.font_size_x', { size: 18 }),
        },
        {
          key: '24',
          label: t('presentation_mode.font_size_x', { size: 24 }),
        },
        {
          key: '36',
          label: t('presentation_mode.font_size_x', { size: 36 }),
        },
        {
          key: 'disabled',
          label: t('presentation_mode.font_size_turn_off'),
        },
      ],
    },
    {
      icon: <PlayCircleIcon className="" />,
      label: t('presentation_mode.duration_label'),
      key: 'slideTransitionDuration',
      popupOffset: [8, 0],
      children: [
        {
          key: '15',
          label: t('presentation_mode.x_seconds', { seconds: 15 }),
        },
        {
          key: '30',
          label: t('presentation_mode.x_seconds', { seconds: 30 }),
        },
        {
          key: '45',
          label: t('presentation_mode.x_seconds', { seconds: 45 }),
        },
        {
          key: '60',
          label: t('presentation_mode.x_seconds', { seconds: 60 }),
        },
      ],
    },
    {
      icon: <RestartIcon />,
      label: t('presentation_mode.replay'),
      key: 'restart',
    },
    {
      icon: <CloseIcon />,
      label: t('presentation_mode.exit'),
      key: 'close',
    },
  ]

  const clickDropdownHandler = ({ key, keyPath, domEvent }) => {
    domEvent.stopPropagation()
    if (keyPath.length > 1) {
      const parentKey = keyPath[1]
      const childKey = keyPath[0]
      const params = {
        id: presentation?.id,
      }

      console.log(`Selected CHILD: ${key}`, keyPath)
      console.log(`parentKey - child: ${parentKey}`, childKey)
      setSettings({
        ...settings,
        [parentKey]: childKey,
      })

      if (parentKey === 'audioGender') {
        params.audioGender = childKey
        params.isVoiceEnabled = childKey === 'off'
      } else if (parentKey === 'slideTransitionDuration') {
        params[parentKey] = childKey
        localStorage.setItem('slideTransitionDuration', childKey)
      }

      if (parentKey === 'text') {
        localStorage.setItem('patum-text-size', childKey)
        dispatch(setPatumSize(childKey))
        params.showDescriptionPopup = childKey !== 'disabled'
        params.descriptionFontSize = params.showDescriptionPopup ? Number(childKey) : 0;
      }

      onUpdatePresentation(params)
        .unwrap()
        .then(() => {
          if (parentKey === 'slideTransitionDuration') {
            handleEditDurationTime?.(childKey)
          }
        })
      return
    }
    // setSelectedKey(key);
    console.log(`Selected: ${key}`)

    switch (key) {
      case 'text':
        // setPatumIsOpen(true);
        break
      case 'edit':
        // dispatch(setPlaybackPresentation(null))
        setIsEditMode(true)
        // setTimeout(() =>  closeHandler(), 300)
        break
      case 'restart':
        restartCountdown?.()
        break
      case 'minimize':
        dispatch(setIsMinimized(true))
        break
      case 'close':
        closeHandler();
        break
      default:
    }
  }

  const closeHandler = () => {
    dispatch(setIsMinimized(false))
    dispatch(setPlaybackPresentation(null))
    dispatch(setViewPopupVisibility(false))
    query.delete('presentation')
    query.delete('screenSlide')
    query.delete('playMode')
    query.delete('playBack')
    query.delete('screenShot')
    query.delete('userId')
    query.delete('external')
    query.delete('articleMode')
    query.delete('microArticleMode')
    stopCountdown?.()

    // window.location.href = `${window.location.pathname}?${query.toString()}`

    // window.history.pushState(
    //     null,
    //     null,
    //     `${window.location.pathname}?${query.toString()}`,
    // )
    //
    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    }, {replace: true})

    onExit?.()
  }

  const selectHandler = (e) => {
    console.log(e)
  }

  useEffect(() => {
    console.log('menuIsOpen', menuIsOpen)
    if (!menuIsOpen) {
      return
    }

    let interval = null

    interval = setInterval(() => {
      const menuItems = document.querySelectorAll(
        '.patum-dropdown--item-menu--popup .ant-dropdown-menu-item',
      )
      console.log('menuItems', menuItems)
      if (menuItems.length > 0) {
        clearInterval(interval) // Stop checking once elements are found
        menuItems.forEach((item) => {
          item.addEventListener('mouseenter', (e) => {
            const fontSize = parseInt(e.target.innerText)
            const overviewElement = document.querySelector(
              '.patum-pt--overview',
            )
            if (!isNaN(fontSize)) {
              overviewElement.style.fontSize = `${fontSize}px`
            } else {
              overviewElement.style.fontSize = 'inherit'
            }
          })
        })
      }
    }, 200) // Check every 100ms

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [menuIsOpen])

  useEffect(() => {
    let resizeObserver;
   setTimeout(() => {
     const footerElement =
         document.querySelector('#mapSlider') || document.querySelector('.footer')
     console.log("footerElement", footerElement);
     if (!footerElement) return

     resizeObserver = new ResizeObserver((entries) => {
       for (let entry of entries) {
         if (entry.target === footerElement && entry.contentRect.height) {
           console.log("entry.contentRect.height", entry.contentRect.height);
           setFooterHeight(entry.contentRect.height)
         }
       }
     })

     resizeObserver.observe(footerElement)
   },2000)

    // Cleanup on unmount
    return () => {
      resizeObserver?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (playbackPresentation) {
      setSettings({
        ...settings,
        audioGender: playbackPresentation?.audioGender || 'off',
        isVoiceEnabled: playbackPresentation?.isVoiceEnabled || false,
        slideTransitionDuration: String(
          playbackPresentation?.slideTransitionDuration === 10
            ? 15
            : playbackPresentation?.slideTransitionDuration || 15,
        ),
        text: playbackPresentation?.showDescriptionPopup
          ? playbackPresentation?.descriptionFontSize || localStorage.getItem('patum-text-size')
          : 'disabled',
      })
    }
  }, [playbackPresentation])

  useEffect(() => {
    return () => {
      dispatch(setPlaybackPresentation(null))
      dispatch(setIsMinimized(false))
    }
  }, [])

  useEffect(() => {
    if (!playbackPresentation) return

    if (currentUser && +currentUser?.id === +playbackPresentation?.createdBy) {
      onUpdatePresentation({
        id: playbackPresentation?.id,
        LastOpenedDate: new Date(),
      })
    }
  },[playbackPresentation, currentUser])

  const openMenuHandler = (menuIsOpen) => {
    if (isPlaying) {
      handlePlayingMode()
    }
    setMenuIsOpen(menuIsOpen)
  }

  const afterEditSuccess = () => {
    refetchSlides();
    // restartCountdown?.()
    setIsEditMode(false)
  }

  console.log('settings', settings, Object.values(settings))

  //

  const handleReplaceUrl = useCallback((slide, isPlaying) => {
    const selectedId = slide?.id
    const articleId = slide?.articleId
    // Use the browser's history API to update the URL when isPresentationMode changes
    const newUrl = new URLSearchParams(window.location.search)
    // const presentationParam = (!!selectedId && typeof selectedId === 'number')
    //                                     ? `&screenSlide=${selectedId}&userId=101&screenShot=true&loading=true`
    //                                     : '';
    const currentUser = JSON.parse(localStorage.getItem('userInfo'))?.data
    if (!!selectedId && typeof selectedId === 'number') {
      newUrl.set('screenSlide', selectedId)
      newUrl.set('screenShot', slide?.mapStateId || "true")

      newUrl.set('userId', `${currentUser?.id}`)
      newUrl.set('loading', 'true')
    } else if (newUrl.has('screenSlide')) {
      newUrl.delete('screenSlide')
      newUrl.delete('screenShot')
      newUrl.delete('userId')
    }
    console.log('NEW SLIDE ID', slide)

    if (!!articleId) {
      newUrl.set('articleMode', articleId)
    } else {
      newUrl.delete('articleMode')
    }

    if (isPlaying) {
      newUrl.set('playBack', 'true')
    }

    navigate(
      {
        pathname: window.location.pathname,
        search: newUrl.toString(),
      },
      { replace: false },
    )
  }, [navigate])

  const runSlide = async (id) => {
    if (!id) return
    window.scrrenShot = true
    dispatch(dispatchScreenShotLoader(true))
    const getApi = await dispatch(getScreenShotSingleDataAPI(id, 'Slide'))
    await getApi
      .call()
      .then((res) => getApi.serialized(res))
      .then((res) => getApi.setLanguage(res))
      .then((res) => getApi.setLocation(res))
      .then((res) => {
        getApi.setData(res).then((runScreenShotStates) => {
          setRunScreenShotStates(() => runScreenShotStates)
        })
      })
    // await getScreenShotSingleDataAPI(id,'Slide')
    //     .then((runScreenShotStates)=>{
    //         setRunScreenShotStates(()=>runScreenShotStates)
    //     })
  }

  useEffect(() => {
    let timer = null
    if (!map || globalLoading) return null

    if (Object.keys(getSubjectsSettingsData).length > 0) {
      let statesST = [
        'checkSettingsSTF',
        'loadBaseMap',
        'mapSTF',
        'toolBarSTF',
        'leftBarSTF',
        'timelineSTF',
        'leftBarCatOpenSTF',
        'compassSTF',
        'loadDoneSTF',
      ]
      let slideData = slides.find(
        (item) => item.id === +search.get('screenSlide'),
      )

      if (slideData && slideData?.articleId) {
        statesST = ['articleModeSTF']
      }
      timer = setTimeout(
        () => {
          if (firstLoad) {
            firstRunScreenShotStates?.(statesST)
          }
          runScreenShotStates?.(statesST)
          firstLoadRef.current = false;
        },
          firstLoadRef.current ? 2500 : 0,
      )
    }

    return () => {
      // clearTimeout(timer)
    }
  }, [
    getSubjectsSettingsData,
    runScreenShotStates,
    globalLoading,
    firstLoad,
    map,
    slides,
  ])

  // useEffect(()=>{
  //     console.log("getSlidesSelectedSlideData", );
  //     if(!getSlidesSelectedSlideData?.id) return
  //     if(!isNaN(+query.get("screenSlide")) && (+query.get("screenSlide") === getSlidesSelectedSlideData?.id)) return;
  //     handleReplaceUrl(getSlidesSelectedSlideData?.id)
  // },[getSlidesSelectedSlideData])

  useEffect(() => {
    if (query.get('loading') === 'true' || firstLoad) return
    runSlide(+query.get('screenSlide'))
  }, [searchParams, firstLoad])

  const handlePlayingMode = () => {
    if (!query.get('screenSlide') && slides.length) {
      // query.set("screenSlide", slides[0]?.id)
      handleReplaceUrl(slides[0], true)
      return
    }
    if (isPlaying) pauseCountdown()
    if (!isPlaying) playCountdown(true)
    setIsPlaying(!isPlaying)
    searchParams.set('playBack', !isPlaying)
    navigate({ search: searchParams.toString(), pathname: window.location.pathname }, { replace: false })
  }

  const handleMuteState = () => {
    setIsMuted(!isMuted)
  }

  useEffect(() => {
    if (searchParams.get('playBack') !== undefined) {

      const play = searchParams.get('playBack') === 'true'
      console.log("isPlaying", isPlaying, play);
      if (isPlaying === play) return
      setTimeout(() => {
        if (!play) pauseCountdown()
        if (play) playCountdown(true)
        setIsPlaying(play)
      }, 1000)
    }
  }, [isPlaying, searchParams.get("playBack")])

  useEffect(() => {
    if (!!query.get('external')) {
      const storageTransitionDuration = {
        ...getLocalStorage('slideTransitionDuration'),
      }
      storageTransitionDuration[query.get('presentation')] = duration
      setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      return
    }
    if (slideTransitionDuration === duration) return
    debeounced300(() => handleEditDurationTime(duration))
  }, [duration])

  useEffect(() => {
    if (
      slideTransitionDuration === duration ||
      typeof +slideTransitionDuration !== 'number' ||
      isNaN(+slideTransitionDuration)
    )
      return

    if (!!query.get('external')) {
      let storageTransitionDuration = getLocalStorage('slideTransitionDuration')
      const presentationId = query.get('presentation')
      if (!storageTransitionDuration?.[query.get('presentation')]) {
        const newObj = {
          [presentationId]: slideTransitionDuration,
          ...storageTransitionDuration,
        }
        setLocalStorage('slideTransitionDuration', newObj)
        storageTransitionDuration = getLocalStorage('slideTransitionDuration')
      }
      if (storageTransitionDuration?.[query.get('presentation')] !== duration) {
        const storageTransitionDuration = getLocalStorage(
          'slideTransitionDuration',
        )
        storageTransitionDuration[query.get('presentation')] =
          duration || slideTransitionDuration
        setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      }
      if (
        ![0, 10, 15, 20].includes(
          storageTransitionDuration[query.get('presentation')],
        )
      ) {
        storageTransitionDuration[query.get('presentation')] =
          slideTransitionDuration
        setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      }
      setDuration(() => storageTransitionDuration[query.get('presentation')])
      return
    }
    setDuration(() => slideTransitionDuration)
  }, [slideTransitionDuration])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === ' ' && !editIsOpen) {
        event.preventDefault()
        handlePlayingMode()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handlePlayingMode, editIsOpen])

  return (
    <div
      style={{
        top: isMinimized ? `calc(100vh - ${footerHeight + 60}px)` : '5px',
      }}
      className={cn(
        'playback-wrapper text-[#2C476C] transition-all duration-300 bg-white absolute top-[0px] left-[7rem] min-w-[140px] rounded-lg h-[42px] px-0',
        {
          'left-3 h-[47px]': isMinimized,
          'min-w-[350px]': !isMinimized,
        },
      )}>
      <div
        className={cn('flex items-center rounded-lg h-[42px] ', {
          'shadow-[0px_7px_26px_0px_rgba(44,71,108,0.1)]': slidesIsShow,
          'px-2  h-[47px]': isMinimized,
          'w-full': !isMinimized,
        })}>
        {!isMinimized && (
          <>
            <Button
              data-is-loading={globalLoading}
              onClick={() => setSlidesIsShow(!slidesIsShow)}
              className={cn('h-[42px] !w-[42px]', {
                'shadow-[0px_7px_26px_0px_rgba(44,71,108,0.1)]': slidesIsShow,
              })}
              icon={<MenuIcon color="#2C476C" className="playback-wrapper--icon" />}
              type="text"
            />
            <span
              data-is-loading={globalLoading}
              className="text-[14px] mx-4 flex-1 whitespace-nowrap line-clamp-1 max-w-[190px] overflow-hidden text-ellipsis"
              title={playbackPresentation?.title || ''}>
              {playbackPresentation?.title}
            </span>
          </>
        )}
        <div
          className={cn('flex items-center gap-2 mr-2', {
            'mr-0': isMinimized,
          })}>
          <span
            data-is-loading={globalLoading}
            className="cursor-pointer rounded-full flex items-center"
            onClick={handlePlayingMode}>
            {!isPlaying ? (
              <PlayIcon width={32} height={32} />
            ) : (
              <CirclePause strokeWidth={1} width={32} height={32} />
            )}
          </span>
          <div className="flex items-center gap-1">
            <span
              data-is-loading={globalLoading}
              className={cn('cursor-pointer', {
                'text-gray-400 pointer-events-none': currentSlideNumber === 1,
              })}
              role={'button'}
              onClick={() => slideNavigation(-1)}>
              {' '}
              <ChevronLeft size={14} color="#2C476C"/>
            </span>
            <span
              data-is-loading={globalLoading}
              className="text-[14px] whitespace-nowrap">
              {currentSlideNumber || '1'} / {slides?.length || ''}
            </span>
            <span
              data-is-loading={globalLoading}
              className={cn('cursor-pointer', {
                'text-gray-400 pointer-events-none':
                  currentSlideNumber === slides?.length,
              })}
              role={'button'}
              onClick={() => slideNavigation(1)}>
              <ChevronRight size={14} color="#2C476C"/>
            </span>
          </div>
          {isMinimized ? (
            <Button
              data-is-loading={globalLoading}
              type="text"
              icon={<Maximize2 size={14} color="#2C476C"/>}
              onClick={() => dispatch(setIsMinimized(false))}
            />
          ) : (
            <Dropdown
              getPopupContainer={() => document.body}
              overlayClassName="app-dropdown w-[160px]"
              open={menuIsOpen}
              onOpenChange={openMenuHandler}
              placement="bottomLeft"
              menu={{
                items,
                onClick: clickDropdownHandler,
                selectedKeys: Object.values(settings).map(item => String(item)),
                onSelect: selectHandler,
              }}
              trigger={['click']}>
              <button data-is-loading={globalLoading} className="flex" onClick={(e) => e.stopPropagation()}>
                <DotsIcon width={13} height={13} />
              </button>
            </Dropdown>
          )}
        </div>
      </div>
      {slidesIsShow && !isMinimized && (
        <div className="bg-white p-4 top-0 left-0 w-full absolute overflow-y-auto max-h-[400px] pt-12 rounded-lg -z-10">
          {slides.map((slide, index) => {
            if (slide.isHidden) return null;
            return (
                <PlaybackSlideItem
                    isLoading={globalLoading}
                    data={slide}
                    isActive={slide.id === Number(query.get('screenSlide'))}
                    key={index}
                    number={index + 1}
                    id={slide.id}
                    image={slide?.imageUrl}
                    articleId={slide?.articleId}
                    microArticleId={slide?.microArticleId}
                    name={slide.title}
                />
            )
          })}
        </div>
      )}
      <Modal
        destroyOnClose
        title={playbackPresentation?.title || ''}
        centered
        classNames={{
          body: 'max-h-[80vh] overflow-auto',
          mask:"!z-[1240]",
          header:
            'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-3',
        }}
        getContainer={() => document.body}
        className="app-modal no-padding max-h-[90vh]"
        width={950}
        wrapClassName="!z-[1240]"
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsEditMode(false)}
        open={editIsOpen}>
        <PresentationFormWrapper
          id={playbackPresentation?.id}
          onSuccess={afterEditSuccess}
        />
      </Modal>
    </div>
  )
}

export default PlaybackWrapper
