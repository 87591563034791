//generate circle outline plus icon component

import React from 'react'

const PresentationIcon = ({ strokeColor = '#2C476C', ...rest }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M34.3958 2C41.8125 2 43.6667 3.85417 43.6667 11.25V24.4375C43.6667 31.8542 41.8125 33.6875 34.4167 33.6875H11.25C3.85417 33.7083 2 31.8542 2 24.4583V11.25C2 3.85417 3.85417 2 11.25 2H34.3958Z"
        stroke={strokeColor}
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.833 33.7085V43.6668"
        stroke={strokeColor}
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 24.9165H43.6667"
        stroke={strokeColor}
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.458 43.6665H32.208"
        stroke={strokeColor}
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PresentationIcon
