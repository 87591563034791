// src/components/CustomColorPicker/CustomColorPicker.js
import React, {useEffect, useState} from 'react';
import {Button, Popover, Row, Col, Typography, Space} from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import styles from './CustomColorPicker.module.css';
import PopupFormStyles from '../PopupForm/PopupForm.module.css'
import ColorGrid from "./ColorGrid";
import IconButton from "../../UI/IconButton";
import ConnectedSpriteSvgComponent from "../../../UI/ConnectedSpriteSvgComponent";
import {useTranslation} from "react-i18next";

const CustomColorPicker = ({ initialColor, onColorChange, selectedIconObject, handleIconSelect }) => {
    const [colorInputVisible, setColorInputVisible] = useState(false);
    const {t} = useTranslation();

    const handleColorSelect = (color) => {
        onColorChange(color);
        setColorInputVisible(false);
    };

    const content = (
        <div className={styles.colorPickerPopover}>
            <Row justify="space-between" align={'middle'}>
                <Typography.Text>
                    {t("microArticle.mapView.createPopupTexts.iconPopup.colorPickerText")}
                </Typography.Text>
                <Space className={styles.iconButtonsWrapper} justify={'end'} align={'middle'}>
                    <IconButton
                        className={styles.iconButtonLight}
                        onClick={() => {
                            handleIconSelect(selectedIconObject.lightKey);
                        }}
                        icon={
                            <ConnectedSpriteSvgComponent
                                scale={0.7}
                                className={PopupFormStyles.icon}
                                name={selectedIconObject ? selectedIconObject.lightKey : ''}
                            />
                        }
                        color={initialColor}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 5,
                            maxWidth: '28px',
                            maxHeight: '28px',
                            minWidth: '28px',
                            minHeight: '28px',
                            backgroundColor: initialColor,
                            border: 'none',
                        }}
                    />
                    <IconButton
                        className={styles.iconButtonLight}
                        onClick={() => {
                            handleIconSelect(selectedIconObject.darkKey);
                        }}
                        icon={
                            <ConnectedSpriteSvgComponent
                                scale={0.7}
                                className={PopupFormStyles.icon}
                                name={selectedIconObject ? selectedIconObject.darkKey : ''}
                            />
                        }
                        color={initialColor}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 5,
                            maxWidth: '28px',
                            maxHeight: '28px',
                            minWidth: '28px',
                            minHeight: '28px',
                            backgroundColor: initialColor,
                            border: 'none',
                        }}
                    />
                </Space>
            </Row>

            <ColorGrid onColorSelect={handleColorSelect} />
        </div>
    );

    return (
        <Popover
            placement="bottomLeft"
            content={content}
            trigger="click"
            arrow={false}
            overlayInnerStyle={
                window.innerWidth < 1540 ?
                {
                    backdropFilter: 'blur(0px)',
                    boxShadow: '0px 7px 26px 0px #2C476C1A',
                    border: '1px solid #2C476C50',
                    padding: 0,
                    marginTop: 10,
                    borderRadius: 8,
                    willChange: 'transform',
                    transform: 'scale(0.85) translateZ(0)',
                    transformOrigin: 'top right',
                }
                :
                {
                    backdropFilter: 'blur(0px)',
                    boxShadow: '0px 7px 26px 0px #2C476C1A',
                    border: '1px solid #2C476C50',
                    padding: 0,
                    marginTop: 10,
                }
            }
            overlayClassName={styles.colorPickerPopoverWrapper}
            open={colorInputVisible}
            onOpenChange={(visible) => setColorInputVisible(visible)}
        >
            <Button
                shape="circle"
                size="small"
                className={styles.coloredCircleButton}
                onClick={() => setColorInputVisible(true)}
            />
        </Popover>
    );
};

export default CustomColorPicker;
