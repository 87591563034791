// src/components/PopupDetail.js
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import {
  Card,
  Carousel,
  Typography,
  Spin,
  Alert,
  Button,
  Space,
  message,
  Skeleton,
  Modal,
} from 'antd';
import Portal from '../Portal'; // Adjust import path
import {
  CloseOutlined,
  ExpandOutlined,
  MinusOutlined,
  LaptopOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  getContrastColor,
  getYouTubeEmbedURL
} from '../../utils'; // Adjust import path
import styles from './PopupDetail.module.css'; // Ensure this CSS is updated

import CollapseIn from '../../../../assets/imgs/MicroArticleIcons/collapse_in_icon.svg';
import CollapseOut from '../../../../assets/imgs/MicroArticleIcons/collapse_out_icon.svg';
import CloseIcon from '../../../../assets/imgs/MicroArticleIcons/close_icon.svg';
import PresentationIcon from '../../../../assets/imgs/MicroArticleIcons/presentation_icon.svg';
import EditIcon from '../../../../assets/imgs/MicroArticleIcons/edit_icon.svg';

import Dots from '../Dots';
import IconButton from '../../UI/IconButton';

import {
  setIsSingleMicroArticleLoading,
  setMicroArticleData,
  setPopupDetailFontSize,
  setViewPopupVisibility,
} from '../../../../store/actions/microArticle';
import {
  getIsFormPopupVisible,
  getIsSingleMicroArticleLoading,
  getMicroArticleData,
  getPopupDetailFontSize,
  getScreenShotLoadingST,
} from '../../../../store/selectors';
import { connect, useSelector } from 'react-redux';

import MediaRenderer from '../MediaRenderer';
import GalleryNew from '../../../gallery-new/UI';
import { MicroArticleService } from '../../service';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { setScreenShotGalleryIndex } from '../../../../store/actions/mapStateAction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateURLParameter } from '../../../../utils/utils';
import PresentationsSelector from '../../../presentation/ui/PresentationsSelector';
import { useMapStateImportToSlideMutation } from '../../../presentation/store/presentationApi';
import { selectMicroArticlePresentation } from '../../../presentation/store/presentationSlice';
import { stringify } from 'flatted';
import { generateSiteUrl } from '../../../presentation/lib/utils';
import { debeounced10 } from "../../../../pages/newMap/utils/helper";

const { Title, Paragraph } = Typography;

const PopupDetail = ({
                       featureId,
                       featureMicroArticleID,
                       isVisible,
                       onClose,
                       map,
                       changeEditMode,
                       changeIsViewPopupVisible,
                       changeIsModalVisible,
                       getIsFormPopupVisible,
                       getMicroArticleData,
                       setMicroArticleData,
                       getScreenShotLoadingST,
                       getIsSingleMicroArticleLoading,
                       setIsSingleMicroArticleLoading,
                       getPopupDetailFontSize,
                       setPopupDetailFontSize,
                       setViewPopupVisibility,
                     }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const carouselRef = useRef(null);
  const carouselContainerRef = useRef(null); // Ref for carousel container
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMaximized, setIsMaximized] = useState(true);
  const [isGalleryVisible, setIsGalleryVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [microArticleFirstImageIdx, setMicroArticleFirstImageIdx] = useState(null);
  const [presentationsIsOpen, setPresentationsIsOpen] = useState(false);

  const [microArticleOwner, setMicroArticleOwner] = useState({
    firstName: '',
    lastName: '',
    id: null,
  });

  const { t } = useTranslation();

  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const [onImportToSlide] = useMapStateImportToSlideMutation();
  const microArticlePresentation = useSelector(selectMicroArticlePresentation);
  const activePresentation =
      searchParams.get('presentation') && { id: +searchParams.get('presentation') };
  const [presentations, setPresentations] = useState([]);

  const mapStyledId = useSelector((state) => state?.mapState.mapStylesId);
  const baseMapGroupId = useSelector((state) => state?.mapState.baseMapGroupId);
  const baseMapIsLightSt = useSelector((state) => state?.mapState.baseMapIsLight);

  // For drag detection:
  const isDraggingRef = useRef(false);
  const dragStartX = useRef(0);
  const dragStartY = useRef(0);
  const dragThreshold = 5;
  const ignoreClickRef = useRef(false);

  // --- Fetch micro article details when visible ---
  useEffect(() => {
    if (isVisible && featureId) {
      setIsSingleMicroArticleLoading(true);
      setLoading(true);
      MicroArticleService.getMicroArticleByID(featureMicroArticleID)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              const createdBy = response?.data?.data[0]?.createdBy;
              if (createdBy) {
                return axios
                    .post(`${process.env.REACT_APP_IDENTITY_URL}/api/User/GetUsersData`, {
                      userIds: [createdBy],
                    })
                    .then((userInfo) => {
                      if (userInfo.status === 200 || userInfo.status === 204) {
                        const ownerData = userInfo.data.data[0];
                        if (ownerData && ownerData.length > 0) {
                          setMicroArticleOwner(ownerData[0]);
                        }
                      } else {
                        message.error(t('microArticle.messages.error.ownerInfoFailed'));
                      }
                      setMicroArticleData(response.data.data[0]);
                    })
                    .catch(() => {
                      message.error(t('microArticle.messages.error.ownerInfoFailed'));
                      setMicroArticleData(response.data.data[0]);
                    });
              } else {
                setMicroArticleData(response.data.data[0]);
              }
            } else {
              setError('Не удалось получить детали статьи.');
            }
          })
          .catch(() => {
            setError('Произошла ошибка при получении данных.');
          })
          .finally(() => {
            setLoading(false);
            setIsSingleMicroArticleLoading(false);
          });
    } else {
      // Cleanup
      setMicroArticleData(null);
      setMicroArticleOwner({ firstName: '', lastName: '', id: null });
      setError(null);
      setCurrentSlide(0);
    }
    return () => {
      setIsSingleMicroArticleLoading(false);
      setError(null);
      setCurrentSlide(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * "handleAfterChange" — whenever the carousel changes slide
   * (via swipe, arrow, etc.), we set currentSlide so that our Dots match.
   */
  const handleAfterChange = useCallback((newSlideIndex) => {
    setCurrentSlide(newSlideIndex);
  }, []);

  /**
   * "handleDotClickOrSwipe" — user clicked a dot => we move the carousel.
   * The carousel then calls afterChange(...) => state is updated => Dots are in sync.
   */
  const handleDotClickOrSwipe = useCallback((index) => {
    if (carouselRef.current) {
      carouselRef.current.goTo(index, false);
    }
  }, []);

  // --- When article data arrives, jump to the first image automatically ---
  useEffect(() => {
    const cantWork = !carouselRef.current || getIsSingleMicroArticleLoading;
    if (cantWork) return;

    setMicroArticleFirstImageIdx(null);
    if (getMicroArticleData && getMicroArticleData?.media) {
      for (let index = 0; index < getMicroArticleData?.media.length; index++) {
        const item = getMicroArticleData?.media[index];
        if (item.type === 'Image') {
          // Set our local "currentSlide" state
          setCurrentSlide(index);
          // Move the carousel there (one-time jump)
          carouselRef.current.goTo(index, false);
          setMicroArticleFirstImageIdx(index);
          break;
        }
      }
    }
  }, [getMicroArticleData, getIsSingleMicroArticleLoading]);

  // Font size controls
  const increaseFontSize = () => {
    setPopupDetailFontSize(Math.min(getPopupDetailFontSize + 2, 20));
  };
  const decreaseFontSize = () => {
    setPopupDetailFontSize(Math.max(getPopupDetailFontSize - 2, 14));
  };

  const togglePopupSize = () => {
    setIsMaximized((prev) => !prev);
  };

  const changeToEditMode = () => {
    changeIsViewPopupVisible(false);
    changeEditMode(true);
    changeIsModalVisible(true);
    searchParams.set('playBack', 'false');
    navigation(
        {
          search: searchParams.toString(),
          pathname: window.location.pathname,
        },
        { replace: true },
    );
  };

  // Gallery open/close
  const handleOpenGallery = () => {
    setIsGalleryVisible(true);
  };
  const toggleGallery = () => {
    setIsGalleryVisible((prev) => !prev);
  };

  // --------------------- Draggable logic on the carousel (optional) ---------------------
  const handleMouseDown = (e) => {
    isDraggingRef.current = false;
    dragStartX.current = e.clientX;
    dragStartY.current = e.clientY;
    ignoreClickRef.current = false;
    if (carouselContainerRef.current) {
      carouselContainerRef.current.classList.add(styles.grabbing);
    }
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const dx = Math.abs(e.clientX - dragStartX.current);
    const dy = Math.abs(e.clientY - dragStartY.current);
    if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
      isDraggingRef.current = true;
      ignoreClickRef.current = true;
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.add(styles.grabbing);
      }
    }
  };

  const handleMouseUp = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false;
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing);
      }
    } else {
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing);
      }
    }
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = false;
    const touch = e.touches[0];
    dragStartX.current = touch.clientX;
    dragStartY.current = touch.clientY;
    ignoreClickRef.current = false;
    if (carouselContainerRef.current) {
      carouselContainerRef.current.classList.add(styles.grabbing);
    }
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const dx = Math.abs(touch.clientX - dragStartX.current);
    const dy = Math.abs(touch.clientY - dragStartY.current);
    if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
      isDraggingRef.current = true;
      ignoreClickRef.current = true;
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.add(styles.grabbing);
      }
    }
  };

  const handleTouchEnd = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false;
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing);
      }
    } else {
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing);
      }
    }
    window.removeEventListener('touchmove', handleTouchMove);
    window.removeEventListener('touchend', handleTouchEnd);
  };
  // --------------------------------------------------------------------------------------

  const changePresentationsHandler = (presentations) => {
    presentations.forEach((presentation) => {
      const presentationId = presentation.id;
      const payload = getMicroArticleData;
      const topicID = searchParams.get('topicId');
      const lessonID = searchParams.get('lessonId');
      onImportToSlide({
        presentationId,
        microArticlesData: [
          {
            id: featureMicroArticleID,
            languageId: payload.languageId,
            title: payload.title,
            topicId: topicID,
            lessonId: lessonID,
            state: stringify({
              getMapStyledId: mapStyledId,
              baseMapGroupIdST: baseMapGroupId,
              baseMapIsLight: baseMapIsLightSt,
              microArticleData: payload,
              url: generateSiteUrl({
                presentationId,
                microArticleMode: featureMicroArticleID,
                topicId: topicID,
                lessonId: lessonID,
              }),
            }),
          },
        ],
      });
    });
    setPresentationsIsOpen(false);
  };

  if (!isVisible) return null;

  // Prepare items for GalleryNew
  const galleryItems = getMicroArticleData?.media?.map((item) => {
    let type;
    let path = item.path;
    if (item.type.trim().toLowerCase() === 'url') {
      // is it YouTube?
      const isYouTube = /youtube\.com|youtu\.be/.test(item.path);
      if (isYouTube) {
        type = 3; // iframe
        path = getYouTubeEmbedURL(item.path);
      } else {
        // some other URL => still treat as type=3 (iframe, e.g.)
        type = 3;
      }
    } else {
      switch (item.type.trim().toLowerCase()) {
        case 'image':
          type = 0;
          break;
        case 'video':
          type = 1;
          break;
        case 'iframe':
          type = 3;
          break;
        default:
          type = -1;
      }
    }
    return {
      ...item,
      type,
      path,
    };
  });

  return (
      <>
        <Portal containerSelector=".main" className={styles.portal}>
          <Card
              className={isMaximized ? styles.card : styles.cardMinimized}
              styles={{
                body: { padding: '0' },
                header: {
                  padding: !isMaximized ? '16px' : '9px 0 0 0',
                  borderBottom: 'none',
                  width: '100%',
                },
              }}
              title={
                isMaximized ? (
                    <div className="headerSkeletonWrapper">
                      {getIsSingleMicroArticleLoading ? (
                          <div className="headerSkeleton">
                            <Skeleton.Avatar
                                className="headerSkeletonInitials"
                                active
                                shape="square"
                                size={30}
                            />
                            <Skeleton.Input active style={{ width: 200 }} />
                          </div>
                      ) : (
                          <div className={styles.header}>
                            <div className={styles.titleContainer}>
                              <div style={{ minHeight: 'max-content', margin: '.8em 0 .5em 0' }} />
                              <Title level={4} className={styles.title}>
                                <div
                                    className={styles.titleText}
                                    style={{
                                      fontSize:
                                          getMicroArticleData &&
                                          getMicroArticleData?.title &&
                                          getMicroArticleData?.title.length > 60
                                              ? '18px'
                                              : '20px',
                                    }}
                                >
                          <span
                              className={styles.nameInitials}
                              style={{
                                // background: getMicroArticleData?.iconBackgroundColor,
                                // color: getContrastColor(getMicroArticleData?.iconBackgroundColor),
                              }}
                          >
                            {microArticleOwner.id
                                ? `${microArticleOwner.firstName[0].toUpperCase()} ${microArticleOwner.lastName[0].toUpperCase()}`
                                : ''}
                          </span>
                                  {getMicroArticleData
                                      ? getMicroArticleData?.title?.toUpperCase()
                                      : ''}
                                </div>
                              </Title>
                            </div>
                          </div>
                      )}
                      <div className={styles.buttons}>
                        <Button
                            type="text"
                            icon={<img className={styles.customIcon} src={CollapseIn} />}
                            onClick={togglePopupSize}
                            className={styles.toggleButton}
                        />
                        <Button
                            type="text"
                            icon={<img style={{ width: '24px', height: '24px' }} src={CloseIcon} />}
                            onClick={() => {
                              setMicroArticleFirstImageIdx(null);
                              onClose();
                            }}
                            className={styles.closeButton}
                        />
                      </div>
                    </div>
                ) : (
                    <div className={styles.minimizedHeader}>
                      <div className={styles.minimizedImageWrapper}>
                        {microArticleFirstImageIdx !== null &&
                        getMicroArticleData?.media?.length ? (
                            <img
                                src={getMicroArticleData?.media[microArticleFirstImageIdx].path}
                                alt={
                                  getMicroArticleData?.media[microArticleFirstImageIdx].caption
                                }
                                className={styles.minimizedImage}
                            />
                        ) : null}
                        <div
                            className={styles.minimizedTitleWrapper}
                            style={{
                              maxWidth: microArticleFirstImageIdx
                                  ? 'calc(100% - 126px)'
                                  : '100%',
                              marginLeft: microArticleFirstImageIdx ? 16 : 0,
                            }}
                        >
                          <Typography.Text className={styles.minimizedTitle}>
                            {getMicroArticleData
                                ? getMicroArticleData?.title?.toUpperCase()
                                : ''}
                          </Typography.Text>
                        </div>
                      </div>
                      <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            flexGrow: 1,
                            maxWidth: 'fit-content',
                          }}
                      >
                        <div className={styles.minimizedButtons}>
                          <Button
                              type="text"
                              icon={<img className={styles.customIcon} src={CollapseOut} />}
                              onClick={togglePopupSize}
                              className={styles.toggleButton}
                          />
                          <Button
                              type="text"
                              icon={<img style={{ width: '24px', height: '24px' }} src={CloseIcon} />}
                              onClick={onClose}
                              className={styles.closeButton}
                          />
                        </div>
                      </div>
                    </div>
                )
              }
              bordered={false}
          >
            {isMaximized && (
                <>
                  {getIsSingleMicroArticleLoading || loading ? (
                      <div className="skeletonContainer">
                        <div className="carouselSkeleton">
                          <Skeleton.Image active style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className="descriptionSkeleton">
                          <Skeleton paragraph={{ rows: 2 }} active />
                        </div>
                        <div className="buttonsSkeleton">
                          <Skeleton.Button className="skeletonButton" active shape="square" />
                          <Skeleton.Button className="skeletonButton" active shape="square" />
                          <Skeleton.Button className="skeletonButton" active shape="square" />
                          <Skeleton.Button className="skeletonButton" active shape="square" />
                        </div>
                      </div>
                  ) : error ? (
                      <Alert message={error} type="error" showIcon />
                  ) : getMicroArticleData ? (
                      <div className={styles.content}>
                        {/* Carousel Section */}
                        {getMicroArticleData?.media?.length > 0 && (
                            <div
                                className={styles.carouselContainer}
                                ref={carouselContainerRef}
                                onMouseDown={handleMouseDown}
                                onTouchStart={handleTouchStart}
                            >
                              <Carousel
                                  ref={carouselRef}
                                  draggable={true}
                                  arrows={false}
                                  infinite={false}
                                  autoplay={false}
                                  dots={false}
                                  afterChange={handleAfterChange} // Sync Carousel -> State
                                  className={styles.carousel}
                              >
                                {getMicroArticleData?.media.map((mediaItem, index) => (
                                    <div
                                        key={mediaItem.mediaId || index}
                                        className={styles.carouselItem}
                                        onClick={(e) => {
                                          // If user was dragging, ignore click
                                          if (ignoreClickRef.current) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return;
                                          }
                                          handleOpenGallery();
                                        }}
                                    >
                                      <MediaRenderer mediaItem={mediaItem} />
                                    </div>
                                ))}
                              </Carousel>
                            </div>
                        )}

                        {/* Dot Pagination Section */}
                        {getMicroArticleData?.media?.length > 0 && (
                            <Dots
                                total={getMicroArticleData?.media.length}
                                active={currentSlide}
                                onScrollTo={handleDotClickOrSwipe}
                                maxDots={6}
                                passiveDotWidth={8}
                                activeDotWidth={10}
                                passiveDotHeight={8}
                                activeDotHeight={10}
                                passiveColor="#dbdbdb"
                                activeColor="#2C476C"
                                marginHorizontal={3}
                                smallerDotScale={0.7}
                            />
                        )}

                        {/* Description Section */}
                        {getMicroArticleData?.description && (
                            <div className={`${styles.description} ${styles.customScroll}`}>
                              <Paragraph
                                  className={styles.paragraph}
                                  style={{ fontSize: `${getPopupDetailFontSize}px` }}
                              >
                                {getMicroArticleData?.description}
                              </Paragraph>
                            </div>
                        )}

                        {/* Action Buttons */}
                        <div className={styles.actionButtons}>
                          <Space>
                            <IconButton
                                disabled={
                                    (!getMicroArticleData?.description?.length) ||
                                    getPopupDetailFontSize === 20
                                }
                                icon={<Typography.Text strong>A +</Typography.Text>}
                                onClick={increaseFontSize}
                            />
                            <IconButton
                                disabled={
                                    (!getMicroArticleData?.description?.length) ||
                                    getPopupDetailFontSize === 14
                                }
                                icon={<Typography.Text strong>A -</Typography.Text>}
                                onClick={decreaseFontSize}
                            />
                            <IconButton
                                onClick={() => setPresentationsIsOpen(true)}
                                icon={
                                  <img style={{ width: '16px', height: '16px' }} src={PresentationIcon} />
                                }
                            />
                            <IconButton
                                icon={<img style={{ width: '16px', height: '16px' }} src={EditIcon} />}
                                onClick={changeToEditMode}
                                disabled={loading}
                            />
                          </Space>
                        </div>
                      </div>
                  ) : (
                      <div>No data available.</div>
                  )}
                </>
            )}
          </Card>
        </Portal>

        {/* Fullscreen Gallery */}
        {isGalleryVisible && getMicroArticleData && (
            <Portal containerSelector="#root">
              <GalleryNew
                  isFromMicroArticle
                  isGrid={false}
                  mapState={map}
                  feauturedImageIdx={currentSlide}
                  title={getMicroArticleData?.title}
                  gallery={isGalleryVisible}
                  toggleGallery={toggleGallery}
                  clickedMediaImage={getMicroArticleData?.media[currentSlide]?.path}
                  feauturedImage={getMicroArticleData?.media[currentSlide]?.path}
                  galleryItems={galleryItems}
                  fromArticle={false}
                  isFullScreen={isFullScreen}
                  setGalleryFullScreen={setIsFullScreen}
                  articleId={getMicroArticleData?.id}
                  screenShotFirstLoad={getScreenShotLoadingST}
              />
            </Portal>
        )}

        {/* Modal with PresentationsSelector */}
        <Modal
            destroyOnClose
            title={t('presentation_mode.presentations')}
            centered
            classNames={{
              header:
                  'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
            }}
            className="app-modal max-h-[90vh]"
            wrapClassName="!z-[1240]"
            width={950}
            footer={false}
            style={{ padding: 0 }}
            onCancel={() => setPresentationsIsOpen(false)}
            open={presentationsIsOpen}
        >
          <PresentationsSelector
              onSelect={changePresentationsHandler}
              data={presentations}
              defaultSelected={activePresentation}
          />
        </Modal>
      </>
  );
};

const mapStateToProps = (state) => ({
  getMicroArticleData: getMicroArticleData(state),
  getScreenShotLoadingST: getScreenShotLoadingST(state),
  getIsSingleMicroArticleLoading: getIsSingleMicroArticleLoading(state),
  getPopupDetailFontSize: getPopupDetailFontSize(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
});

const mapDispatchToProps = {
  setMicroArticleData,
  setIsSingleMicroArticleLoading,
  setPopupDetailFontSize,
  setViewPopupVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PopupDetail));
