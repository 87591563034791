import i18n from '../../../locales/i18n'
import axios from 'axios'

export class MicroArticleService {
  static baseUrl = `${process.env.REACT_APP_ARTICLE_URL}`

  static baseSpriteUrl = `${process.env.REACT_APP_GIS_URL}`

  static baseUrlAPI = `${MicroArticleService.baseUrl}/api`
  static getAllMicroArticleURL = () =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/DataProvider/${i18n.language === 'hy' ? 1 : 2}`
  static createMicroArticleURL = () =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle`
  static updateMicroArticleMediaURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}/Media`
  static getMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`
  static updateMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`
  static deleteMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`
  static getMicroArticleSpriteCategoriesURL = () =>
    `${MicroArticleService.baseSpriteUrl}/IconGroup`;
  static getMicroArticleSpriteIconsCategoriesURL = () => {
    const lang = i18n.language === 'hy' ? 1 : 2
    return `${this.getMicroArticleSpriteCategoriesURL()}/DataProvider/${lang}`
  }

  static getMicroArticleSpriteIconsURL = () =>
    `${MicroArticleService.baseSpriteUrl}/Icon`;

  static getMicroArticleSpriteIconsDataProviderUrl = () => {
    // const lang = i18n.language === 'hy' ? 1 : 2
    return `${this.getMicroArticleSpriteIconsURL()}/DataProvider`
  }

  static getAllMicroArticles = async (body) => {
    return await axios
      .post(this.getAllMicroArticleURL(), body)
      .then((res) => res)
  }

  static createMicroArticle = async (body) => {
    if (!body) {
      console.error('body is required for creating micro article')
      return
    }

    const responseData = await axios
      .post(this.createMicroArticleURL(), body)
      .then((res) => {
        const data = res.data
        if (data) {
          return res
        } else {
          console.error('something went wrong while fetching micro articles')
          return null
        }
      })

    return responseData
  }

  static getMicroArticleByID = async (id) => {
    if (!id) {
      console.error('id is required for getting micro article')
      return
    }

    return await axios.get(this.getMicroArticleByIDURL(id)).then((res) => res)
  }

  static updateMicroArticleByID = async (id, body) => {
    if (!id) {
      console.error('id is required for updating micro article')
      return
    }
    if (!body) {
      console.error('body is required for updating micro article')
      return
    }

    return await axios
      .put(this.updateMicroArticleByIDURL(id), body)
      .then((res) => res)
  }

  static deleteMicroArticleByID = async (id) => {
    if (!id) {
      console.error('id is required for deleting micro article')
      return
    }

    return await axios
      .delete(this.deleteMicroArticleByIDURL(id))
      .then((res) => {
        const data = res.data
        if (data) {
          return data
        } else {
          console.error('something went wrong while fetching micro articles')
          return []
        }
      })
  }

  static updateMicroArticleMedia = async (id, body) => {
    if (!id) {
      console.error('id is required for updating micro article media')
      return
    }
    if (!body) {
      console.error('body is required for updating micro article media')
      return
    }

    return await axios
      .put(this.updateMicroArticleMediaURL(id), body)
      .then((res) => res)
  }

  static getMicroArticlesForMap = async () => {
    const params = new URLSearchParams(window.location.search)
    const topicID = params.get('topicID')
    const lessonID = params.get('lessonID')
    const userData = JSON.parse(localStorage.getItem('userInfo'))

    const body = {
      pageSize: 9999,
      pageNumber: 0,
      userId: userData?.data.id,
      relatedTopics: topicID ? [topicID] : [],
      relatedLessons: lessonID ? [lessonID] : [],
    }

    try {
      const microArticlesCall = await this.getAllMicroArticles(body)
      const microArticles = microArticlesCall.data.data[0].items
      return microArticles.map(item => ({ ...item, show: true }))
    } catch (error) {
      console.error('Error fetching micro articles for map:', error)
      return []
    }
  }

  static getMicroArticlesForBoard = async (page, userId, searchParams) => {
    // const topicId = body.topicId;
    // const lessonId = body.lessonId;
    const payload =  !searchParams ?
        {
          pageSize: 10,
          pageNumber: page,
          userId: userId,
          relatedTopics: [],
          relatedLessons: [],
        }
    :
        {
          pageSize: 10,
          pageNumber: page,
          userId: userId,
          relatedTopics: [],
          relatedLessons: [],
          search: [
            {
              searchColumn: "title",
              searchText: searchParams,
              searchComparer: "Contains"
            }
          ],
        }

    try {
      return await this.getAllMicroArticles(payload);
    } catch (error) {
      return null;
    }
  }

  static getMicroArticleSpriteCategories = async (searchParams) => {
    // const body = {
    //   "pageSize": 999999999,
    //   "pageNumber": 1,
    //   // "search": [
    //   //   {
    //   //     "searchColumn": "string",
    //   //     "searchText": "string",
    //   //     "searchComparer": "Equal"
    //   //   }
    //   // ],
    //   "sort": [
    //     {
    //       "sortColumn": "name",
    //       "sortDir": "Asc"
    //     }
    //   ]
    // }

    const tempBody = {
      "pageSize": 9999999,
      "pageNumber": 1,
      "sort": [
        {
          "sortColumn": "order",
          "sortDir": "Asc"
        }
      ]
    }


    return await axios
      .post(this.getMicroArticleSpriteIconsCategoriesURL(), tempBody)
      .then((res) => res)
  }

  static getMicroArticleSpriteIconsByCategoryId = async (catIds, searchTerm) => {
    const payload =  searchTerm ?
        {
          "pageSize": 99999999,
          "pageNumber": 1,
          // "search": [
          //   {
          //     "searchColumn": "key",
          //     "searchText": searchTerm,
          //     "searchComparer": "Contains"
          //   }
          // ],
          // "sort": [
          //   {
          //     "sortColumn": "string",
          //     "sortDir": "Asc"
          //   }
          // ],
          // "showOnlyGrouplessIcons": true,
          "groupIds": catIds,
          "searchTerm": searchTerm,
          "includeData": false,
        }
          :
        {
          "pageSize": 99999999,
          "pageNumber": 1,
          "groupIds": catIds,
          "includeData": false,
        }

        const res = await axios
        .post(this.getMicroArticleSpriteIconsDataProviderUrl(), payload)
        .then((res) => res)

    return res;
  }
}
