import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, Row} from "antd";
import TextEditor from "../elements/TextEditor";
import Selector from "../elements/Selector";
import VolumeIcon from "../icons/VolumeIcon";
import DurationIcon from "../icons/DurationIcon";
import ImageUploader from "../elements/ImageUploader";
import PatumToggle from "../elements/PatumToggle";
import PresentationSlideFormRow from "../slides/PresentationSlideFormRow";
import EyeIcon from "../icons/EyeIcon";
import CopyIcon from "../icons/CopyIcon";
import TrashIcon from "../icons/TrashIcon";
import {cn} from "../../../../utils/utils";
import {getStringFromHTML} from "../../lib/utils";
import VolumeOffIcon from "../icons/VolumeOffIcon";
import {useTranslation} from "react-i18next";

const maxCount = 100;

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
        {label && <span style={{marginLeft: 0}}>{label}</span>}
        {required && <span style={{ color: "red", marginLeft: 4}}>*</span>}
    </>
);

const PresentationForm = ({createMode,slideId, initialData,loading, onSubmit}) => {
  const {t} = useTranslation();
    const [form] = Form.useForm();
    // const stringValue = form.getFieldValue('title');
    const stringValue = Form.useWatch('title', form);
    const slides = Form.useWatch('slides', form) || initialData?.slides || [];
    const audioGenderValue = Form.useWatch('audioGender', form);

    const [selectedSlides, setSelectedSlides] = useState([]);
    const hasSelected = selectedSlides.length > 0;

    console.log("---------", "LOADER FORM ", loading);

    const submitHandler = (values) => {
        const sentData = {
            ...values,
            audioGender: values.audioGender === 'off' ? null : (values.audioGender || values?.isVoiceEnabled ? 'Male' : 'off'),
            isVoiceEnabled: values.isVoiceEnabled || values.audioGender === 'Male',
            rawDescription: getStringFromHTML(values.description || ''),
        }
        console.log("onSubmit", onSubmit);
        onSubmit?.(sentData);
        console.log("SUBMIT FORM", values);

        // form.submit();
    }

    console.log("initialData IN FORM::::---", initialData);

    const selectSlideHandler = (index) => {

        if (selectedSlides.includes(index)) {
            setSelectedSlides(selectedSlides.filter(i => i !== index))
        } else {
            setSelectedSlides([...selectedSlides, index])
        }
    }

    const removeSelectedHandler = () => {
        // remove selected from form

        form.setFieldsValue({
            slides: slides.filter((_, i) => !selectedSlides.includes(i))
        })
        setSelectedSlides([])
    }

    const cloneSelectedHandler = () => {
        const newSlides = slides.filter((_, i) => selectedSlides.includes(i));
        const transformedNewSlides = newSlides.map((slide, i) => ({
            ...slide,
            uuid: Math.random(),
            isNew: true,
            state:"{\"status\":\"noState\"}",
            title: `(${newSlides.filter(item => item.id === slide.id).length + 1}) ${slide.title}`,
        }))
        // clone selected from form
        form.setFieldsValue({
            slides: [
                ...transformedNewSlides,
                ...slides,
            ]
        })
        setSelectedSlides([])
    }

    const cloneSlideHandler = id => {
        const slideData = slides.find(slide => slide.id === id);
        const newSlide = {
            ...slides.find(slide => slide.id === id),
            uuid: Math.random(),
            isNew: true,
            title: `(${slides.filter(item => item.id === slideData.id).length + 1}) ${slideData.title}`,
        };

        console.log("newSlide", newSlide);
        form.setFieldsValue({
            slides: [newSlide, ...slides]
        })
    }

    const hiddenSlideHandler = index => {
        console.log(index, 'hiddenSlideHandler');
        form.setFieldsValue({
            slides: slides.map((slide, i) => ({...slide, arman: 1, isHidden: i === index ? !slide.isHidden : slide.isHidden}))
        })
    }

    const hiddenSelectedHandler = () => {
        form.setFieldsValue({
            slides: [
                ...slides.map((slide, i) => ({...slide, isHidden: selectedSlides.includes(i) ? !slide.isHidden : slide.isHidden})),
            ]
        })
        setSelectedSlides([])
    }

    useEffect(() => {
        console.log("slideId", slideId);
        if (slideId) {
            // scroll to slide


setTimeout(() => {

    const slide = document.getElementById(`slide-${slideId}`);

        console.log("slide", slide);
        if (slide) {
            slide.scrollIntoView({behavior: "smooth", block: "center"});
        }
    },1000)
        }
    }, [slideId]);

    console.log("initialData IN FORMMMM", initialData);

    return (
        <div className="text-[#2C476C]">
            <Form form={form} className="h-full flex flex-col" requiredMark={customizeRequiredMark} layout="vertical" initialValues={initialData || {
                title:'',
                description:'',
                audioGender: 'off',
                slideTransitionDuration: '15',
                showDescriptionPopup: false,
                slides: [{
                    name: '',
                    description: '',
                }],
                imageUrl: ''
            }} wrapperCol={{
                span: 24
            }}
            onFinish={submitHandler}>
                <div className={cn("max-h-[70dvh]  2xl:pb-8 pb-4", {
                    "overflow-y-auto": !!slides?.length
                })}>
                    <div className="2xl:px-8 2xl:pt-8 px-4 pt-0">
                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item
                                    name="title"
                                    label={t('presentation_mode.name')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('presentation_mode.name_validation_text'),
                                        },
                                        {
                                            max: 100,
                                            message: t('presentation_mode.text_max_100_message'),
                                        }
                                    ]}
                                >
                                    <Input rootClassName="h-[44px]"/>

                                </Form.Item>
                                <div className="flex justify-end -mt-5 absolute bottom-[4px] right-[12px]">
                                        <span className="text-[12px] text-[#2C476C50]">
                                            <span
                                                className={cn({
                                                    "text-red-500": (stringValue || '').length > maxCount,
                                                })}
                                            >
                                                {(stringValue || '').length}
                                            </span>
                                            /{maxCount}
                                        </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="imageUrl"
                                    label={t("presentation_mode.main_image")}
                                    rules={[]}
                                >
                                    <ImageUploader localUpload fileName={`pm-${Date.now()}`}/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name="description"
                                    label={t("presentation_mode.description")}
                                    rules={[ {
                                        validator: (_, value) => {
                                            const plainText = getStringFromHTML(value || '');
                                            if (plainText.length > 1000) {
                                                return Promise.reject(t('presentation_mode.text_max_1000_message'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }]}
                                >
                                    <TextEditor className="!h-[213px]" showCount maxCount={1000}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider/>
                   <div className="2xl:px-8 2xl:my-3 my-1 px-4">
                       <Row gutter={8}>
                           <Col span={7}>
                              <Form.Item name="audioGender">
                                  <Selector label={t("presentation_mode.voiceover")}  icon={audioGenderValue !== 'off' ? <VolumeIcon/> : <VolumeOffIcon/>} options={[{
                                      key:"Male",
                                      label:t("presentation_mode.voiceover_on"),
                                      disabled: true,
                                  }, {
                                      key:"off",
                                      label:t("presentation_mode.voiceover_off"),
                                  }]}/>
                              </Form.Item>
                           </Col>
                           <Col span={7}>
                               <Form.Item name="slideTransitionDuration">
                               <Selector label={t("presentation_mode.duration_label")} icon={ <DurationIcon className="text-red-300"/>} options={[{
                                   key:"15",
                                   label:t("presentation_mode.x_seconds",{seconds: 15})
                               }, {
                                   key:"30",
                                   label:t("presentation_mode.x_seconds",{seconds: 30})
                               }, {
                                   key:"45",
                                   label:t("presentation_mode.x_seconds",{seconds: 45})
                               }, {
                                   key:"60",
                                   label:t("presentation_mode.x_seconds",{seconds: 60})
                               }
                               ]}/>
                               </Form.Item>
                           </Col>
                           <Col span={7}>
                               <Form.Item name="showDescriptionPopup">
                                <PatumToggle/>
                               </Form.Item>
                           </Col>
                       </Row>
                       {
                           (!createMode) &&
                           <div className="w-full">

                               <Form.List name="slides">
                                   {(fields, { add, remove }) => {
                                       console.log("fields", fields);
                                       if (!slides) return null;
                                       return (
                                           <div>
                                               <div className="flex justify-between items-center flex-wrap 2xl:my-3 2xl:pr-5  my-1 pr-4">
                                                 <span
                                                   className="uppercase font-medium text-[18px] mb-3 mt-5 block flex-1 text-[#2C476C] tracking-wider">{t('presentation_mode.presentation_slides')}</span>

                                                   <div className="flex items-center gap-1">
                                                       <Button disabled={!hasSelected} onClick={hiddenSelectedHandler} icon={<EyeIcon/>} type="text"></Button>
                                                       <Button disabled={!hasSelected} onClick={cloneSelectedHandler} icon={<CopyIcon/>} type="text"></Button>
                                                       <Button disabled={!hasSelected} onClick={removeSelectedHandler} icon={<TrashIcon/>} type="text"></Button>
                                                   </div>
                                               </div>
                                               {fields.map(({key, name, ...restField}) => {
                                                   if (!slides?.[name]) return null;
                                                   return (
                                                       <PresentationSlideFormRow
                                                           onSelect={selectSlideHandler}
                                                           onCopy={cloneSlideHandler}
                                                           onRemove={remove}
                                                           onHidden={hiddenSlideHandler}
                                                           selected={selectedSlides.includes(name)}
                                                           key={name}
                                                           selectedSlide={slideId}
                                                           index={name}
                                                           slideData={slides?.[name]}
                                                           {...restField}
                                                       />
                                                   )
                                               })}
                                           </div>
                                       )
                                   }}
                               </Form.List>
                           </div>
                       }
                   </div>
                </div>
                <div className={cn("flex justify-center mt-9 mb-9", {
                    "mt-3 mb-4": !!slides?.length
                })}>
                    <Button htmlType="submit" loading={loading} rootClassName="w-[200px] uppercase text-[16px] bg-[#FFDE5A] rounded-full"
                            type="text">{t("presentation_mode.save")}</Button>
                </div>
            </Form>
        </div>
    );
};

export default PresentationForm;
