import React, {useEffect} from 'react'
import PresentationButton from './PresentationButton'
import {Popover} from 'antd'
import PresentationList from './PresentationList'
import {useDispatch, useSelector} from 'react-redux'
// import {selectPlaybackPresentation} from "../store/presentationSlice";
import Draggable from './elements/Draggable'
import {
  selectCurrentSlide,
  selectIsMinimized,
  selectPatumSize,
  selectPlaybackPresentation, selectPresentationsIsShown,
  setIsMinimized,
  setPlaybackPresentation, setPresentationsIsShown,
} from '../store/presentationSlice'
import {useGetPresentationQuery, useGetSlideQuery,} from '../store/presentationApi'
import {useSearchParams} from 'react-router-dom'
import ReactQuill from "react-quill";
import {cn} from "../../../utils/utils";
import {getIsScreenShotMode} from "../../../store/selectors";

const PresentationWrapper = ({ children, isGlobalLoading }) => {
  const dispatch = useDispatch()
  const playbackPresentation = useSelector(selectPlaybackPresentation)
  const isMinimized = useSelector(selectIsMinimized)
  const presentationsIsShown = useSelector(selectPresentationsIsShown)
  const isScreenShotMode = useSelector(getIsScreenShotMode)

  const currentSlide = useSelector(selectCurrentSlide)
  const [search] = useSearchParams()
  const patumSize = useSelector(selectPatumSize)

  console.log('currentSlide in wrapper', currentSlide)

  const { data: slideData } = useGetSlideQuery(
    currentSlide?.id || currentSlide || search.get('screenSlide'),
    { skip: !currentSlide && !search.get('screenSlide') },
  )
  // const {data:slideDataX} = useGetSlideQuery(2123)

  console.log('search.get("presentation")', search.get('presentation'))
  const { data } = useGetPresentationQuery(
    { id: search.get('presentation') },
    { skip: !search.get('presentation') },
  )

  const currentSlideData = slideData?.data?.[0]

  useEffect(() => {
    // document.querySelector(".map-boby").addEventListener("click", () => setIsOpened(false))
  }, [])

  useEffect(() => {
    if (data?.data?.[0] && search.get('presentation')) {
      dispatch(setPlaybackPresentation(data?.data?.[0]))
    }
  }, [data, search])

  useEffect(() => {
    if (playbackPresentation) {
      dispatch(setPresentationsIsShown(false))
    }
  }, [playbackPresentation])

  const clickButtonHandler = () => {
    if (playbackPresentation) {
      dispatch(setIsMinimized(!isMinimized))
      return
    }
    dispatch(setPresentationsIsShown(!presentationsIsShown))
  }

    console.log("WRAPPER setPlaybackPresentation", playbackPresentation);

    return (
    <>
      <div className="presentation-left-bar--container text-[#2C476C]">
        <Popover
          open={presentationsIsShown}
          trigger="click"
          overlayClassName="z-[9999]"
          content={<PresentationList />}
          disabled={playbackPresentation}
          getPopupContainer={() => document.querySelector('.map-boby')}
          placement="rightBottom"
          arrow={false}
          destroyTooltipOnHide
          overlayInnerStyle={{
            padding: 0,
            borderRadius: 10,
            overflow: 'hidden',
            transform: 'translateY(-5px)',
          }}>
          <>
            {' '}
            {children ? (
              children()
            ) : (
              <PresentationButton
                isLoading={isGlobalLoading}
                onClick={clickButtonHandler}
                disabled={isScreenShotMode}
                isActive={presentationsIsShown}
              />
            )}
          </>
        </Popover>
        {/*{*/}
        {/*    playbackPresentation && <PlaybackWrapper/>*/}
        {/*}*/}
      </div>
      {playbackPresentation?.showDescriptionPopup &&
        (currentSlideData?.description || currentSlideData?.shortDescription) && (
          <Draggable>
            <div
              className="p-4 text-[#2C476C] max-w-[500px] bg-white shadow-2xl rounded min-w-[140px]"
              style={{ fontSize: patumSize ? `${patumSize}px` : 'initial' }}>
              <div data-is-loading={isGlobalLoading}
              >
                  <ReactQuill value={currentSlideData?.description || currentSlideData?.shortDescription || ''} readOnly={true} className={cn("border-none [&>.ql-toolbar]:hidden readably--quill", {
                      "inherit-fontsize--quill": patumSize
                  })}
                  />
              </div>
            </div>
          </Draggable>
        )}
    </>
  )
}

export default PresentationWrapper
