import {parse, stringify} from "flatted";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import ScreenShotIcon from "../../../../assets/imgs/icons/screenshot-icon.svg";
import ScreenShotIconWhite from "../../../../assets/imgs/icons/screenshot-icon-white.svg";
import {
    createScreenShotAPI,
    setScreenShotSendDataST,
    setScreenShotIntermediateDataST,
    setScreenShotButtonClickST,
    setScreenShotButtonStateST,
    editScreenDataAPI, setIsScreenShotMode,
} from "../../../../store/actions/mapStateAction";
import {
    getArticlesData,
    getCategoryArticleClickedId,
    getColorPickerShowState,
    getCompassCoordinates,
    getEraserClickedState,
    getFilteredCategoriesData,
    getFilteredLegendsData,
    getHintsData,
    getLampData,
    getLampModalState,
    getLampModalStateIndex,
    getLampSelectedId,
    getLampSortedTooltipState,
    getLessonNavigationData,
    getMapBounceData,
    getMapCenterCoordinatesST,
    getMapMarkerInfo,
    getMapRequirementData,
    getMapRequirementsData,
    getMapStateSingleData,
    getMapStyledDarkId,
    getMenuData,
    getMenuExpendData,
    getNewAngle,
    getNewRotate,
    getOpenCategoryID,
    getPainterColor,
    getPainterColorData,
    getPainterGeoJsonDataST,
    getPainterSize,
    getPainterSizeData,
    getPainterStartData,
    getQuizPopupData,
    getRulerClickedState,
    getRulerGeoJsonDataST,
    getRulerPopupShowST,
    getCommentToolboxState,
    getCommentToolboxMarkersState,
    getStickyNotesMarkersState,
    getStickyNotesClickedState,
    getScreenShotButtonClickST,
    getScreenShotIntermediateDataST,
    getScreenShotSendDataST,
    getSelectedLessonId,
    getDrawerToolboxClickedState,
    getSubjectsSettingsData,
    getTimeLineCursorStatusST,
    getTimeLineEventDataST,
    getLineDrawToolboxState,
    getMapboxDrawToolboxGeoJSONState,
    getTimeLineExpendData,
    getTimeLineHeightST,
    getTimeLineItemsData,
    getTimeLineMoveCountST,
    getTimeLineMovePositionST,
    getTimeLineMoveStatusST,
    getTimeLineMovieDataST,
    getTimeLineZoomIndexST,
    getTimeLineZoomST,
    getTimeTineZoomLevelST,
    getTopicNavigationData,
    getTopicsData,
    getTopicStaticTitleST,
    getBaseMapGroupIdST,
    getBaseMapIsLight,
    getTextToolboxState,
    getTextToolboxMarkersState,
    getIsScreenShotMode,
    getGalleryIndex,
    getScreenShotGalleryIndex,
    getActiveScreenShot, getArticleId, getCurrentTiliIndex, getScreenShotFullScreen, getIsFormPopupVisible,
} from "../../../../store/selectors";
import "./style.css";
import {setLampModalState} from "../../../../store/actions/lamp";
import ScreenShotNotification from "../ScreenShotNotification";
import ScreenShotNotificationPopup from "../ScreenShotNotificationPopup";
import AttachIcon from "../../../../assets/imgs/icons/attachIcon.svg";
import {getPresentationAPI} from "../../../../store/actions/presentationMode";
import {createSlideAPI, editSlideAPI} from "../../../../store/actions/slides";
import html2canvas from 'html2canvas';
import {presentationPostMethod} from "../../../../service/presentationMode";
import {handleCaptureTypeBlob, uploadImage} from "../../../../utils/helpers";
import SaveSlideIcon from "../../../../assets/imgs/icons/saveSlide.svg";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import ArticlePresentationScreenshot from "../../ArticlePresentationScreenshot";

import {useShapes} from "../../../toolbox/ShapeToolbox";
import MapScreenShotPopup from "../../MapScreenShotPopup";
import MapScreenShotPopupView from "../../MapScreenShotPopupView";
import {Modal} from "antd";
import {center} from "@turf/turf";
import {selectPresentationsIsShown, selectScreenshotPresentation} from "../../../presentation/store/presentationSlice";

const MapScreenShotButton = (props) => {
    const {t} = useTranslation()
    const {
        createScreenShotAPI,
        menuExpend,
        getOpenCategoryID,
        mapRequirement,
        mapRequirements,
        hints,
        quizPopup,
        getCategoryArticleClickedId,
        filteredCategories,
        getLampData,
        getLampSortedTooltipState,
        getLampSelectedId,
        getMapStyledDarkId,
        painterStart,
        getDrawerToolboxClickedState,
        getPainterColor,
        getPainterColorData,
        getPainterSize,
        getPainterSizeData,
        getColorPickerShowState,
        getRulerClickedState,
        getPainterGeoJsonDataST,
        getRulerGeoJsonDataST,
        getCommentToolboxState,
        getStickyNotesClickedState,
        getCommentToolboxMarkersState,
        getStickyNotesMarkersState,
        getTextToolboxState,
        getTextToolboxMarkersState,
        getNewAngle,
        getNewRotate,
        getCompassCoordinates,
        getRulerPopupShowST,
        getTimeLineZoomST,
        getTimeLineZoomIndexST,
        getTimeLineMoveCountST,
        getTimeLineCursorStatusST,
        getTimeLineEventDataST,
        getTimeLineMovePositionST,
        getTimeLineMoveStatusST,
        getTimeTineZoomLevelST,
        timelineExtend,
        getLineDrawToolboxState,
        getMapboxDrawToolboxGeoJSONState,
        getMapCenterCoordinatesST,
        getLineDrawToolboxLinesState,
        setScreenShotSendDataST,
        setScreenShotIntermediateDataST,
        getScreenShotButtonClickST,
        setScreenShotButtonClickST,
        setScreenShotButtonStateST,
        disable = "none",
        selectedLesson,
        articleData,
        menu,
        article = props.activeScreenShot === "article-gallery",
        isReadMoreArticle = props.activeScreenShot === 'article-read-more' || props.isFromReadMore,
        isGalleryMode = props.activeScreenShot === "article-gallery" && props.currentTileIndex !== null,
        articlePosition = null,
        // closedBlocks = null,
        getTimeLineMovieDataST,
        getScreenShotSendDataST,
        editScreenDataAPI,
        getTopicStaticTitleST,
        getTimeLineHeightST,
        getLampModalState,
        getLampModalStateIndex,
        filteredLegends,
        map,
        getSubjectsSettingsData,
        createSlideAPI,
        editSlideAPI,
        globalLoading,
        getMapMarkerInfo,
        topicNavigation,
        baseMapGroupIdST,
        baseMapIsLight,
        lessonNavigationData,
        white = false,
        galleryIndex = 0,
        galleryTiliIndex = props.currentTileIndex || props.galleryIndex || 0,
        galleryFullScreen =  props.screenShotFullScreen,
        articleId = props.screenShotArticleId,
        mapStateSingleData,
        isMapHeader,
        setIsScreenShotMode,
        isScreenShotMode,
        activeScreenShot,
        getIsMicroArticleFormPopupVisible,
        getScreenShotIntermediateDataST
    } = props;
    const overlay = useRef();
    const contentRef = useRef();
    const navigate = useNavigate();
    const params = useLocation();
    const {shapes, menuIsOpen, activeShape, shapeModeIsActive} = useShapes();

    console.log("props.activeScreenShot XX", props);
    console.log("isGalleryMode XX-----", isGalleryMode);

    const [screenshotUrl, setScreenshotUrl] = useState(null);
    let [searchParams] = useSearchParams();

    let topicID = searchParams.get("topicID");
    let isScreenShot = searchParams.get("screenShot");
    let isPresentation = searchParams.get("presentation");
    let screenSlideID = searchParams.get("screenSlide");
    const presentationsIsShown = useSelector(selectPresentationsIsShown)

    const screenshotPresentation = useSelector(selectScreenshotPresentation)
    const [isOpen, setIsOpen] = useState(!!screenshotPresentation);
    const [isViewOpen, setIsViewOpen] = useState(isScreenShot && isScreenShot !== 'true' && isMapHeader);
    const [isActive, setIsActive] = useState(false);
    const [isChangedData, setIsChangedData] = useState(true);
    const url = `${params?.pathname}${params?.search}`;

    useEffect(() => {
        setIsOpen(!!screenshotPresentation)
        setIsScreenShotMode(!!screenshotPresentation)
        if (screenshotPresentation) {
           onScreenShot();
            setIsViewOpen(false)
            searchParams.delete("screenShot")
            navigate({
                pathname: window.location.pathname,
                search: searchParams.toString(),
            }, {replace: true});
            // setIsActive(true)

       }
    },[screenshotPresentation])

    useEffect(() => {
        setIsViewOpen(isScreenShot && isScreenShot !== 'true' && isMapHeader)
        if (screenSlideID) {
            setIsOpen(false)
        }
    },[screenSlideID, isScreenShot, isMapHeader])

    const handleReturnStatesData = () => {
        const lng = i18n.language === 'en' ? 2 : 1
        return {
            url: url,
            // getLampData: getLampData,
            baseMapGroupIdST: baseMapGroupIdST,
            baseMapIsLight: baseMapIsLight,
            topicTitle: topicNavigation.current?.title,
            topicStaticTitle: topicNavigation.current?.title,
            lessonTitle: lessonNavigationData.current?.title,
            menuExpend: menuExpend,
            categoryOpenID: getOpenCategoryID,
            mapRequirement: mapRequirement,
            mapRequirements: mapRequirements,
            hints: hints,
            quizPopup: quizPopup,
            getCategoryArticleClickedId: getCategoryArticleClickedId,
            filteredCategoriesProxyData: typeof filteredCategories?.getDataStates === 'function' && filteredCategories?.getDataStates(),
            filteredLegendsProxyData: typeof filteredLegends?.getDataStates === 'function' && filteredLegends?.getDataStates(),
            lampDataFiltered: lampDataFiltered(),
            getLampSortedTooltipState: getLampSortedTooltipState,
            getLampSelectedId: getLampSelectedId,
            getMapStyledDarkId: getMapStyledDarkId,
            painterStart: painterStart,
            getRulerClickedState: getRulerClickedState,
            getPainterGeoJsonDataST:
                Object.keys(getPainterGeoJsonDataST).length > 0
                    ? getPainterGeoJsonDataST
                    : {
                        type: "FeatureCollection",
                        features: [],
                    },
            getRulerGeoJsonDataST: getRulerGeoJsonDataST,
            getCommentToolboxState: getCommentToolboxState,
            getCommentToolboxMarkersState: getCommentToolboxMarkersState,
            getStickyNotesClickedState: getStickyNotesClickedState,
            getStickyNotesMarkersState: getStickyNotesMarkersState,
            getTextToolboxState: getTextToolboxState,
            getTextToolboxMarkersState: getTextToolboxMarkersState,
            getNewAngle: getNewAngle,
            getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState,
            getNewRotate: getNewRotate,
            getLineDrawToolboxState: getLineDrawToolboxState,
            getLineDrawToolboxLinesState: getLineDrawToolboxLinesState,
            getCompassCoordinates: getCompassCoordinates,
            getRulerPopupShowST: getRulerPopupShowST,
            getTimeLineZoomST: getTimeLineZoomST,
            getTimeLineZoomIndexST: getTimeLineZoomIndexST,
            getTimeLineMoveCountST: getTimeLineMoveCountST,
            getTimeLineCursorStatusST: getTimeLineCursorStatusST,
            getTimeLineEventDataST: getTimeLineEventDataST,
            getTimeLineMovePositionST: getTimeLineMovePositionST,
            getTimeLineMoveStatusST: getTimeLineMoveStatusST,
            getTimeTineZoomLevelST: getTimeTineZoomLevelST,
            timelineExtend: timelineExtend,
            getMapCenterCoordinatesST: getBoundsMap(),
            selectedLesson: selectedLesson,
            // articleData: articleData,
            selectedArticleId: articleData?.[0]?.id || null,
            markerInfo: getMapMarkerInfo,
            menu: menu,
            isReadMore: isReadMoreArticle,
            // articlePosition,
            // closedBlocks,
            isGalleryMode,
            galleryIndex,
            galleryTiliIndex,
            galleryFullScreen,
            articleId,
            getTimeLineMovieDataST: getTimeLineMovieDataST,
            getTimeLineHeightST,
            getLampModalState, getLampModalStateIndex,
            updateSeenDate: getSubjectsSettingsData?.modifiedAt,
            languageId: lng,
            shapeToolState: {
                shapes,
                // menuIsOpen,
                // activeShape,
                // shapeModeIsActive
            }
        }
    }

    const getBoundsMap = () => {
        if (!map) return;

        return {
            zoom: map.getZoom(),
            center: map.getCenter(),
            pitch: map.getPitch(),
            bearing: map.getBearing()
        };
    }

    function lampDataFiltered() {
        return getLampData
            .filter(el => el.isHide === true)
            .map((item) => {
                return {
                    id: item.id,
                    isHide: item.isHide,
                };
            });
    }

    const onDocumentBodyClick = (e) => {
        if (
            e.target.id === "screenShot_icon" ||
            e.target.id === "save_us_screenShot" ||
            e.target.id === "new_screenShot"
        )
            return;
        let data = handleReturnStatesData();
        setScreenShotIntermediateDataST(stringify(data));
        setIsChangedData(true);
        setScreenShotButtonStateST(true);
    };
    const handleCreateSlide = (data) => {
        return createSlideAPI(
            topicNavigation.current?.title ||
            lessonNavigationData.current?.title,
            data,
            null,
            {
                topicId: searchParams.get(`topicID`),
                lessonId: searchParams.get(`lessonID`),
                languageId: data.languageId,
            }
        )
    }
    const handleEditSlide = (data) => {
        const body = {
            state: stringify(data),
            imageUrl: data.imageUrl,
            mediaId: data.mediaId
        }
        return editSlideAPI(screenSlideID, body)
    }
    const handleCaptureClick = async (blob, data, type) => {
        return await uploadImage(blob)
            .then(resp => {
                isScreenShot && setScreenShotState(!screenShotState);
                data.imageUrl = resp?.mediaInfo?.optimized
                data.mediaId = resp?.id
            })
            .then(() => {
                if (type === 'edit') return handleEditSlide(data)
                handleCreateSlide(data)
            });
    };

    const handleOverlayBody = (type) => {
        let data = handleReturnStatesData();
        if (getScreenShotSendDataST) data.id = parse(getScreenShotSendDataST).id
        setScreenShotIntermediateDataST(stringify(data));
        setScreenShotSendDataST(stringify(data));

        setTimeout(() => {
            setScreenShotButtonClickST(!getScreenShotButtonClickST);
        }, 1000);
        if (type === 'presentation') {
            const elementId =
                isReadMoreArticle && !galleryIndex && !isGalleryMode ? 'article-read-more' :
                    ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                        ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                            'map';

            handleCaptureTypeBlob((blob) => handleCaptureClick(blob, data), elementId, isGalleryMode)
        } else {
            createScreenShotAPI(
                data.languageId,
                topicNavigation.current?.title ||
                lessonNavigationData.current?.title,
                data,
                searchParams.get(`topicID`),
                searchParams.get(`lessonID`),
            );
        }
        overlay.current.style = "display:block";
        setTimeout(() => {
            overlay.current.style = "display:none";
        }, 500);

        setScreenShotState(false);
    };

    const [screenShotState, setScreenShotState] = useState(false);

    const onScreenShotEdit = (type) => {
        let data = handleReturnStatesData();
        data.id = parse(getScreenShotSendDataST).id
        if (type === 'presentation-edit') {
            const elementId = isReadMoreArticle && !galleryIndex && !isGalleryMode ? 'article-read-more' :
                ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                    ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                        'map';
            handleCaptureTypeBlob((blob) => handleCaptureClick(blob, data, 'edit'), elementId, galleryIndex)
        } else {
            editScreenDataAPI(
                data.languageId,
                parse(getScreenShotSendDataST).id,
                getTopicStaticTitleST,
                data,
                null,
                searchParams.get(`topicID`),
                searchParams.get(`lessonID`),
            );
            setTimeout(() => {
                setScreenShotButtonClickST(!getScreenShotButtonClickST);
            }, 1000);
        }
        setScreenShotIntermediateDataST(stringify(data));
        setScreenShotSendDataST(stringify(data));


        overlay.current.style = "display:block";
        setTimeout(() => {
            overlay.current.style = "display:none";
        }, 500);
        setScreenShotState(false);

    };


    useEffect(() => {
        document.body.addEventListener("mouseup", onDocumentBodyClick);

        document
            .getElementsByClassName("timeline__content")[0]
            .addEventListener("click", onDocumentBodyClick);

        return () => {
            document.body.removeEventListener("mouseup", onDocumentBodyClick);
            const timeLineContent = document
                .getElementsByClassName("timeline__content")[0];
            if (timeLineContent) {
                timeLineContent.removeEventListener("click", onDocumentBodyClick);
            }
        };
    }, [props, isChangedData]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isChangedData && isScreenShot) {
                event.preventDefault();
                event.returnValue = "";
            }
        };

        // window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener('beforeunload', function (event) {
            event.stopImmediatePropagation();
        });
        return () => {
            // window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isChangedData, isScreenShot]);

    const onDeleteParams = () => {
        const url = new URL(window.location.href);

        url.searchParams.delete("screenShot");
        url.searchParams.delete("userId");

        navigate(`${url.pathname}${url.search}`, {replace: true});
    }


    const setScreenShot = () => {
        console.log("isReadMoreArticle", isReadMoreArticle, "galleryIndex", galleryIndex, "isGalleryMode", isGalleryMode)

      setTimeout(() => {
          handleCaptureTypeBlob(
              (blob) => {
                  const url = URL.createObjectURL(blob);
                  setScreenshotUrl(url);
              },
              isReadMoreArticle && !(galleryIndex >= 0) && !isGalleryMode ? 'article-read-more' :
                  ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                      ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                          activeScreenShot,
              galleryIndex
          )
      },300)
    }


    const handleMouseDown = () => {
        if (!isScreenShot && !isOpen) return;
        setIsActive(true);
    };

    const handleMouseUp = () => {
        if (!isScreenShot && !isOpen) return;
        setIsActive(false);
    };

    const onScreenShot = () => {
        overlay.current.style = "display:block";
        setTimeout(() => {
            overlay.current.style = "display:none";
            setScreenShot()
        }, 500);
    }

    return (
        <div
            style={{
                position: "relative",
                display: 'flex',
                height: 'auto',
            }}
        >
            {!article && <ScreenShotNotification/>}
            <MapScreenShotPopupView
                data={handleReturnStatesData}
                mapStateSingleData={mapStateSingleData}
                open={isViewOpen}
                onOpenChange={(newOpen) => {
                    setIsOpen(newOpen)
                }}
                onClose={() => {
                    onDeleteParams()
                    setIsViewOpen(false)
                }}
                setIsViewOpen={setIsViewOpen}
                globalLoading={globalLoading}

                onSuccess={() => {
                    setIsChangedData(false)
                }}

                isReadMoreArticle={isReadMoreArticle}
                galleryIndex={galleryIndex}
                isGalleryMode={isGalleryMode}
                isMapHeader={isMapHeader}
            />
            <MapScreenShotPopup
                onOpenChange={(newOpen) => {
                    if (!isScreenShot && !isOpen && !isScreenShotMode) {
                        setIsOpen(true)
                        setIsScreenShotMode(true)
                        onScreenShot()
                    }
                }}
                onClose={() => {
                    Modal.confirm(
                        {
                            centered: true,
                            title: <h3 className='map-screenshot-popup__confirm-text'
                                       style={{
                                           fontSize: 20,
                                           letterSpacing: 2,
                                       }}>{t("screenShot.delete").toUpperCase()}</h3>,
                            content: (
                                <div style={{marginBottom: '10px'}}>
                                    <p className='map-screenshot-popup__confirm-text'
                                       style={{
                                           fontSize: 16,
                                       }}>{t('screenShot.close_popup_edit_mode_description_2')}</p>
                                </div>
                            ),
                            icon: null,
                            cancelButtonProps: {
                                className: 'map-screenshot-popup__confirm-button',
                                style: {
                                    backgroundColor: '#FFDE5A',
                                },
                                type: 'primary'
                            },
                            okButtonProps: {
                                className: 'map-screenshot-popup__confirm-button',
                                style: {
                                    backgroundColor: '#FF4015',
                                },
                            },
                            cancelText: t('no'),
                            okText: t('yes'),
                            onOk: () => {
                                setIsOpen(false)
                                setIsScreenShotMode(false)
                            },
                            width: 535,
                            wrapClassName: 'map-screenshot-popup_confirm-modal'
                        }
                    )
                }}
                open={isOpen}
                trigger="click"
                imageUrl={screenshotUrl}
                isEditMode={isScreenShot}
                lessonNavigationData={lessonNavigationData}
                topicNavigation={topicNavigation}
                data={handleReturnStatesData}
                onSuccess={() => {
                    setIsOpen(false)
                    setIsChangedData(false)
                    setIsScreenShotMode(false)
                }}
                globalLoading={globalLoading}
                isReadMoreArticle={isReadMoreArticle}
                galleryIndex={galleryIndex}
                isGalleryMode={isGalleryMode}
                isMapHeader={isMapHeader}
            >
                <div>
                    <div
                        id="screenShot_icon"
                        className={`right-side__search-btn main__tab-btn screenshot-btn`}
                        style={{
                            borderRadius: "10px",
                            backgroundColor: white
                                ? "transparent"
                                : (isActive && isMapHeader && presentationsIsShown)
                                    ? "#2C476C"
                                    : "#fff",
                            opacity: (isScreenShotMode || isScreenShot || presentationsIsShown || getIsMicroArticleFormPopupVisible) ? 0.7 : !isPresentation ? 1 : 0.7,
                            cursor: 'pointer',
                            pointerEvents: presentationsIsShown || getIsMicroArticleFormPopupVisible ? 'none' : 'auto',
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                    >
                    <span className="main__tab-icon icon-wrapper screenshot-icon">
                      <img src={white || (isActive && isMapHeader && presentationsIsShown) ? ScreenShotIconWhite : ScreenShotIcon}/>
                    </span>
                    </div>
                </div>
            </MapScreenShotPopup>

            {screenShotState && isScreenShot && (
                <div
                    style={{
                        backgroundColor: "#fff",
                        padding: "20px 15px",
                        borderRadius: "10px",
                        marginTop: "20px",
                        position: "absolute",
                        width: "fit-content",
                        display: "flex",
                        left: isGalleryMode ? '-105%' : galleryFullScreen ? '10%' : '50%',
                        top: isReadMoreArticle || white ? "65%" : "100%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <div
                        style={{
                            left: isGalleryMode ? '70%' : galleryFullScreen ? "52%" : "45.5%",
                            width: (isGalleryMode || galleryFullScreen) && 'calc(15px / 0.7)',
                            height: (isGalleryMode || galleryFullScreen) && 'calc(15px / 0.7)'
                        }}
                        className="tooltip"></div>
                    <button
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid gray",
                            width: "140px",
                            fontSize: "12px",
                            padding: !isReadMoreArticle && "6px",
                            marginRight: "4px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            fontWeight: 500,
                            height: isReadMoreArticle && "20px"
                        }}
                        id="new_screenShot"
                        onClick={() => {
                            handleOverlayBody();
                        }}
                    >
                        {t('createNew')}
                    </button>
                    <button
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid gray",
                            width: "140px",
                            fontSize: "12px",
                            padding: !isReadMoreArticle && "6px",
                            marginLeft: "4px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            fontWeight: 500,
                            height: isReadMoreArticle && "20px"
                        }}
                        id="save_us_screenShot"
                        onClick={() => {
                            onScreenShotEdit();
                        }}
                    >
                        {t('save')}
                    </button>
                </div>
            )}
            <div ref={overlay} className={"body-overlay-shot"}></div>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    getLineDrawToolboxState: getLineDrawToolboxState(state),
    getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState(state),
    topics: getTopicsData(state),
    timeLineItems: getTimeLineItemsData(state),
    menuExpend: getMenuExpendData(state),
    mapStateSingleData: getMapStateSingleData(state),
    getOpenCategoryID: getOpenCategoryID(state),
    mapRequirement: getMapRequirementData(state),
    mapRequirements: getMapRequirementsData(state),
    hints: getHintsData(state),
    quizPopup: getQuizPopupData(state),
    getCategoryArticleClickedId: getCategoryArticleClickedId(state),
    filteredCategories: getFilteredCategoriesData(state),
    getLampData: getLampData(state),
    getLampSortedTooltipState: getLampSortedTooltipState(state),
    getMapBounceData: getMapBounceData(state),
    getLampSelectedId: getLampSelectedId(state),
    getMapStyledDarkId: getMapStyledDarkId(state),
    painterStart: getPainterStartData(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getPainterColor: getPainterColor(state),
    getPainterColorData: getPainterColorData(state),
    getPainterSize: getPainterSize(state),
    getPainterSizeData: getPainterSizeData(state),
    getColorPickerShowState: getColorPickerShowState(state),
    getRulerClickedState: getRulerClickedState(state),
    getEraserClickedState: getEraserClickedState(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
    getCommentToolboxState: getCommentToolboxState(state),
    getStickyNotesClickedState: getStickyNotesClickedState(state),
    getCommentToolboxMarkersState: getCommentToolboxMarkersState(state),
    getStickyNotesMarkersState: getStickyNotesMarkersState(state),
    getTextToolboxState: getTextToolboxState(state),
    getTextToolboxMarkersState: getTextToolboxMarkersState(state),
    getNewAngle: getNewAngle(state),
    getNewRotate: getNewRotate(state),
    getCompassCoordinates: getCompassCoordinates(state),
    getRulerPopupShowST: getRulerPopupShowST(state),
    getTimeLineZoomST: getTimeLineZoomST(state),
    getTimeLineZoomIndexST: getTimeLineZoomIndexST(state),
    getTimeLineMoveCountST: getTimeLineMoveCountST(state),
    getTimeLineCursorStatusST: getTimeLineCursorStatusST(state),
    getTimeLineEventDataST: getTimeLineEventDataST(state),
    getTimeLineMovePositionST: getTimeLineMovePositionST(state),
    getTimeLineMoveStatusST: getTimeLineMoveStatusST(state),
    getTimeTineZoomLevelST: getTimeTineZoomLevelST(state),
    timelineExtend: getTimeLineExpendData(state),
    getMapCenterCoordinatesST: getMapCenterCoordinatesST(state),
    getScreenShotButtonClickST: getScreenShotButtonClickST(state),
    selectedLesson: getSelectedLessonId(state),
    articleData: getArticlesData(state),
    menu: getMenuData(state),
    getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
    getTimeLineMovieDataST: getTimeLineMovieDataST(state),
    getScreenShotSendDataST: getScreenShotSendDataST(state),
    getTopicStaticTitleST: getTopicStaticTitleST(state),
    getTimeLineHeightST: getTimeLineHeightST(state),
    getLampModalState: getLampModalState(state),
    getLampModalStateIndex: getLampModalStateIndex(state),
    filteredLegends: getFilteredLegendsData(state),
    getSubjectsSettingsData: getSubjectsSettingsData(state),
    getMapMarkerInfo: getMapMarkerInfo(state),
    topicNavigation: getTopicNavigationData(state),
    lessonNavigationData: getLessonNavigationData(state),
    baseMapGroupIdST: getBaseMapGroupIdST(state),
    baseMapIsLight: getBaseMapIsLight(state),
    isScreenShotMode: getIsScreenShotMode(state),
    galleryIndex: getScreenShotGalleryIndex(state),
    activeScreenShot: getActiveScreenShot(state),
    screenShotArticleId: getArticleId(state),
    currentTileIndex: getCurrentTiliIndex(state),
    screenShotFullScreen: getScreenShotFullScreen(state),
    articleId: getArticleId(state),
    getIsMicroArticleFormPopupVisible: getIsFormPopupVisible(state),
});

const mapDispatchToProps = {
    createScreenShotAPI,
    setScreenShotSendDataST,
    setScreenShotIntermediateDataST,
    setScreenShotButtonClickST,
    setScreenShotButtonStateST,
    editScreenDataAPI,
    createSlideAPI,
    editSlideAPI,
    getPresentationAPI,
    setIsScreenShotMode
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(MapScreenShotButton);
