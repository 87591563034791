// IconSelectPopup.js

import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styles from "../PopupForm.module.css";
import { Button, Col, Input, List, message, Row, Space, Typography } from "antd";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import InfiniteIconsGrid from "../InfiniteIconsGrid";
import CustomColorPicker from "../../CustomColorPicker";
import {
    getMicroArticleData, getMicroArticleIconsKeys,
    getMicroArticleIconsSpriteCategories, getMicroArticleSelectedCategoryId, getMicroArticleSpriteIconsSearch,
    getSprite, getSpriteBase64Data,
    getSpriteData, getSpriteURLData
} from "../../../../../store/selectors";
import {
    setMicroArticleData,
    setMicroArticleIconsKeys,
    setMicroArticleIconsSpriteCategories, setMicroArticleSelectedCategoryId, setMicroArticleSpriteIconsSearch
} from "../../../../../store/actions/microArticle";
import {connect} from "react-redux";
import {INITIAL_ICON_OBJECT} from "../../../utils";
import SearchIcon from "../../../../../assets/imgs/search-icon.svg";
import {useTranslation} from "react-i18next";

const IconSelectPopup = ({
                             getSpriteData,
                             handleIconSelect,
                             handleColorChangeLocal,
                             recentColors,
                             selectedColor,
                             getMicroArticleIconsSpriteCategories,
                             getMicroArticleIconsKeys,
                             getMicroArticleSelectedCategoryId,
                             setMicroArticleSelectedCategoryId,
                             getMicroArticleSpriteIconsSearch,
                             setMicroArticleSpriteIconsSearch,
                             selectedIcon,
                         }) => {
    const colors = useMemo(() => ['#157EFA', '#53D669', '#FED031'], []);
    const [selectedIconObject, setSelectedIconObject] = useState(null);
    const {t} = useTranslation();

    const [searchText, setSearchText] = useState(getMicroArticleSpriteIconsSearch);

    useEffect(() => {
        setMicroArticleSelectedCategoryId(null);
        const handler = setTimeout(() => {
            setMicroArticleSpriteIconsSearch(searchText);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText, setMicroArticleSpriteIconsSearch]);

    // Retrieve all icon names from sprite data
    const handleChangeIcon = useCallback((iconKey, icon) => {
        console.log('icon', icon);
        handleIconSelect(iconKey);
    }, [handleIconSelect]);

    useEffect(() => {
        if (selectedIcon) {
            if(getMicroArticleIconsKeys) {
                getMicroArticleIconsKeys.forEach((icon) => {
                    if(icon.lightKey === selectedIcon || icon.darkKey === selectedIcon) {
                        setSelectedIconObject(icon);
                    }
                })
            }
        } else {
            setSelectedIconObject(INITIAL_ICON_OBJECT)
        }
    }, [selectedIcon, getMicroArticleIconsKeys]);

    return (
      <div className={styles.environmentPopup}>
        <Row style={{ maxHeight: 'inherit', width: '100%' }}>
          {/* Left Column: Categories */}
          <Col span={8} className={`${styles.popupLeft} custom-scroll-v3`}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}>
              {/* Sticky Title */}
              <div
                style={{
                  position: 'sticky',
                  top: 0,
                  background: '#E9ECF0',
                  zIndex: 10,
                }}>
                <Typography.Paragraph
                  style={{
                    margin: 10,
                    padding: 5,
                  }}>
                    {t("microArticle.mapView.createPopupTexts.iconPopup.columnName")}
                </Typography.Paragraph>
              </div>

              {/* Scrollable List */}
              <div
                className={'custom-scroll-v3'}
                style={{ flex: 1, overflowY: 'auto' }}>
                <List
                  className={styles.categoryList}
                  dataSource={
                    getMicroArticleIconsSpriteCategories
                      ? [
                          { name: t("microArticle.mapView.createPopupTexts.iconPopup.all"), id: null },
                          ...getMicroArticleIconsSpriteCategories,
                        ]
                      : [{ name: t("microArticle.mapView.createPopupTexts.iconPopup.all"), id: null }]
                  }
                  renderItem={(category) => (
                    <List.Item
                      className={styles.categoryItem}
                      onClick={() => {
                        setMicroArticleSelectedCategoryId(category.id)
                      }}>
                        <span
                            style={{
                                fontWeight: category.id === getMicroArticleSelectedCategoryId && 'bold',
                            }}
                        >
                            {category.name} {/* Render the category name */}
                        </span>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Col>
          {/* Right Column: Search, Icons, Colors, Color Picker */}
          <Col span={16} className={styles.popupRight}>
            {/* Search Section */}
            <div className={styles.topSection}>
              <Input
                suffix={
                  <img
                    className={styles.customIcon}
                    src={SearchIcon}
                    alt={'Search Icon'}
                  />
                }
                placeholder={t("microArticle.mapView.createPopupTexts.iconPopup.searchPlaceholder")}
                className={styles.searchInput}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {/* Icons Grid Section */}
            <InfiniteIconsGrid
              selectedIcon={selectedIconObject}
              selectedColor={selectedColor}
              icons={getMicroArticleIconsKeys}
              onIconSelect={handleChangeIcon}
            />
            {/* Colors and Color Picker Section */}
            <div className={styles.bottomSection}>
              <Space className={styles.bottomSectionSpace}>
                {/* Existing color buttons */}
                <Button
                  key={`color-${selectedColor}`}
                  size="small"
                  shape="circle"
                  className={styles.colorButton_selected}
                  style={{ borderColor: selectedColor, color: selectedColor }}
                  onClick={() => {
                    // handleColorChangeLocal(selectedColor);
                  }}>
                  <span className={styles.colorButtonInner}></span>
                </Button>
                {colors.map((color, index) => (
                  <Button
                    key={`color-${index}`}
                    size="small"
                    shape="circle"
                    className={styles.colorButton}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      handleColorChangeLocal(color)
                    }}
                  />
                ))}
                {/* Three dynamic buttons: Recent colors or "+" */}
                {[0, 1, 2].map((i) =>
                  recentColors[i] ? (
                    <Button
                      key={`recent-color-${i}`}
                      size="small"
                      shape="circle"
                      className={styles.colorButton}
                      style={{ backgroundColor: recentColors[i] }}
                      onClick={() => {
                        handleColorChangeLocal(recentColors[i])
                      }}
                    />
                  ) : (
                    <div
                      key={`add-color-${i}`}
                      className={`${styles.colorButton} ${styles.recentColorButton}`}
                      onClick={() => {
                        message.info(t('microArticle.messages.info.selectColor'))
                      }}>
                      <PlusCircleFilled
                        style={{
                          color: '#D8D8D8',
                          backgroundColor: '#fff',
                          fontSize: '32px',
                        }}
                      />
                    </div>
                  ),
                )}
                {/*Always Visible Color Picker*/}
                <CustomColorPicker
                  handleIconSelect={handleIconSelect}
                  selectedIconObject={selectedIconObject}
                  initialColor={selectedColor}
                  onColorChange={handleColorChangeLocal}
                />
              </Space>
            </div>
          </Col>
        </Row>
      </div>
    )
};

const mapStateToProps = (state) => ({
    getMicroArticleIconsSpriteCategories: getMicroArticleIconsSpriteCategories(state),
    getMicroArticleIconsKeys: getMicroArticleIconsKeys(state),
    getMicroArticleSelectedCategoryId: getMicroArticleSelectedCategoryId(state),
    getMicroArticleSpriteIconsSearch: getMicroArticleSpriteIconsSearch(state),
})

const mapDispatchToProps = {
    setMicroArticleIconsSpriteCategories,
    setMicroArticleIconsKeys,
    setMicroArticleSelectedCategoryId,
    setMicroArticleSpriteIconsSearch,
}

/**
 * Connect the component to Redux and export.
 */
export default connect(mapStateToProps, mapDispatchToProps)(memo(IconSelectPopup))
