// MicroArticleBoardCard.jsx
import React, {useCallback, useEffect, useState} from 'react'
import {Card, Typography, Row, Col, Divider, Tooltip, message} from 'antd'
import PlaceholderImage from '../../../assets/imgs/image_placeholder_2.png'
import styles from './MicroArticleBoardCard.module.css'
import EditModal from './EditModal'
import DeleteModal from './DeleteModal'
import DotsIcon from '../../../assets/imgs/DotsIcons.svg'
import {MicroArticleService} from "../../microArticle/service";
import ConnectedSpriteSvgComponent from "../ConnectedSpriteSvgComponent";
import {getSpriteBase64Data, getSpriteData} from "../../../store/selectors";
import {connect, Provider} from "react-redux";
import {store} from "../../../store";
import {useTranslation} from "react-i18next";
import ConnectedSpriteImageComponent from "../ConnectedSpriteImageComponent";
import {INITIAL_ICON_OBJECT, isIconKeyValid} from "../../microArticle/utils";

const { Title, Text } = Typography

const MicroArticleBoardCard = ({ microArticleItemData, handleGetData, spriteJson, getSpriteBase64Data }) => {
  const {t} = useTranslation();
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false);
  // Utility function to format Unix timestamp to readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    const date = new Date(timestamp * 1000);  // from seconds to milliseconds

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  // Handle Edit Modal submission
    const handleEdit = (values) => {
        try {
            MicroArticleService.getMicroArticleByID(microArticleItemData.id).then(
                (res) => {
                    const newMicroArticleInfo = {
                        ...res.data.data[0],
                        title: values.title,
                        description: values.content,
                    }
                    MicroArticleService.updateMicroArticleByID(
                        microArticleItemData.id,
                        newMicroArticleInfo,
                    )
                        .then((res) => {
                            handleGetData()
                            message.success(t('microArticle.messages.success.updated'))
                        })
                        .catch((e) => {
                            message.error(t('microArticle.messages.error.updateFailed'))
                        })
                },
            ).catch(e => {
                message.error(t('microArticle.messages.error.updateFailed'))
            })
        } catch (e) {
            message.error(t('microArticle.messages.error.updateFailed'))
        }
        setEditModalVisible(false)
    }

  // Handle Delete Modal confirmation
  const handleDelete = useCallback(async () => {
      if(!microArticleItemData || !microArticleItemData.id) return;
      try {
        await MicroArticleService.deleteMicroArticleByID(microArticleItemData.id);
        message.success(t('microArticle.messages.success.deleted'))
        handleGetData()
        setDeleteModalVisible(false)
      } catch (e) {
        message.error(t('microArticle.messages.error.deleteFailed'))
        setDeleteModalVisible(false)
      }
  }, [microArticleItemData, handleGetData]);

    const handleHeaderClick = () => {
        const type = microArticleItemData.lessonIds.length ? 'lesson' : 'topic';

        let typeID = type === 'lesson' ? microArticleItemData.lessonIds[0] : microArticleItemData.topicIds[0];
        const microarticleId = microArticleItemData.id;

        // Construct the URL
        const baseUrl = window.location.origin; // e.g., https://yourdomain.com
        const url = `${baseUrl}/map?type=${type}&${type}ID=${encodeURIComponent(typeID)}&microarticleID=${microarticleId}`;

        // Open the URL in a new tab
        window.open(url, '_blank');
    };


  return (
    <Card
      className={styles.card}
      styles={{
          body: {
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
      }}}>
      {/* Header Section */}
        <Row
            justify="space-between"
            align="middle"
            className={styles.header}
            style={{ flexWrap: 'nowrap', cursor: 'pointer' }} // Add cursor pointer for better UX
            onClick={handleHeaderClick} // Attach the click handler
        >
            <Col style={{width: "100%", flexWrap: 'none', display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: 'none', gap: 8, flex: 1, width: "100%" }} >
                    <div
                        className={styles.iconWrapper}
                        style={{
                            backgroundColor: microArticleItemData.iconBackgroundColor || '#000',
                        }}
                    >
                        <ConnectedSpriteImageComponent scale={0.7} name={isIconKeyValid(microArticleItemData.iconKey) ? microArticleItemData.iconKey : INITIAL_ICON_OBJECT.darkKey } />
                    </div>
                    <Title level={5} className={styles.title}>
                        {
                            microArticleItemData.title.length > 15 ?
                                microArticleItemData.title.substring(0, 15) + '...'
                                : microArticleItemData.title
                        }
                    </Title>
                </div>
                <Tooltip
                    trigger={['click']}
                    // mouseLeaveDelay={0.1}
                    destroyTooltipOnHide={true}
                    placement="bottomRight"
                    arrow={false}
                    overlayInnerStyle={{
                        backgroundColor: '#2c476c',
                        color: '#fff',
                    }}
                    open={tooltipVisible} // Control visibility
                    onOpenChange={visible => setTooltipVisible(visible)}
                    title={
                        <div className={styles.tooltip}>
                            <div
                                className={styles.tooltipItem}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering header click
                                    setEditModalVisible(true);
                                    setTooltipVisible(false); // Close tooltip
                                }}
                            >
                                {t("microArticle.userDashboard.microArticlesCardText.menu.edit")}
                            </div>
                            <Divider className={styles.tooltipDivider} />
                            <div
                                className={styles.tooltipItem}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering header click
                                    setDeleteModalVisible(true);
                                    setTooltipVisible(false); // Close tooltip
                                }}
                            >
                                {t("microArticle.userDashboard.microArticlesCardText.menu.delete")}
                            </div>
                        </div>
                    }
                >
                    <img
                        onClick={e => e.stopPropagation()}
                        src={DotsIcon} alt={'Action Button'}
                        className={styles.ellipsisIcon}
                    />
                </Tooltip>
            </Col>
        </Row>

      {/* Divider */}
      <Divider className={styles.divider} />

      {/* Content Section */}
      <div className={styles.content}>
        {/* Image Section */}
        <div className={styles.imageWrapper}>
          <img
            src={microArticleItemData.imageUrl || PlaceholderImage}
            alt="Thumbnail"
            className={styles.image}
          />
        </div>

        {/* Text Section */}
        <div className={styles.textSection}>
          <p className={styles.text}>
            {microArticleItemData.description}
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <Row justify="space-between" className={styles.footer}>
        <Col>
          <Text className={styles.footerText}>
            <span className={styles.dateText}>{t('createdAt')}</span>
            <span className={styles.dateNums}>
              {formatDate(microArticleItemData.createdAt)}
            </span>
          </Text>
        </Col>
        <Col>
          <Text className={styles.footerText}>
            <span className={styles.dateText}>{t('modifiedAt')}</span>
            <span className={styles.dateNums}>
              {formatDate(microArticleItemData.modifiedAt)}
            </span>
          </Text>
        </Col>
      </Row>

      {/* Edit Modal */}
      <EditModal
        visible={editModalVisible}
        onOk={handleEdit}
        onCancel={() => setEditModalVisible(false)}
        initialValues={{
          title: microArticleItemData.title || '',
          content: microArticleItemData.description || '',
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
      />
    </Card>
  )
}

const mapStateToProps = (state) => ({
    spriteJson: getSpriteData(state),
    getSpriteBase64Data: getSpriteBase64Data(state), // Ensure this returns a Promise for the Base64 data
})

const MicroArticleBoardCardComponent = (props) => {
    return (
        <Provider store={store}>
            <MicroArticleBoardCard {...props} />
        </Provider>
    )
}

export default connect(mapStateToProps)(MicroArticleBoardCardComponent)
