import React, {useState} from 'react'
import PlayIcon from './icons/PlayIcon'
import DotsIcon from './icons/DotsIcon'
import {Button, Dropdown, Modal, Spin} from 'antd'
import TextIcon from './icons/TextIcon'
import PenIcon from './icons/PenIcon'
import CopyIcon from './icons/CopyIcon'
import LinkIcon from './icons/LinkIcon'
import TrashIcon from './icons/TrashIcon'
import PresentationFormWrapper from './form/PresentationFormWrapper'
import {cn} from '../../../utils/utils'
import {useDispatch} from 'react-redux'
import {setCurrentSlide, setPlaybackPresentation, setSelectedPresentation,} from '../store/presentationSlice'
import PresentationIntro from './PresentationIntro'
import {
  useClonePresentationMutation,
  useLazyGetPresentationSlidesQuery, useRemoveMediaByPathMutation,
  useRemovePresentationMutation,
} from '../store/presentationApi'
import {XIcon} from 'lucide-react'
import {generateSiteUrl} from '../lib/utils'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ReactQuill from "react-quill";
import {setSelectedSlideData} from "../../../store/actions/slides";

const PresentationListItem = ({
  name,
  onClick,
  onStart,
  firstSlide,
  id,
  presentation,
  hasSlides,
  description,
  viewMode,
    isEditorial
}) => {
  const { t } = useTranslation()
  const [editIsOpen, setIsEditMode] = useState(false)
  const [isIntroOpen, setIsIntroOpen] = useState(false)
  const [patumIsOpen, setPatumIsOpen] = useState(false)
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userInfo'))?.data)

  const dispatch = useDispatch()

  const [search] = useSearchParams()
  const navigate = useNavigate()

  const [onClone, { isLoading: isCloning }] = useClonePresentationMutation()
  const [onRemove] = useRemovePresentationMutation()
  const [deleteMediaByPath, { isLoading: isDeleting }] = useRemoveMediaByPathMutation()

  const [getSlides] = useLazyGetPresentationSlidesQuery()

  const clickDropdownHandler = (e) => {
    e.domEvent.stopPropagation()
    const queryParams = new URLSearchParams(window.location.search)

    switch (e.key) {
      case 'text':
        setPatumIsOpen(true)
        break
      case 'edit':
        setIsEditMode(true)
        break
      case 'copy_url':
        getSlides({
          page: 1,
          perPage: 1,
          columnFiltersMany: {
            presentationId: [String(presentation?.id)],
          },
          sort: [
            {
              field: 'order',
              type: 'asc',
            },
          ],
        })
          .unwrap()
          .then((res) => {
            const slide = res?.data?.[0]?.items?.[0]
            const url = generateSiteUrl({
              presentationId: id,
              articleMode: slide?.articleId && false,
              slideId: slide?.id,
              topicId: queryParams.get('topicID'),
              lessonId: queryParams.get('lessonID'),
            })
            navigator.clipboard.writeText(url)
          })

        break
      case 'copy':
        onClone({ id })
        break
      case 'remove':
        // if (presentation?.imageUrl) {
        //   deleteMediaByPath({ path: presentation?.imageUrl })
        // }
        onRemove(id)
          .unwrap()
          .then(() => {
            dispatch(setSelectedPresentation(null))
          })
        break
      default:
    }
  }

  const items = [
    {
      icon: <TextIcon />,
      label: t('presentation_mode.narrative'),
      key: 'text',
      disabled: !description,
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'edit',
      disabled: isEditorial,
    },
    {
      icon: <LinkIcon />,
      label: t('presentation_mode.copy_url'),
      key: 'copy_url',
    },
    {
      icon: isCloning ? <Spin /> : <CopyIcon />,
      label: t('presentation_mode.duplicate'),
      key: 'copy',
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
      disabled: isEditorial,
    },
  ]

  const onPlayPresentation = (e) => {
    e.stopPropagation()
    setIsIntroOpen(true)
  }

  const startHandler = async (e) => {
    e.stopPropagation()
    dispatch(setPlaybackPresentation(presentation))
    dispatch(setCurrentSlide(''))

    if (!firstSlide) {
      try {
        const slidesResponse = await getSlides({
          page: 1,
          perPage: 1,
          columnFiltersMany: {
            presentationId: [String(presentation?.id)],
          },
          sort: [
            {
              field: 'order',
              type: 'asc',
            },
          ],
        }).unwrap()

        firstSlide = slidesResponse.data?.[0].items?.[0]

        console.log(slidesResponse, firstSlide)
      } catch (e) {
        console.log(e)
      }
    }

    search.set('presentation', presentation?.id)

    if (!firstSlide?.articleId) {
      // search.set('screenShot', true)
    } else {
      search.set('articleMode', firstSlide?.articleId)
    }
    search.set('screenShot', firstSlide?.mapStateId || "true")
    search.set('loading', true)
    search.set('playBack', true)

    if (currentUser) {
      search.set('userId', currentUser?.id)
    }

    if (firstSlide) {
      search.set('screenSlide', firstSlide?.id)
      dispatch(setCurrentSlide(firstSlide?.id))
      dispatch(setSelectedSlideData(firstSlide))
    }




    // navigate({
    //     pathname: window.location.pathname,
    //     search: search.toString()
    // }, {replace: true})

    window.location.href =
      window.location.origin +
      window.location.pathname +
      '?' +
      search.toString()

    // navigate({
    //   pathname: window.location.pathname,
    //   search: search.toString(),
    // }, { replace: true })

    onStart?.(presentation)
  }

  const onEditSuccess = () => {
    setIsEditMode(false)
  }

  return (
    <div className="text-[#2C476C]">
      <div
        id={`presentation-${id}`}
        className={cn('presentation-list--item', { active: patumIsOpen })}
        onClick={onClick}>
        <span>{name}</span>
        <div className="presentation-list--item--icons">
          <button
            disabled={!hasSlides}
            className={cn('flex', {
              'opacity-20 pointer-events-none': !hasSlides,
            })}
            onClick={onPlayPresentation}>
            <PlayIcon />
          </button>
          {
            !viewMode &&
              <Dropdown
                  getPopupContainer={() => document.body}
                  overlayClassName="app-dropdown text-[#2C476C]"
                  placement="bottomLeft"
                  menu={{
                    items: items.filter((item) => !item.disabled),
                    onClick: clickDropdownHandler,
                  }}
                  trigger={['click']}>
                <button className="flex" onClick={(e) => e.stopPropagation()}>
                  <DotsIcon />
                </button>
              </Dropdown>
          }
        </div>
      </div>
      {patumIsOpen && (
          <div className="my-1.5 p-2 pr-0 presentation-patum--editor">
            <div className="flex justify-between gap-2 items-center mb-1">
            <span className="mb-1 block text-sm text-[#2C476C]">
              {t('presentation_mode.narrative')}
            </span>
              <Button
                  type="text"
                  size="small"
                  icon={<XIcon size={14} color="#2C476C"/>}
                  onClick={() => setPatumIsOpen(false)}
              />
            </div>
            <div className="max-h-[80px] overflow-auto border-[#e4e4e4] rounded-md p-2 border border-solid">
              <ReactQuill value={description} readOnly={true}
                          className="border-none [&>.ql-toolbar]:hidden readably--quill"
              />
            </div>
          </div>
      )}
      <Modal
          destroyOnClose
          title={name}
          centered
          classNames={{
            body: 'max-h-[80vh] overflow-auto',
            header:
                'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-3',
          }}
          getContainer={() => document.body}
          className="app-modal no-padding max-h-[90vh]"
        width={950}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsEditMode(false)}
        open={editIsOpen}>
        <PresentationFormWrapper id={id} onSuccess={onEditSuccess} />
      </Modal>
      <Modal
        destroyOnClose
        centered
        classNames={{
          body: 'max-h-[82vh] overflow-auto',
          header:
            'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-3',
        }}
        getContainer={() => document.body}
        className="app-modal no-padding max-h-[80vh]"
        width={700}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsIntroOpen(false)}
        open={isIntroOpen}>
        <PresentationIntro id={id} onStart={startHandler} />
      </Modal>
    </div>
  )
}

export default PresentationListItem
