import React, { useEffect, useRef, useState } from 'react';
import { DndContext, useDraggable } from '@dnd-kit/core';

function DraggableItem({ children, initialPosition }) {
  const [position, setPosition] = useState(initialPosition || { x: 0, y: 0 });
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable-item',
  });
  const nodeRef = useRef(null);

  const handleDragEnd = () => {
    setPosition((prevPosition) => {
      const newX = prevPosition.x + (transform?.x || 0);
      const newY = prevPosition.y + (transform?.y || 0);

      const constrainedX = Math.max(
          0,
          Math.min(newX, window.innerWidth - nodeRef.current.clientWidth)
      );
      const constrainedY = Math.max(
          0,
          Math.min(newY, window.innerHeight - nodeRef.current.clientHeight)
      );

      return { x: constrainedX, y: constrainedY };
    });
  };

  useEffect(() => {
    setPosition({
      x: (initialPosition.x - (nodeRef.current?.clientWidth || 0)) / 2,
      y: initialPosition.y - (nodeRef.current?.clientHeight || 0),
    });
  }, [initialPosition]);

  const style = {
    transform: `translate3d(${position.x + (transform?.x || 0)}px, ${
        position.y + (transform?.y || 0)
    }px, 0)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'grab',
    zIndex:5,
    position: 'absolute', // Ensure free movement
  };

  return (
      <div
          ref={(node) => {
            setNodeRef(node);
            nodeRef.current = node;
          }}
          style={style}
          {...listeners}
          {...attributes}
          onPointerUp={handleDragEnd} // Trigger position update when drag ends
      >
        {children}
      </div>
  );
}

export default function Draggable({ children }) {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const footerElement = document.querySelector('.footer');

    if (containerRef.current) {
      const element = containerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === footerElement) {
            setInitialPosition({
              x: viewportWidth, // Center horizontally
              y: viewportHeight - element.height - entry.contentRect.height - 20, // Align near the bottom with a 20px margin
            });
          }
        }
      });

      resizeObserver.observe(footerElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [containerRef]);

  return (
      <DndContext>
        <div ref={containerRef}>
          <DraggableItem initialPosition={initialPosition}>
            {children}
          </DraggableItem>
        </div>
      </DndContext>
  );
}
