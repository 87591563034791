import React from 'react'
import {Button} from 'antd'
import {CloseOutlined} from '@ant-design/icons'

import {cn} from '../../../../utils/utils'
import {useMapStateImportToSlideMutation,} from '../../store/presentationApi'

import i18n from 'i18next'
import PresentationArticlesList from './PresentationArticlesList'
import PresentationMicroArticlesList from './PresentationMicroArticlesList'
import {useSelector} from 'react-redux'
import {stringify} from 'flatted'
import {DEFAULT_MAP_STATE} from '../../lib/constants'
import {generateSiteUrl} from '../../lib/utils'
import {useTranslation} from 'react-i18next'

const PresentationArticleSelector = ({
  onCancel,
  onSuccess,
  presentationId,
}) => {
  const lng = i18n.language === 'en' ? 2 : 1
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = React.useState('system')

  const [selectedArticles, setSelectedArticles] = React.useState([])
  const [selectedMicroArticles, setSelectedMicroArticles] = React.useState([])
  const mapStyledId = useSelector((state) => state?.mapState.mapStylesId)
  const baseMapGroupId = useSelector((state) => state?.mapState.baseMapGroupId)
  const baseMapIsLightSt = useSelector(
    (state) => state?.mapState.baseMapIsLight,
  )

  console.log('mapStyledId XXX', mapStyledId)
  const [onImportToSlide] = useMapStateImportToSlideMutation()

  const onSetSelected = (item) => {
    if (selectedArticles.find((el) => el.id === item.id)) {
      setSelectedArticles(selectedArticles.filter((el) => el.id !== item.id))
    } else {
      setSelectedArticles([...selectedArticles, item])
    }
  }

  const onSetMicroArticleSelected = (item) => {
    console.log('item', item)
    if (selectedMicroArticles.find((el) => el.id === item.id)) {
      setSelectedMicroArticles(
        selectedMicroArticles.filter((el) => el.id !== item.id),
      )
    } else {
      setSelectedMicroArticles([...selectedMicroArticles, item])
    }
  }

  const submitHandler = () => {
    const queryParams = new URLSearchParams(window.location.search)

    const url = generateSiteUrl({
      presentationId,
      articleMode: true,
      microArticleMode: true,
      topicId: queryParams.get('topicID'),
      lessonId: queryParams.get('lessonID'),
    })

    console.log('url', queryParams.get('topicID'), url)

    const state = {
      ...DEFAULT_MAP_STATE,
      url,
      getMapStyledId: mapStyledId,
      baseMapGroupIdST: baseMapGroupId,
      baseMapIsLight: baseMapIsLightSt,
      languageId: lng,
    }

    onImportToSlide({
      presentationId,
      microArticlesData: selectedMicroArticles.map((item) => ({
        id: item.id,
        languageId: item.languageId || lng,
        title: item.title,
        topicId: queryParams.get('topicID'),
        lessonId: queryParams.get('lessonID'),
        state: stringify({
          ...state,
          microArticleData: item,
          url: generateSiteUrl({
            presentationId,
            microArticleMode: item.id,
            topicId: queryParams.get('topicID'),
            lessonId: queryParams.get('lessonID'),
          }),
        }),
      })),
      articlesData: selectedArticles.map((item) => ({
        id: item.id,
        languageId: item.languageId || lng,
        title: item.mainTitle,
        topicId: queryParams.get('topicID'),
        lessonId: queryParams.get('lessonID'),
        state: stringify({ ...state, articleData: item }),
        url: generateSiteUrl({
          presentationId,
          articleMode: item.id,
          topicId: queryParams.get('topicID'),
          lessonId: queryParams.get('lessonID'),
        }),
      })),
    })
      .unwrap()
      .then((res) => {
        onCancel?.()
      })
    // onConfirm(selectedArticles)
  }

  return (
    <div
      className="presentation-article-selector text-[#2C476C]"
      style={{ width: 700, maxWidth: '100%' }}>
      <div className="presentation-article-selector-header bg-[#E6E9ED] -mx-6 -mt-5 rounded-t-lg !pb-0 !justify-start mb-2">
        <div className="flex ">
          <span
            onClick={() => setActiveTab('system')}
            className={cn(
              'transition-all text-[#2C476C] 2xl:px-7 duration-300 rounded-t-lg cursor-pointer flex justify-center items-center h-full py-3 px-4',
              {
                'bg-white': activeTab === 'system',
              },
            )}>
            {t('presentation_mode.library')}
          </span>
          <span
            onClick={() => setActiveTab('micro')}
            className={cn(
              'transition-all text-[#2C476C] 2xl:px-7 duration-300  rounded-t-lg cursor-pointer flex justify-center items-center py-3 px-4',
              {
                'bg-white': activeTab === 'micro',
              },
            )}>
            {t('presentation_mode.my_articles')}
          </span>
        </div>
        <span
          className="close-icon mr-5"
          onClick={onCancel}
          role="presentation">
          <CloseOutlined />
        </span>
      </div>

      <div className={cn({ hidden: activeTab === 'micro' })}>
        <PresentationArticlesList
          selectedArticles={selectedArticles}
          onSelect={onSetSelected}
        />
      </div>

      <div className={cn({ hidden: activeTab === 'system' })}>
        <PresentationMicroArticlesList
          selectedArticles={selectedMicroArticles}
          onSelect={onSetMicroArticleSelected}
        />
      </div>
      <div className="presentation-article-selector--footer flex-col gap-3 -mb-6">
        {!!selectedArticles?.length || !!selectedMicroArticles?.length ? (
          <span className="text-[#2C476C80] uppercase">
            {t('presentation_mode.selected_articles', {
              count: selectedArticles?.length + selectedMicroArticles?.length,
            })}
          </span>
        ) : (
          <span className="text-[#2C476C80] uppercase">
            {t('presentation_mode.select_min_one_article')}
          </span>
        )}

        <Button
          disabled={!selectedArticles?.length && !selectedMicroArticles?.length}
          type="text"
          onClick={submitHandler}
          className="bg-[#FFDE5A] tracking-widest hover:bg-[#FFDE5A] border-none rounded-full uppercase">
          {t('presentation_mode.my_maps_add')}
        </Button>
      </div>
    </div>
  )
}

export default PresentationArticleSelector
