import React, {useEffect, useMemo} from 'react'
import PresentationListItem from '../PresentationListItem'
import PresentationSlideItem from './PresentationSlideItem'

import PlusCircleIcon from '../icons/PlusCircleIcon'
import {Menu, Modal, Popover, Skeleton} from 'antd'
import PinIcon from '../icons/PinIcon'
import ScreenshotIcon from '../icons/ScreenshotIcon'
import SettingsIcon from '../icons/SettingsIcon'
import MapsIcon from '../icons/MapsIcon'
import PresentationArticleSelector from '../articles/PresentationArticleSelector'
import {DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors,} from '@dnd-kit/core'
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,} from '@dnd-kit/sortable'
import {restrictToParentElement} from '@dnd-kit/modifiers'
import {
    useGetPresentationQuery,
    useGetPresentationSlidesQuery,
    useUpdateSlideOrderMutation,
} from '../../store/presentationApi'
import {cn} from '../../../../utils/utils'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
    selectMicroArticlePresentation,
    selectScreenshotPresentation,
    setCurrentSlide,
    setMicroArticlePresentation,
    setScreenshotPresentation
} from '../../store/presentationSlice'
import {setSelectedSlideData} from '../../../../store/actions/slides'
import PresentationScreenshotSelector from '../screenshots/PresentationScreenshotSelector'
import {useTranslation} from 'react-i18next'
import {setMicroArticleBar, setMicroArticleToolClickedState} from "../../../../store/actions/painterStart";
import {closeArticlePopup} from "../../../../store/actions/articles";

const modifiers = [restrictToParentElement]

const PresentationSlidesList = ({ presentationId,viewMode, hidePresentation }) => {
  const { t } = useTranslation()
  const [openArticleSelector, setOpenArticleSelector] = React.useState(false)
  const [openScreenshotSelector, setOpenScreenshotSelector] =
    React.useState(false)
  const [slides, setSlides] = React.useState([])
  const [addMenuIsOpen, setAddMenuIsOpen] = React.useState(false)
  const [search] = useSearchParams()
  const microArticlePresentation = useSelector(selectMicroArticlePresentation)

  const dispatch = useDispatch()

  const { data: presentationData, isFetching } = useGetPresentationQuery(
    { id: presentationId },
    {
      skip: !presentationId,
    },
  )

  const [reOrder, { isLoading }] = useUpdateSlideOrderMutation()

  const { data, isFetching: isFetchingSlides, refetch } = useGetPresentationSlidesQuery(
    {
      page: 1,
      perPage: 1000,
      columnFiltersMany: {
        presentationId: [String(presentationId)],
      },
      sort: [
        {
          field: 'order',
          type: 'asc',
        },
      ],
    },
    { skip: !presentationId, refetchOnMountOrArgChange: true },
  )

  const presentation = presentationData?.data[0]
  const records = data?.data?.[0]?.items || []

  console.log('SLIDES DAATA,data.l', data, presentationId)
  console.log('SINGLE PRESENT', presentationData)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const items = useMemo(
    () => [
      {
        icon: <PinIcon />,
        label: t('presentation_mode.micro_article'),
        key: 'point',
        disabled:microArticlePresentation
      },
      // {
      //     icon: <LineIcon/>,
      //     label: t('presentation_mode.line'),
      //     key: 'line',
      // },
      // {
      //     icon: <PolygonIcon/>,
      //     label: 'Բազմանկյուն',
      //     key: 'polygon',
      // },
      {
        icon: <ScreenshotIcon />,
        label: t('presentation_mode.map'),
        key: 'screenshot',
      },
      {
        type: 'divider',
      },
      {
        icon: <SettingsIcon />,
        label: t('presentation_mode.library'),
        key: 'article',
      },
      {
        icon: <MapsIcon />,
        label: t('presentation_mode.my_maps'),
        key: 'maps',
      },
    ],
    [],
  )

    const stopPinAnimation = () => {
        let mapMarkers = document.getElementsByClassName(
            "mapboxgl-marker-anchor-center"
        );

        for (let i = 0; i < mapMarkers.length; i++) {
            mapMarkers[i].classList.remove("tic_animation");
            mapMarkers[i].style.zIndex = "1";
        }
    };

  const clickPointHandler = () => {
      dispatch(setMicroArticlePresentation({id:presentationId}))

      dispatch(setMicroArticleBar(true))
      dispatch(closeArticlePopup("all"))
      stopPinAnimation()
      setTimeout(() => {
          dispatch(setMicroArticleToolClickedState(true))
      },100)
  };

  const clickAddMenuHandler = (e) => {
    // e.domEvent.stopPropagation()
    switch (e.key) {
      case 'point':
          clickPointHandler();
        break
      case 'line':
        break
      case 'polygon':
        break
      case 'screenshot':
          dispatch(setScreenshotPresentation({id:presentationId}))
        break
      case 'article':
        setOpenArticleSelector(true)
        break
      case 'maps':
        setOpenScreenshotSelector(true)
        break
      default:
        break
    }
  }

  const menuPopover = (
    <div className="-m-[12px] max-h-[300px] overflow-y-auto ">
      <Menu className="p-0 bg-transparent" selectable={false}>
        {items.map((item) => {
          if (item.type === 'divider') {
            return <Menu.Divider className="my-2" />
          }

          return (
            <Menu.Item
              className="p-0 "
              key={item.name}
              disabled={item.disabled}
              onClick={() => {
                clickAddMenuHandler(item)
                setAddMenuIsOpen(false)
              }}>
              <div
                className={cn(
                  'flex items-center text-[#2C476C] -ml-[10px] pr-3',
                  {
                    'opacity-50 pointer-events-none': item.disabled,
                  },
                )}>
                <div className="w-6 h-6 flex items-center justify-center">
                  {item.icon}
                </div>
                <span className="ml-2">{item.label}</span>
              </div>
            </Menu.Item>
          )
        })}
      </Menu>
    </div>
  )

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (!over) return

    if (active.id !== over.id) {
      console.log(active, over)
      setSlides((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)
        reOrder({
          id: active.id,
          order: items[newIndex].order || newIndex,
        })
          .unwrap()
          .then(() => {
            refetch()
            // reOrder({
            //     id: over.id,
            //     order: oldIndex
            // })
          })
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const afterArticlesAddSuccess = () => {
    setOpenArticleSelector(false)
  }

  const startHandler = (id) => {
    dispatch(setCurrentSlide(slides[0]?.id))
    dispatch(setSelectedSlideData(slides[0]))
  }

  useEffect(() => {
    setSlides(data?.data?.[0]?.items || [])
  }, [data])

    useEffect(() => {
        return () => {
            dispatch(setScreenshotPresentation(null))
            dispatch(setMicroArticlePresentation(null))
        }
    },[])

  return (
    <div>
      {!!presentationId && (
        <div className="presentation-slide-content">
          {!hidePresentation && (
            <PresentationListItem
              firstSlide={slides[0]}
              description={presentation?.description || presentation?.shortDescription}
              onStart={startHandler}
              presentation={presentation}
              id={presentation?.id}
              name={presentation?.title}
              hasSlides={slides?.length > 0}
              viewMode={viewMode}
            />
          )}

          <div className="presentation-slide-content--body max-h-[300px] overflow-auto">
            {isFetchingSlides && !slides?.length && <Skeleton active paragraph={{ rows: 4 }} />}
              <DndContext
                  modifiers={modifiers}
                  sensors={sensors}
                  // collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}>
                  <SortableContext
                      items={slides}
                      strategy={verticalListSortingStrategy}>
                      {slides?.map((slide, index) => {
                          if (slide.isHidden) return null;

                          return (
                              <PresentationSlideItem
                                  key={slide.id}
                                  number={index + 1}
                                  description={slide.description || slide.shortDescription}
                                  presentation={presentation}
                                  presentationId={presentation?.id}
                                  articleId={slide?.articleId}
                                  microArticleId={slide?.microArticleId}
                                  image={slide.imageUrl}
                                  id={slide.id.toString()}
                                  name={slide.title}
                                  viewMode={viewMode}
                              />
                          )
                      })}
                  </SortableContext>
              </DndContext>
          </div>

            {
                !viewMode &&
                <div
                    className="presentation-slide--footer !cursor-default"
                    onClick={(e) => e.stopPropagation()}>
                    <Popover
                        content={menuPopover}
                        getPopupContainer={() => document.body}
                        overlayClassName="app-dropdown"
                        placement="bottomLeft"
                        arrow={false}
                        open={addMenuIsOpen}
                        onOpenChange={setAddMenuIsOpen}
                        align={{
                            offset: [33, 20], // Customize your offsets here
                        }}
                        overlayStyle={{
                            minWidth: '200px',
                            inset: '192px auto auto 175px',
                        }}
                        menu={{items, onClick: clickAddMenuHandler, offset: [0, 10]}}
                        trigger={['click']}>
                        <div className="flex items-center gap-3 cursor-pointer text-[#2C476C]">
                            <PlusCircleIcon/>
                            <span>{t('presentation_mode.my_maps_add')}</span>
                        </div>
                    </Popover>
                </div>
            }

            <Modal
                style={{padding: 0}}
                footer={false}
                width={700}
                closeIcon={null}
                destroyOnClose
                onCancel={() => setOpenArticleSelector(false)}
                open={openArticleSelector}>
                <PresentationArticleSelector
                    presentationId={presentationId}
                    onSuccess={afterArticlesAddSuccess}
                    onCancel={() => setOpenArticleSelector(false)}
                />
            </Modal>
            <Modal
                style={{padding: 0}}
                footer={false}
                width={840}
                destroyOnClose
                title={t('presentation_mode.screenshots')}
                classNames={{
                    header:
                        'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
                }}
                onCancel={() => setOpenScreenshotSelector(false)}
                open={openScreenshotSelector}>
            <PresentationScreenshotSelector
              presentationId={presentationId}
              onSuccess={afterArticlesAddSuccess}
              onCancel={() => setOpenScreenshotSelector(false)}
            />
          </Modal>
        </div>
      )}

      {/*<DotsPagination onScrollTo={setIndex}*/}
      {/*                total={10}*/}
      {/*                maxDots={5}*/}
      {/*                passiveDotWidth={10}*/}
      {/*                activeDotWidth={12}*/}
      {/*                passiveDotHeight={10}*/}
      {/*                activeDotHeight={12}*/}
      {/*                passiveColor="#ddd"*/}
      {/*                activeColor="#f06"*/}
      {/*                smallerDotScale={0.8}*/}
      {/*                active={index}/>*/}
    </div>
  )
}

export default PresentationSlidesList
