import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { OutlinedInput, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import "./style.css";
import { editScreenShotCardAPI } from "../../../../store/actions/mapStateAction";
import {useTranslation} from "react-i18next";
import {TextInput} from "../../TextInput";
import Monitor from "../../../../assets/imgs/icons/monitor.svg";

const ScreenShotEdit = (props) => {
  const {t} = useTranslation()
  const { item, onClose, editScreenShotCardAPI } = props;
  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);

  const [editButtonDisable, setEditButtonDisable] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  let pageLoc = +queryParams.get("page");

  const onScreenShotEdit = () => {
    setEditButtonDisable(false);
    editScreenShotCardAPI(
      pageLoc,
      item.userId,
      item.id,
      title,
      item.data,
      onClose,
      description,
      item.mediaId,
      item.imageUrl,
    );
  };

  return (
    <div className="card-body__edit-screenshot-title-form edit-screenshot-title-form">
      <div className="edit-screenshot-title-form__btn-box">
        <form className="map-screenshot-popup__form">
          <div className="map-screenshot-popup__fields">
            <div>
              <label className="map-screenshot-popup__label">
                {t("screenShot.name")}
                <TextInput
                    className="map-screenshot-popup__textarea fixed-size-textarea"
                    style={{fontFamily: '"Noto Sans",sans-serif'}}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title || ""}
                />
                <span style={{fontFamily: '"Noto Sans",sans-serif'}}
                      className="map-screenshot-popup__counter">
                                    {title?.length || 0} / 70
                                </span>
              </label>
            </div>
            <div>
              <label className="map-screenshot-popup__label map-screenshot-popup__label--description">
                {t("screenShot.description")}
                <textarea
                    className="map-screenshot-popup__textarea fixed-size-textarea"
                    rows="5"
                    style={{fontFamily: '"Noto Sans",sans-serif'}}
                    value={description || ""}
                    onChange={(e) =>
                        setDescription(e.target.value)
                }
                />

                <span className="map-screenshot-popup__counter"
                      style={{fontFamily: '"Noto Sans",sans-serif'}}>
                      {description?.length || 0} / 1000
                </span>
              </label>
            </div>
          </div>
        </form>
      </div>
      <Stack spacing={2} direction="row" justifyContent={"center"}>
        <Button onClick={onClose} variant="outlined" color={"inherit"}>
          {t('cancel')}
        </Button>
        <Button
            variant="contained"
            onClick={onScreenShotEdit}
            disabled={!editButtonDisable || !title?.length}
        >
          {t('save')}
        </Button>
      </Stack>
    </div>
  );
};

const mapDispatchToProps = {
  editScreenShotCardAPI: editScreenShotCardAPI,
};

export default connect(null, mapDispatchToProps)(ScreenShotEdit);
