// DeleteModal.jsx
import React from 'react';
import { Modal } from 'antd';
import {useTranslation} from "react-i18next";

const DeleteModal = ({ visible, onOk, onCancel }) => {
    const {t} = useTranslation();

    return (
        <Modal
            open={visible}
            centered={true}
            styles={{
                title: {
                  textAlign: 'center'
                },
                content: {
                    padding: 20,
                    borderRadius: 20,
                    width: 420,
                    maxWidth: 420,
                    gap: 50
                },
                header: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                },
                body: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                footer: {
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }}
            style={{
                width: 420,
                maxWidth: 420,
            }}
            title={<span style={{fontWeight: 700, color: '#2C476C', fontFamily: 'Noto Sans Armenian'}}>{t('microArticle.userDashboard.microArticlesCardText.menu.deletePopup.title').toUpperCase()}</span>}
            okText={
                <span style={{
                    backgroundColor: 'red',
                    color: '#fff',
                    fontSize: 12
                }}>
                    {t('microArticle.userDashboard.microArticlesCardText.menu.deletePopup.buttons.delete')}
                </span>
            }
            cancelButtonProps={{
                style: {width: 100, height: 30,}
            }}
            okButtonProps={{
                style: { backgroundColor: 'red', color: '#fff', width: 100, height: 30, }
            }}
            okType="danger"
            cancelText={
                <span style={{fontSize: 12}}>
                    {t('microArticle.userDashboard.microArticlesCardText.menu.deletePopup.buttons.cancel')}
                </span>
            }
            onOk={onOk}
            onCancel={onCancel}
        >
            <p style={{textWrap: 'nowrap', fontWeight: 500, color: '#2C476C', fontFamily: 'Noto Sans Armenian'}}>{t('microArticle.userDashboard.microArticlesCardText.menu.deletePopup.message')}</p>
        </Modal>
    );
};

export default DeleteModal;
