import React from 'react'
import withRedux from '../../mapper'
import Requirements from '../../../UI/Requirements'
import {t} from "i18next";

const FilterTab = ({
  mapRequirement,
  setMapRequirement,
  setSelectedCategoryId,
  setSortedTooltipSelectItem,
  setFilterAction,
  setOpenCategoryID,
  globalLoading,
  mapRequirements,
  setMapRequirements,
}) => {
  const handleFilterButtonClick = () => {
    setMapRequirement(!mapRequirement)
    setSelectedCategoryId(null)
    setSortedTooltipSelectItem(1)
    setFilterAction('alphabetically')
    setOpenCategoryID(null)
  }

  const handleRadioClick = (itemValue) => {
    setMapRequirements(itemValue)
  }

  return (
    <div className={`filters ${mapRequirement ? 'active' : ''}`}>
      <button
        className={`filters__btn main__tab ${mapRequirement ? 'active' : ''}`}
        type="button"
        onClick={handleFilterButtonClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-is-loading={globalLoading}>
        <Requirements />
      </button>
      <div className="filters__content">
        <h2 className="filters__title">Դասակարգել Ըստ</h2>
        <div className="filters__radio">
          {mapRequirements?.map((item) => (
            <div
              className={`filters__radio-label ${item.active ? 'bg' : ''}`}
              key={item.id + 'filter'}
              onClick={() => handleRadioClick(item.value)}>
              <span className="filters__radio-text">{t(item.titleKey)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withRedux(FilterTab)
