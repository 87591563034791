export const getStringFromHTML = (html) => {
  if (!html) return '';
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent
}

export const patchFormAdapter = (formObj) => {
  console.log('formObj', formObj)
  const data = []
  for (const key in formObj) {
    // if (formObj[key] === null) continue
    data.push({
      path: `/${key}`,
      op: 'add',
      value: formObj[key],
    })
  }

  return data
}

export const getChangesFormData = (oldObj, newObj) => {
  const data = {}

  for (const key in newObj) {
    if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
      data[key] = newObj[key]
    }
  }
  return data
}

export const generateSiteUrl = ({
  presentationId,
  slideId,
  topicId,
  lessonId,
  articleMode,
                                  microArticleMode,
}) => {
  const url = window.location.origin + window.location.pathname
  const type = topicId ? 'topic' : lessonId ? 'lesson' : ''
  const path = `${url}?presentation=${presentationId}${slideId ? `&screenSlide=${slideId}` : ''}${type ? `&type=${type}` : ''}${topicId ? `&topicID=${topicId}` : ''}${lessonId ? `&lessonID=${lessonId}` : ''}${articleMode ? `&articleMode=${articleMode}` : ''}${microArticleMode ? `&microArticleMode=${microArticleMode}` : ''}`

  console.log('path:;;', path, presentationId, slideId, topicId, lessonId)

  return path
}
