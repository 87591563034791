// Create a feature for cluster elements
import {v4 as uuidv4} from "uuid";
import {INITIAL_ICON_OBJECT, isIconKeyValid} from "../../../components/microArticle/utils";

export const BRAINOGRAPH_PIN_GIS_API = "Brainograph PIN GIS API";
export const BRAINOGRAPH_GIS_API = "Brainograph GIS API";

export const createClusterElement = (el, selectorsRef, legends, cat) => {
    const article = selectorsRef.current.filteredCategories.getById(el?.articleIds[0]);
    const mainSub = article?.subcategory?.find((sub) => sub.isMain);
    const mainCatId = mainSub?.categoryId || article?.categoryId;
    const mainSubId = mainSub?.subCategoryId;
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [el.location.longitude, el.location.latitude],
        },
        properties: {
            id: el.id,
            relationType: el.lessonRelationType,
            articleIds: el.articleIds,
            name: el.displayName || 'no name',
            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
            startTime: 0,
            endTime: 0,
            visible: 'visible',
            relationFilter: 'visible',
            iconPath: 'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
            key: legends?.getKeyById?.(el?.layerId)?.key,
            subId: mainSubId,
            catId: mainCatId,
            color: cat[mainCatId]?.color,
            catColor: cat[mainCatId]?.color,
            spriteIcon: legends?.getKeyById?.(el?.layerId)?.icon,
            element: true,
        },
    };
};

// Create a feature for articles
export const createArticleFeature = (el, subCat, cat, idx) => {
    const mainSub = el?.subcategory.find((sub) => sub.isMain);
    const mainCatId = mainSub?.categoryId || el?.categoryId;
    const mainSubId = mainSub?.subCategoryId;
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [el.location.longitude, el.location.latitude],
        },
        properties: {
            visible: 'visible',
            relationFilter: 'visible',
            id: el.id,
            relationType: el.lessonRelationType,
            articleIds: [el.id],
            name: el.content[0]?.shortTitle || el.content[0]?.mainTitle || 'no name',
            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
            startTime: 0,
            endTime: 0,
            catId: cat[mainCatId]?.id,
            catColor: cat[mainCatId]?.color,
            subId: subCat[mainSubId]?.id,
            iconPath: subCat[mainSubId]?.iconPath,
            spriteIcon: subCat[mainSubId]?.spriteIcon,
            color: subCat[mainSubId]?.color || '#fff',
            key: subCat[mainSubId]?.key,
            isArticle: true,
            active: idx === 0,
        },
    };
};

export const createMicroArticleFeature = (el, idx) => {
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [el.longitude, el.latitude],
        },
        properties: {
            id: uuidv4(),
            microArticleID: el.id,
            title: el.title,
            backgroundColor: el.iconBackgroundColor || '#FF0000',
            iconImage: el.iconImage,
            iconKey: isIconKeyValid(el.iconKey) ? el.iconKey : INITIAL_ICON_OBJECT.darkKey,
            radius: 10,
            opacity: 0.5,
            show: true,
            visible: 'visible',
            relationFilter: 'visible',
            name: el.title || 'no name',
            yearStart: +`${el.startTimeIsBC ? '-' : ''}${el.startYear || '4714'}`,
            yearEnd: +`${el.endTimeIsBC ? '-' : ''}${el.endYear || new Date().getFullYear()}`,
            startTime: 0,
            endTime: 0,
            active: idx === 0,
            isMicroArticle: true,
        },
    };
};

export const createEmptyMicroArticleFeature = (coords, iconKey) => {
    const currentYear = new Date().getFullYear();

    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [coords.lng, coords.lat], // Default coordinates; adjust as needed
        },
        properties: {
            id: uuidv4(),
            microArticleID: null,
            title: '',
            backgroundColor: '#036147',
            iconKey: iconKey,
            radius: 10,
            opacity: 0.5,
            show: true,
            visible: 'visible',
            relationFilter: 'visible',
            name: '',
            yearStart: -4713, // Default start year (e.g., 4714 BC)
            yearEnd: currentYear, // Default end year (current year)
            startTime: 0,
            endTime: 0,
            active: false,
            isMicroArticle: true,
        },
    };
};
