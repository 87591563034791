import ArrowLeft from '../../../assets/imgs/icons/arrowLeft.svg'
import "./style.css"
import ConnectedSpriteImageComponent from "../ConnectedSpriteImageComponent";
import {setActiveMicroArticleId} from "../../../store/actions/microArticle";
import {useDispatch} from "react-redux";
import {INITIAL_ICON_OBJECT, isIconKeyValid} from "../../microArticle/utils";
const MapElementsPopup = (props) =>{
    const {child,selectELements,coordinates} = props
    const dispatch = useDispatch();
    const handleClick = (el) => {
        if (el.properties && el.properties.isMicroArticle) {
            dispatch(setActiveMicroArticleId(el.properties.microArticleID))
        } else {
            selectELements(el,coordinates,child.length)
        }
    }

   return(
            <div className="elements-popup-box"
                 style={{
                     '--icon-arrow-left':`url("${ArrowLeft}")`
                 }}>
                <div className="elements-popup-content">
                    <ul className="elements-popup-ul">
                        {
                            child?.map(el=>(
                                <li className="elements-popup-li"
                                    key={`element-popup-${el?.properties?.id}`}
                                    style={{
                                        '--color':`${el?.properties?.isMicroArticle ? el?.properties?.backgroundColor : el?.properties?.color}`
                                    }}
                                    onClick={() => handleClick(el)}>
                                    <div className="elements-popup-icon">
                                        <ConnectedSpriteImageComponent
                                            name={el?.properties && el?.properties?.isMicroArticle ? isIconKeyValid(el?.properties?.iconKey) ? el?.properties?.iconKey : INITIAL_ICON_OBJECT.darkKey : el?.properties?.spriteIcon}
                                        />
                                    </div>
                                    {/*<i className="elements-popup-icon"*/}
                                    {/*   style={{*/}
                                    {/*       '--icon':`url("${el?.properties?.iconPath}")`*/}
                                    {/*   }}/>*/}
                                    <span className="elements-popup-title">{el?.properties?.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
   )
}
export default MapElementsPopup
