import React, {memo} from 'react';
import {connect} from "react-redux";
import {
    getMapStyledId,
    getMicroArticleBarClickedState,
    getPainterGeoJsonDataST,
    getPainterStartData,
    getScreenShootButtonStateST,
} from "../../store/selectors";

import MicroArticleTool from "./microArticleTool";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";

const MicroArticleBar = (props) => {
  const {
      getMicroArticleBarClickedState,
      isScreenShot,
      getAllFeaturesOfSuperCluster,
      superCluster,
      useMapLiveViewRef,
      map,
      globalLoading,
  } = props
  return (
    <div
      className="paintBar_container"
      style={{ display: getMicroArticleBarClickedState ? 'flex' : 'none' }}>
      {/*<div id={'screen-shot-icon'} className="lastToolboxBtn">*/}
      {/*    {getMicroArticleBarClickedState && (*/}
      {/*        <MapScreenShotButton map={map} disable={isScreenShot ? getScreenShootButtonStateST : undefined} />*/}
      {/*    )}*/}
      {/*</div>*/}
      <MicroArticleTool superCluster={superCluster} useMapLiveViewRef={useMapLiveViewRef} getAllFeaturesOfSuperCluster={getAllFeaturesOfSuperCluster} globalLoading={globalLoading} map={map} />
    </div>
  )
}

const mapStateTopProps = (state) => ({
  getMicroArticleBarClickedState: getMicroArticleBarClickedState(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getMapStyledId: getMapStyledId(state),
})

const mapDispatchToProps = {};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(MicroArticleBar));

