// src/components/MediaRenderer.js
import React, {useEffect} from 'react'
import { Typography } from 'antd'
import styles from './MediaRenderer.module.css' // Убедитесь, что стили обновлены

const { Text } = Typography

const MediaRenderer = ({ mediaItem }) => {
    const { type, path, caption } = mediaItem

    useEffect(() => {
        console.log('mediaItem', mediaItem);
    }, []);

    // Функция для генерации обернутого медиа-элемента с оверлеем
    const renderMediaWithOverlay = (mediaElement) => (
        <div className={styles.mediaContainer}>
            {mediaElement}
            <div className={styles.overlay}></div>
        </div>
    )

    switch (type) {
        case 'Image':
            return renderMediaWithOverlay(
                <img
                    src={path}
                    alt={caption || 'Image'}
                    className={styles.media}
                />
            )

        case 'Video':
            const renderVideoSlide = () => (
              <div className="single-gallery-video is-video">
                <video
                  data-item={true}
                  src={path}
                  className={`hiddConroll ${styles.media}`}
                  controls="hidden"
                  style={{ pointerEvents: 'none' }}
                />
                <div className={'single-gallery-video-icon'}>
                  {/* SVG content can be rendered here if provided */}
                </div>
              </div>
            )

            return renderMediaWithOverlay(
                renderVideoSlide()
            )

        case 'Url':
            // Предполагается, что path - это YouTube URL
            // Извлекаем ID видео и встраиваем его
            const getYouTubeEmbedURL = (url) => {
                try {
                    // If the URL already includes "/embed/", do nothing (return it as is).
                    if (url.includes('/embed/')) {
                        return url.replace('youtube', 'youtube-nocookie');
                    }

                    const urlObj = new URL(url);
                    const videoId = urlObj.searchParams.get('v');

                    // If we can't find a video ID in the query params, return null
                    if (!videoId) {
                        return null;
                    }

                    return `https://www.youtube.com/embed/${videoId}`;
                } catch (e) {
                    console.error(e);
                    return null;
                }
            };

            const embedURL = getYouTubeEmbedURL(path)
            console.log('embedURL', embedURL);
            if (!embedURL) {
                return <Text type="warning">Неверный YouTube URL.</Text>
            }

            return renderMediaWithOverlay(
                <iframe
                    width="100%"
                    height="315"
                    src={embedURL}
                    title={caption || 'YouTube video'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={styles.media}
                ></iframe>
            )

        case 'Iframe':
            return renderMediaWithOverlay(
                <iframe
                    width="100%"
                    height="315"
                    src={path}
                    title={caption || 'YouTube video'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={styles.media}
                ></iframe>
            )

        default:
            return <Text type="warning">Unsupported media type.</Text>
    }
}

export default MediaRenderer
