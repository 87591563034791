import React, {useEffect, useMemo, useState} from 'react'
import {Checkbox, Dropdown, Form, Image, Input} from 'antd'
import TextEditor from '../elements/TextEditor'
import DotsIcon from '../icons/DotsIcon'
import CopyIcon from '../icons/CopyIcon'
import TrashIcon from '../icons/TrashIcon'
import EyeIcon from '../icons/EyeIcon'
import {cn} from '../../../../utils/utils'
import {useGetArticleCategoriesQuery, useGetArticleQuery, useGetMicroArticleQuery,} from '../../store/presentationApi'
import ConnectedSpriteImageComponent from '../../../UI/ConnectedSpriteImageComponent'
import {useSelector} from 'react-redux'
import {getCategoriesData} from '../../../../store/selectors'
import {EyeOffIcon} from 'lucide-react'
import {useTranslation} from 'react-i18next'
import {getStringFromHTML} from "../../lib/utils";

const PresentationSlideFormRow = ({
  index,
  selectedSlide,
  onSelect,
  onCopy,
  onRemove,
  onHidden,
  selected,
  slideData,
}) => {
  const { isHidden } = slideData || {}
  const { t } = useTranslation()
  const [isHighlighted, setIsHighlighted] = useState(
    String(selectedSlide) === String(slideData?.id),
  )

  const { data: articleData } = useGetArticleQuery(slideData?.articleId, {
    skip: !slideData?.articleId,
  })

  const { data: microArticleData } = useGetMicroArticleQuery(
    slideData?.microArticleId,
    {
      skip: !slideData?.microArticleId,
    },
  )

  const categories = useSelector(getCategoriesData)

  const {
    data: categoriesData,
    isFetching: categoriesIsFetching,
    ...rest
  } = useGetArticleCategoriesQuery({})

  const article = articleData?.data[0]
  const microArticle = microArticleData?.data[0]

  const subCategoryInfo = useMemo(() => {
    const subCategoryId = article?.subCategory?.find(
      (item) => item.isMain,
    )?.subCategoryId
    if (!subCategoryId || !categories?.length) {
      return null
    }

    //get subCategory object from categories -> item  -> subcategories

    let icon
    let color

    categories?.forEach((item) => {
      item.subCategories?.forEach?.((subCategory) => {
        if (subCategory?.id === subCategoryId) {
          icon = subCategory?.spriteIcon
          color = subCategory?.color
        }
      })
    })

    return { icon, color }
  }, [article, categories])

  console.log('microArticle', microArticle)

  const articleImage = article?.content?.[0]?.featuredImage
  console.log('article', article?.id,articleImage, article)

  const microArticleImage = microArticle?.media.find(
    (item) => (item.type || '').toLowerCase() === 'image',
  )?.path

  const items = [
    {
      icon: isHidden ? (
        <EyeIcon />
      ) : (
        <EyeOffIcon size={14} strokeWidth={2.5} color={'#2C476C'} />
      ),
      label: t(`presentation_mode.${isHidden ? 'show' : 'hide'}`),
      key: 'show',
    },
    {
      icon: <CopyIcon />,
      label: t('presentation_mode.duplicate'),
      key: 'copy',
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
    },
  ]

  const highlightElement = () => {
    setTimeout(() => {
      setIsHighlighted(false)
    }, 4000) // Highlight for 4 seconds
  }

  useEffect(() => {
    highlightElement()
  }, [selectedSlide])

  console.log('slideData', slideData)

  const clickDropdownHandler = (e) => {
    e.domEvent.stopPropagation()
    switch (e.key) {
      case 'show':
        onHidden?.(index)
        break
      case 'remove':
        onRemove?.(index)
        break
      case 'copy_url':
        break
      case 'copy':
        onCopy?.(slideData?.id)
        break
      default:
        break
    }
  }

  const selectHandler = (id) => {
    onSelect?.(index)
  }

  console.log(
    'String(selectedSlide) === String(slideData?.id)',
    selectedSlide,
    isHighlighted,
    slideData?.id,
    String(selectedSlide) === String(slideData?.id),
  )

  if (!slideData) return null

  return (
    <div
      className={cn(
        'presentation-slide--item--row my-1 py-2 rounded-lg transition-all',
        {
          'bg-[#F6F6F6]': isHighlighted,
        },
      )}
      id={`slide-${slideData?.id}`}>
      <div className="flex items-center mb-7">
        <span className="basis-10 text-center flex items-center justify-center text-[#2C476C]">
          {index + 1}
        </span>
        <Form.Item
          className={cn('flex-1 pr-4 mb-0', {
            'disabled opacity-50 pointer-events-none': isHidden,
          })}
          name={[index, 'title']}
          rules={[
            {
              required: true,
              message: t('presentation_mode.name_validation_text'),
            },
          ]}>
          <Input rootClassName="h-[44px]" />
        </Form.Item>
      </div>
      <div className="flex items-center w-full mb-4">
        <span className="basis-10 text-center  flex items-center justify-center">
          <Checkbox
            checked={selected}
            onChange={(e) => selectHandler(e.target.checked)}
          />
        </span>
        <div className={cn('grid grid-cols-12 w-full gap-3', {})}>
          <div
            className={cn('col-span-3 ', {
              'disabled opacity-50': isHidden,
            })}>
            <div
              className={cn(
                'rounded-xl bg-[#F6F6F6] h-full flex items-end justify-start relative overflow-hidden',
                {
                  'bg-[#0576F4]  items-center justify-center':
                    (!articleImage && article) ||
                    (!microArticleImage && microArticle),
                },
              )}
              style={{
                backgroundColor:
                  subCategoryInfo?.color || microArticle?.iconBackgroundColor,
              }}>
              {(articleImage || microArticleImage || slideData?.imageUrl) && (
                <Image
                  preview={false}
                  className="object-cover !h-full"
                  rootClassName="w-full rounded-lg aspect-[397/280] absolute left-0 top-0 h-full"
                  src={articleImage || microArticleImage || slideData?.imageUrl}
                />
              )}
              {(article || microArticle) && (
                <div
                  className={cn(
                    'bg-[#0576F4] z-10 [&>div]:scale-150 [&>div]:invert-[1] rounded-tr-xl rounded-bl-xl inline-flex p-2 items-center justify-center',
                    {
                      '[&>div]:scale-100': articleImage,
                    },
                  )}
                  style={{
                    backgroundColor:
                      subCategoryInfo?.color ||
                      microArticle?.iconBackgroundColor,
                  }}>
                  <ConnectedSpriteImageComponent
                    name={subCategoryInfo?.icon || microArticle?.iconKey}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-span-9 flex">
            <Form.Item
              className={cn('flex-1 pr-3 mb-0', {
                'disabled opacity-50': isHidden,
              })}
              name={[index, 'description']}
              rules={[
                {
                  required: false,
                  message: t('presentation_mode.name_validation_narrative'),
                },
                {
                  validator: (_, value) => {
                    const plainText = getStringFromHTML(value || '');
                    if (plainText.length > 500) {
                      return Promise.reject(t('presentation_mode.text_max_500_message'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <TextEditor className="!h-[160px]" showCount maxCount={500} />
            </Form.Item>
            <div className="inline-flex justify-end items-center ">
              <Dropdown
                getPopupContainer={() => document.body}
                overlayClassName="app-dropdown !z-[1300]"
                placement="bottomLeft"
                menu={{ items, onClick: clickDropdownHandler }}
                trigger={['click']}>
                <button
                  type="button"
                  className="flex"
                  onClick={(e) => e.stopPropagation()}>
                  <DotsIcon width="6" height="10" className="!h-4" />
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PresentationSlideFormRow
