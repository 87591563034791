import React, {useEffect, useState} from 'react'
import PresentationListHeader from './PresentationListHeader'
import PresentationListItem from './PresentationListItem'
import PresentationSlidesList from './slides/PresentationSlidesList'
import {useGetExternalPresentationsQuery, useGetPresentationsQuery,} from '../store/presentationApi'
import {Skeleton} from 'antd'
import {selectSelectedPresentation, setSelectedPresentation,} from '../store/presentationSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getIsFormPopupVisible} from "../../../store/selectors";
import {cn} from "../../../utils/utils";

const PresentationList = () => {
  const dispatch = useDispatch()
  const activeId = useSelector(selectSelectedPresentation)
  const microArticleIsFormPopupVisible = useSelector(getIsFormPopupVisible)
  const { t } = useTranslation()
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userInfo'))?.data)

  const { data: presentationsData, isFetching } = useGetPresentationsQuery({
    page: 1,
    perPage: 7,
    columnFilters: {
      createdBy: String(currentUser?.id),
    },
    sort: [
      {
        field: 'LastOpenedDate',
        type: 'Desc',
      },
    ]
  })

  const {
    data: externalPresentations,
    isFetching: externalPresentationsIsFetching,
  } = useGetExternalPresentationsQuery({
    page: 1,
    perPage: 1000,
  })

  const records = presentationsData?.data?.[0]?.items || []

  const externalRecords = externalPresentations?.data?.[0]?.items || []

  const clickHandler = (id) => {
    dispatch(setSelectedPresentation(id))
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedPresentation(null))
    }
  }, []);

  return (
    <div className={cn({
      "pointer-events-none opacity-90": microArticleIsFormPopupVisible
    })}>
      <PresentationListHeader
        canGoBack={!!activeId}
        onClickBack={() => clickHandler(null)}
      />
      {externalPresentationsIsFetching && !externalRecords?.length && (
        <div className="p-2">
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      )}
      {!activeId && (
        <div className="presentation-list">
          {externalRecords.map((item, index) => (
            <PresentationListItem
              key={item.id}
              description={item.description || item.shortDescription}
              presentation={item}
              id={item.id}
              index={index}
              hasSlides={item.slidesCount > 0}
              onClick={() => clickHandler(item.id)}
              name={item.title}
            />
          ))}
          <div className="px-2 pt-3 pb-1">
            <span className="uppercase text-[#2C476C80]">
              {t('presentation_mode.my_last_presentations')}
            </span>
          </div>
          {isFetching && !records?.length && (
            <div className="p-2">
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          )}
          {records.map((item, index) => (
            <PresentationListItem
              key={item.id}
              description={item.description || item.shortDescription}
              presentation={item}
              id={item.id}
              index={index}
              hasSlides={item.slidesCount > 0}
              onClick={() => clickHandler(item.id)}
              name={item.title}
            />
          ))}
        </div>
      )}

      {!!activeId && <PresentationSlidesList presentationId={activeId} />}
    </div>
  )
}

export default PresentationList
