import {identityStoreApi} from '../../../store/api/identityStoreApi'
import {articleStoreApi} from '../../../store/api/articleStoreApi'
import {indexingStoreApi} from '../../../store/api/indexingStoreApi'
import {mediaContentStoreApi} from '../../../store/api/mediaContentStoreApi'
import {patchFormAdapter} from '../lib/utils'
import i18n from '../../../locales/i18n'

export const identityApi = identityStoreApi.injectEndpoints({
  endpoints: (build) => ({
    getPresentations: build.query({
      query: (params) => {
        return {
          url: `/Presentation/DataProvider`,
          method: 'POST',
          body: params,
        }
      },
      providesTags: ['presentations'],
    }),
    getExternalPresentations: build.query({
      query: (params) => {
        return {
          url: `/Presentation/ExternalDataProvider`,
          method: 'POST',
          body: params,
        }
      },
      providesTags: ['presentations'],
    }),
    getPresentation: build.query({
      query: (params) => {
        return {
          url: `/Presentation/${params.id}`,
          method: 'GET',
        }
      },
      providesTags: ['presentation'],
    }),
    updateSlideOrder: build.mutation({
      query: (params) => {
        const { id, order } = params
        return {
          url: `/Slide/${id}/${order}`,
          method: 'PUT',
          body: params,
        }
      },
    }),
    createPresentation: build.mutation({
      query: (params) => {
        return {
          url: `/Presentation`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['presentations'],
    }),
    updatePresentation: build.mutation({
      query: ({ id, ...params }) => {
        return {
          url: `/Presentation/${id}`,
          method: 'PATCH',
          body: patchFormAdapter(params),
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
        }
      },
      invalidatesTags: ['presentations', 'presentation'],
    }),
    removePresentation: build.mutation({
      query: (id) => {
        return {
          url: `/Presentation/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['presentations'],
    }),
    cloneSlide: build.mutation({
      query: (params) => {
        return {
          url: `/Slide/Duplicate`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['slides'],
    }),
    updateSlide: build.mutation({
      query: ({ id, ...params }) => {
        return {
          url: `/Slide/${id}`,
          method: 'PATCH',
          body: patchFormAdapter(params),
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
        }
      },
      invalidatesTags: ['slides'],
    }),
    mapStateImportToSlide: build.mutation({
      query: ({ id, ...params }) => {
        return {
          url: `/Slide/MapStateBatchImport`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['slides', 'presentation'],
    }),
    createSlide: build.mutation({
      query: (params) => {
        return {
          url: `/Slide`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['slides'],
    }),
    removeSlide: build.mutation({
      query: (id) => {
        return {
          url: `/Slide/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['slides'],
    }),
    clonePresentation: build.mutation({
      query: (params) => {
        return {
          url: `/Presentation/Duplicate`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['presentations'],
    }),
    getPresentationSlides: build.query({
      query: (params) => {
        return {
          url: `/Slide/DataProvider`,
          method: 'POST',
          body: params,
        }
      },
      providesTags: ['slides'],
    }),
    getSlide: build.query({
      query: (id) => {
        return {
          url: `/Slide/${id}`,
          method: 'GET',
        }
      },
    }),
    getArticles: build.query({
      query: (params) => {
        const lng = i18n.language === 'en' ? 2 : 1

        return {
          url: `/Article/DataProvider/${lng}`,
          method: 'POST',
          body: params,
        }
      },
    }),
    getScreenshots: build.query({
      query: (params) => {
        return {
          url: `/MapState/DataProvider`,
          method: 'POST',
          body: params,
        }
      },
    }),
  }),
})

export const indexingApi = indexingStoreApi.injectEndpoints({
  endpoints: (build) => ({
    getArticle: build.query({
      query: (id) => {
        return {
          url: `/Article/${id}`,
          method: 'GET',
        }
      },
    }),
    getArticles: build.query({
      query: (params) => {
        return {
          url: `/Map/Search`,
          method: 'GET',
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newData, options) => {
        if (!currentCache || Object.keys(currentCache).length === 0)
          return newData
        const newDataItems = newData?.data?.[0]?.items
        const isLastCollection =
          options.pageSize > newDataItems.length || !newDataItems.length
        if (options.arg.pageNumber === 0) {
          return newData
        }
        currentCache?.data?.[0]?.items.push(...newDataItems)
        currentCache.data[0].isLast = isLastCollection
        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: ['articles'],
      // onCacheEntryAdded: async (args, { cacheDataLoaded, cacheEntryRemoved }) => {
      //     const { data } = await cacheDataLoaded;
      //     cacheEntryRemoved();
      //     cacheDataLoaded({
      //         data: data,
      //     });
      // },
      transformResponse: (response) => ({
        ...response,
      }),
    }),

    getMicroArticles: build.query({
      query: (params) => {
        return {
          url: `/Map/Search/Microarticle`,
          method: 'GET',
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newData, options) => {
        if (!currentCache || Object.keys(currentCache).length === 0)
          return newData
        const newDataItems = newData?.data?.[0]?.items
        const isLastCollection =
          options.pageSize > newDataItems.length || !newDataItems.length
        if (options.arg.pageNumber === 0) {
          return newData
        }
        currentCache?.data?.[0]?.items.push(...newDataItems)
        currentCache.data[0].isLast = isLastCollection
        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: ['articles'],
      // onCacheEntryAdded: async (args, { cacheDataLoaded, cacheEntryRemoved }) => {
      //     const { data } = await cacheDataLoaded;
      //     cacheEntryRemoved();
      //     cacheDataLoaded({
      //         data: data,
      //     });
      // },
      transformResponse: (response) => ({
        ...response,
      }),
    }),
  }),
})

export const articleApi = articleStoreApi.injectEndpoints({
  endpoints: (build) => ({
    getArticleCategories: build.query({
      query: (params) => {
        const lng = i18n.language === 'en' ? 2 : 1

        return {
          url: `/Category/DataProvider/${lng}`,
          method: 'POST',
          body: params,
        }
      },
    }),

    getMicroArticle: build.query({
      query: (id) => {
        return {
          url: `/MicroArticle/${id}`,
          method: 'GET',
        }
      },
    }),
    getArticlesFromArticleService: build.query({
      query: ({ languageId, ...params }) => {
        return {
          url: `/Article/DataProvider/${languageId || 1}`,
          method: 'POST',
          body: params,
        }
      },
    }),
  }),
})

export const mediaContentApi = mediaContentStoreApi.injectEndpoints({
  endpoints: (build) => ({
    uploadMedia: build.mutation({
      query: (params) => {
        return {
          url: `/Upload`,
          method: 'POST',
          body: params,
        }
      },
    }),
    removeMediaByPath: build.mutation({
      query: (params) => {
        return {
          url: `/Content/DeleteByPaths`,
          method: 'DELETE',
          body: params,
        }
      },
    }),
    removeMedia: build.mutation({
      query: (id) => {
        return {
          url: `/Content/HardDelete/${id}`,
          method: 'DELETE',
        }
      },
    }),
  }),
})

export const {
  useCreatePresentationMutation,
  useGetPresentationsQuery,
    useLazyGetPresentationsQuery,
  useGetPresentationQuery,
  useLazyGetPresentationQuery,
  useGetPresentationSlidesQuery,
  useUpdateSlideOrderMutation,
  useClonePresentationMutation,
  useRemovePresentationMutation,
  useUpdatePresentationMutation,
  useCloneSlideMutation,
  useUpdateSlideMutation,
  useRemoveSlideMutation,
  useMapStateImportToSlideMutation,
  useCreateSlideMutation,
  useGetSlideQuery,
  useGetExternalPresentationsQuery,
  useGetScreenshotsQuery,
  useLazyGetPresentationSlidesQuery,
} = identityApi

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useGetMicroArticlesQuery,
} = indexingApi

export const {
  useGetArticleCategoriesQuery,
  useGetArticlesFromArticleServiceQuery,
  useGetMicroArticleQuery,
} = articleApi

export const { useUploadMediaMutation, useRemoveMediaByPathMutation, useRemoveMediaMutation } = mediaContentApi
