import axios from 'axios'
import {
    ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION,
    ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS
} from "../components/articleReadMore/constants";
import GatewayAPI from "../server/gatewayAPI";
import IdentityAPI from "../server/IdentityAPI";
import i18n from "i18next";
import {getLocaleKey} from "../utils/helpers";

const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data
    return []
}

const sanitizeFilteredSelectedResult = (data) => {
    return {
        id: data.id,
        show: true,
        articleData: data,
    }
}
const sanitizeFilteredByLanguage = (data,lng) => {
    data.content = data?.content?.filter?.(el=> el.languageId === lng)
    data.gallery = data?.gallery?.filter?.(el=> el.languageId === lng)
    return data
}

const selectAPIRequest = (id) => {
    const firstId = typeof id === 'string' ? id.split(',')[0] : id // Берём первое число
    return axios
        .get(
            `${process.env.REACT_APP_INDEXING_URL}/api/Map/Preview?articleId=${firstId}`
        )
        .then(extractData)
}
// const selectAPIRequest = (id) => {
//
//     return axios
//         .get(
//             `${process.env.REACT_APP_GIS_URL}/Articles/${id}`
//         )
//         .then(extractDataByStatus)
// }
export const likeArticleAPIRequest = (body) => {
    return IdentityAPI.post('/api/FavoriteArticle', body)
}
export const unlikeArticleAPIRequest = (id) => {
    return IdentityAPI.delete(`/api/FavoriteArticle/${id}`)
}
// const selectAllAPIRequest = (id) => {
//     return axios
//         .get(
//             `${process.env.REACT_APP_ARTICLE_URL}/api/Article/${id}/Language/1?IncludeAll=true`
//         )
//         .then(extractData)
// }
const selectAllAPIRequest = (id, lng = 1) => {
    return axios.get(`${process.env.REACT_APP_INDEXING_URL}/api/Article/${id}/${lng}`)
        .then(extractData)


}
const favoriteArticleLikeDataProviderAPIRequest = (id) => {
    const body = {
        "page": 1,
        "perPage": 10,
        "columnFilters": {
            "articleId": `${id}`
        }
    }
    return  axios.post(`${process.env.REACT_APP_IDENTITY_URL}/api/FavoriteArticle/DataProvider`, body)
        .then(extractData)
}
const historicalCountriesAPIRequest = (id) =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/HistoricalCountry/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const historicalPeriodsAPIRequest = () =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/HistoricalPeriod/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const countryAPIRequest = (id) =>{
    const body = {"page":1,"perPage":1500, ids: [id]}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/Country/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const regionAPIRequest = () =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/Region/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const lessonAPIRequest = () =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_HEADING_URL}/api/Lesson/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const relatedArticlesAPIRequest = (ids, lng = 1) =>{
    const body = {
        "page":1,
        "perPage":1500,
        "includeContent": true,
        "includeCategories": true,
        "ids": ids,}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/Article/DataProvider/${lng}`,body)
        .then(extractData)
}
export const topicsAPIRequest = () =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_HEADING_URL}/api/Topic/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const tagsAPIRequest = () =>{
    const body = {"page":1,"perPage":1500}
    return  axios.post(`${process.env.REACT_APP_ARTICLE_URL}/api/Tag/DataProvider/${i18n.language === 'en' ? 2 : 1}`,body)
        .then(extractData)
}
export const getArticleAPI = (id, lng) => {
    return selectAPIRequest(id)
        .then((res)=>sanitizeFilteredByLanguage(res,lng))
        .then(sanitizeFilteredSelectedResult)
        .catch(console.error)
}

export const getArticleAllAPI = (id, lng) => {
    return selectAllAPIRequest(id,lng)
        .then(sanitizeFilteredSelectedResult)
        .then(res=>{
            res.articleData.affiliationsInfo = []
            res.articleData.otherConnectionsInfo = []
            res.articleData.tagsInfo = []
            return res
        })
        .then(async res=>{
            if(res?.articleData?.historicalCountry?.length){
                await historicalCountriesAPIRequest()
                .then(resolve=>{
                    const historicalCountriesIdsArr = res?.articleData?.historicalCountry?.map(el=>el.historicalCountryId)
                    res.articleData.affiliationsInfo.push({
                        ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION[0],
                        content:[...resolve?.items?.filter(el=> historicalCountriesIdsArr.includes (el.id))]
                    })
                })
            }
            if(res?.articleData?.historicalPeriod?.length){
                await historicalPeriodsAPIRequest()
                    .then(resolve=>{
                        const historicalPeriodsIdsArr = res?.articleData?.historicalPeriod?.map(el=>el.historicalPeriodsId)
                        res.articleData.affiliationsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION[3],
                            content:[...resolve?.items?.filter(el=> historicalPeriodsIdsArr.includes (el.id))]
                        })
                    })
            }
            if(res?.articleData?.country?.length){
                await countryAPIRequest()
                    .then(resolve=>{
                        const countriesIdsArr = res?.articleData?.country?.map(el=>el.countryId)
                        res.articleData.affiliationsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION[2],
                            content:[...resolve?.items?.filter(el=> countriesIdsArr.includes (el.id))]
                        })
                    })
            }
            if(res?.articleData?.region?.length){
                await regionAPIRequest()
                    .then(resolve=>{
                        const regionsIdsArr = res?.articleData?.region?.map(el=>el.regionId)
                        res.articleData.affiliationsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION[1],
                            content:[...resolve?.items?.filter(el=> regionsIdsArr.includes (el.id))]
                        })
                    })
            }
            if(res?.articleData?.lesson?.length){
                await lessonAPIRequest()
                    .then(resolve=>{
                        const lessonsIdsArr = res?.articleData?.lesson?.map(el=>el.lessonId)
                        res.articleData.otherConnectionsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS[1],
                            content:[...resolve?.items?.filter(el=> lessonsIdsArr.includes (el.id))]
                        })
                    })
            }
            if(res?.articleData?.topic?.length){
                await topicsAPIRequest()
                    .then(resolve=>{
                        const topicsIdsArr = res?.articleData?.topic?.map(el=>el.topicId)
                        res.articleData.otherConnectionsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS[0],
                            content:[...resolve?.items?.filter(el=> topicsIdsArr.includes (el.id))]
                        })
                    })
            }
            if(res?.articleData?.relatedArticle?.length){
                const relatedArticlesIdsArr = res?.articleData?.relatedArticle?.map(el=>el.relatedArticleId)
                await relatedArticlesAPIRequest(relatedArticlesIdsArr)
                    .then(resolve=>{
                        res.articleData.otherConnectionsInfo.push({
                            ...ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS[2],
                            content:[...resolve?.items]
                        })
                    })
            }
            if(res?.articleData?.tag?.length){
                await tagsAPIRequest()
                    .then(resolve=>{
                        const tagsIdsArr = res?.articleData?.tag?.map(el=>el.tagId)
                        res.articleData.tagsInfo.push([...resolve?.items?.filter(el=> tagsIdsArr.includes (el.id))])
                    })
            }
            if(res?.articleData?.id){
                await favoriteArticleLikeDataProviderAPIRequest(res?.articleData?.id)
                    .then(resolve=>{
                        res.articleData.like = resolve.items.length !== 0
                    })
            }
            return res
        })
        .catch(console.error)
}

export const getArticleLabels = (lng = 1) => {
    return axios
        .post(
            `${process.env.REACT_APP_ARTICLE_URL}/api/ArticleLabel/DataProvider/${lng}`,{
                body:{
                    page: 1,
                    perPage: 50,
                }
            }
        )
        .then(extractData)
}
